/* istanbul ignore file */
import {
  Paper,
  Table as TableUI,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from 'containers/services/translate';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Spinner } from 'utils/focus-ui-kit';
import CollapsibleRow from 'utils/focus-ui-kit/dahsboard-dg-ui-kit/TableDashboard/CollapsibleRow';
import messages from 'utils/focus-ui-kit/dahsboard-dg-ui-kit/TableDashboard/messages';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  tablerows: {
    color: '#6A6A6A',
    fontFamily: ' Inter',
    fontSize: '12.8px',
    fontWeight: 600,
    letterSpacing: '-0.04px',
    lineHeight: '20px',
  },
  cellcontainer: {
    padding: 0,
  },
});

const TableDashboard = (props) => {
  const {
    rows,
    dashboardDgLoading,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    total,
    searchLoading,
    dashboardSubscriber,
    setQuery,
    query,
  } = props;
  const classes = useStyles();
  const {
    statusTableDgCell,
    tableDgNoData,
    tableDgLabelRowsPerPage,
    LastUpdateTableDgCell,
    filialeTableDgCell,
    aviationTableDgCell,
    machineryBreakdownTableDgCell,
    marineHullTableDgCell,
    cyberTableDgCell,
    generalLiabilityTableDgCell,
    dandOTableDgCell,
    earandCarTableDgCell,
    cargoTableDgCell,
    propretyTableDgCell,
    politicalRisksTableDgCell,
    directeurTechniqueTableDgCell,
    elementsQuantitatifsTableDg,
    tableDgLabelDisplayedRows,
  } = messages;
  const rowsTitle = [
    translate(directeurTechniqueTableDgCell, props),
    translate(statusTableDgCell, props),
    translate(LastUpdateTableDgCell, props),
    translate(filialeTableDgCell, props),
    translate(aviationTableDgCell, props),
    translate(machineryBreakdownTableDgCell, props),
    translate(marineHullTableDgCell, props),
    translate(cyberTableDgCell, props),
    translate(propretyTableDgCell, props),
    translate(generalLiabilityTableDgCell, props),
    translate(dandOTableDgCell, props),
    translate(politicalRisksTableDgCell, props),
    translate(cargoTableDgCell, props),
    translate(earandCarTableDgCell, props),
  ];

  const eQuanNames = [
    'Aviation',
    'Bris de machine',
    'Corps de navires',
    'Cyber',
    'Dommages aux biens',
    'RC Générale',
    'RC mandataires sociaux',
    'Risques politiques-terrorism',
    'Transport',
    'TRC & TRME',
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableCellRender = (title) => {
    return (
      <TableCell className={classes.tablerows} align="center" key={title}>
        {title}
      </TableCell>
    );
  };

  return (
    <Paper className={classes.paper}>
      {!rows && (dashboardDgLoading || searchLoading) ? (
        <Container>
          <Spinner noHeight />
        </Container>
      ) : (
        <TableContainer>
          <ContainerHeader>
            <TablTitle> </TablTitle>
            <TablTitle>
              {translate(elementsQuantitatifsTableDg, props)}
            </TablTitle>
          </ContainerHeader>
          <TableUI className={classes.table} aria-label="simple table">
            <colgroup>
              <col width="9%" />
              <col width="5%" />
              <col width="9%" />
              <col width="7%" />
              <col width="7%" />
              <col width="7%" />
              <col width="7%" />
              <col width="7%" />
              <col width="7%" />
              <col width="7%" />
              <col width="7%" />
              <col width="7%" />
              <col width="7%" />
              <col width="7%" />
              <col width="7%" />
            </colgroup>
            <TableHead>
              <TableRow>
                {rowsTitle.map((title) => tableCellRender(title))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((row, idx) => (
                  <CollapsibleRow
                    eQuanNames={eQuanNames}
                    row={row}
                    idx={idx}
                    dashboardSubscriber={dashboardSubscriber}
                    setQuery={setQuery}
                    query={query}
                  />
                ))}
            </TableBody>
          </TableUI>
        </TableContainer>
      )}
      {rows && rows.length === 0 && (
        <Container>
          <span> {translate(tableDgNoData, props)}</span>
        </Container>
      )}
      {rows && rows.length !== 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={translate(tableDgLabelRowsPerPage, props)}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${translate(
              tableDgLabelDisplayedRows,
              props
            )} ${count}`
          }
        />
      )}
    </Paper>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;
const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 20px;
  color: '#3A3B3F';
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.05px;
  line-height: 20px;
  padding: 21px;
`;

const TablTitle = styled.div`
  margin-left: 20px;
  color: '#3A3B3F';
`;

TableDashboard.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  dashboardDgLoading: PropTypes.bool.isRequired,
  searchLoading: PropTypes.bool.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  dashboardSubscriber: PropTypes.arrayOf(PropTypes.object).isRequired,
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
};

export default injectIntl(TableDashboard);
