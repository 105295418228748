import React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.fill ? '20' : '16'}
    height={props.fill ? '20' : '18'}
    viewBox="0 0 16 18"
    {...props}
  >
    <path
      fill={props.fill ? '#fff' : '#3E3F42'}
      fill-rule="nonzero"
      d="M3.293 15l11.5-11.5L12.5 1.207 1 12.707V15h2.293zM0 15.5v-3a.5.5 0 0 1 .146-.354l12-12a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-12 12A.5.5 0 0 1 3.5 16h-3a.5.5 0 0 1-.5-.5zM.5 18a.5.5 0 1 1 0-1h10.007a.5.5 0 1 1 0 1H.5z"
    />
  </svg>
);
