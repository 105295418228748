import { defineMessages } from 'react-intl';

export default defineMessages({
  StaticCardneedAction: {
    id: 'static_card_need_action',
  },
  StaticCardPending: {
    id: 'static_card_pending',
  },
  StaticCardValide: {
    id: 'static_card_valide',
  },
});
