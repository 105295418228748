import { BLUE_MUI } from 'shared';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Rubik;
  font-size: 45px;
  font-weight: 500;
  color: #fff;
  background-color: ${BLUE_MUI};
  border-radius: 5px 5px 0px 0px;
`;

export const NoNotif = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  font-family: Rubik;
  font-size: 25px;
  font-weight: 400;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  font-family: Rubik;
  font-size: 15px;
  font-weight: 200;
`;

export const BorderBottom = styled.div`
  border-bottom: solid 2px #eef1f5;
`;
