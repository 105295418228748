export const GET_ALL_CONSULTANTS = 'GET CONSULTANTS [GET_ALL_CONSULTANTS]';
export const GET_ALL_CONSULTANTS_FAILED =
  'GET ALL CONSULTANTS SUCCES [GET_ALL_CONSULTANTS_FAILED]';
export const GET_ALL_CONSULTANTS_SUCCES =
  'GET ALL CONSULTANTS SUCCES [GET_ALL_CONSULTANTS_SUCCES]';

export const GET_CONSULTANT_BYID = 'GET_CONSULTANT_BYID';
export const GET_CONSULTANT_BYID_SUCCES = 'GET_CONSULTANT_BYID_SUCCES';
export const GET_CONSULTANT_BYID_FAILED = 'GET_CONSULTANT_BYID_FAILED';

export const GET_CONSULTANT_EMAIL = 'GET_CONSULTANT_EMAIL';
export const GET_CONSULTANT_EMAIL_SUCCES = 'GET_CONSULTANT_EMAIL_SUCCES';
export const GET_CONSULTANT_EMAIL_FAILED = 'GET_CONSULTANT_EMAIL_FAILED';

export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENTS_SUCCES = 'GET_CLIENTS_SUCCES';
export const GET_CLIENTS_FAILED = 'GET_CLIENTS_FAILED';

export const CLEAR_SELECTED_CONSULTANT = 'CLEAR_SELECTED_CONSULTANT';

// POST ACTIVITY CONSTANTS
export const POST_ACTIVITY = 'POST_ACTIVITY [POST_ACTIVITY]';
export const POST_ACTIVITY_SUCCESS =
  'POST ACTIVITY SUCCES [POST_ACTIVITY_SUCCESS]';
export const POST_ACTIVITY_FAILED =
  'POST ACTIVITY FAILED [POST_ACTIVITY_FAILED]';

// POST CLIENT
export const POST_CLIENT = 'POST_CLIENT [POST_CLIENT]';
export const POST_CLIENT_SUCCES = 'POST CLIENT SUCCES [POST_CLIENT_SUCCES]';
export const POST_CLIENT_FAILED = 'POST CLIENT FAILED [POST_CLIENT_FAILED]';

// POST Salary
export const POST_SALARY = 'POST SALARY [POST_SALARY]';
export const POST_SALARY_SUCCES = 'POST SALARY SUCCES [POST_SALARY_SUCCES]';
export const POST_SALARY_FAILED = 'POST SALARY FAILED [POST_SALARY_FAILED]';

// get certifications

export const GET_ALL_CERTIFICATIONS = 'GET_ALL_CERTIFICATIONS';
export const GET_ALL_CERTIFICATIONS_SUCCESS = 'GET_ALL_CERTIFICATIONS_SUCCESS';
export const GET_ALL_CERTIFICATIONS_FAILED = 'GET_ALL_CERTIFICATIONS_FAILED';

// filters
export const HIDE_FILTERS_COUNT = 'HIDE_FILTERS_COUNT';
export const POST_FILTERS = 'POST_FILTERS  [POST_FILTERS]';
export const POST_FILTERS_SUCCES = 'POST FILTERS  SUCCES [POST_FILTERS_SUCCES]';
export const POST_FILTERS_FAILED = 'POST FILTERS  FAILED [POST_FILTERS_FAILED]';

// POST ACTIVITY CONSTANTS
export const UPDATE_ACTIVITY = 'UPDATE ACTIVITYY [UPDATE_ACTIVITY]';
export const UPDATE_ACTIVITY_SUCCES =
  'UPDATE ACTIVITY SUCCES [UPDATE_ACTIVITY_SUCCES]';
export const UPDATE_ACTIVITY_FAILED =
  'UPDATE ACTIVITY FAILED [UPDATE_ACTIVITY_FAILED]';

// POST ACTIVITY CONSTANTS
export const UPDATE_ACTIVITY_STATUS =
  'UPDATE ACTIVITY STATUS [UPDATE_ACTIVITY_STATUS]';
export const UPDATE_ACTIVITY_STATUS_SUCCES =
  'UPDATE ACTIVITY STATUS SUCCES [UPDATE_ACTIVITY_STATUS_SUCCES]';
export const UPDATE_ACTIVITY_STATUS_FAILED =
  'UPDATE ACTIVITY STATUS FAILED [UPDATE_ACTIVITY_STATUS_FAILED]';

export const UPDATE_COMMENT = 'UPDATE_COMMENT [UPDATE_COMMENT]';
export const UPDATE_COMMENT_SUCCES =
  'UPDATE_COMMENT_SUCCES [UPDATE_COMMENT_SUCCES]';
export const UPDATE_COMMENT_FAILED =
  'UPDATE_COMMENT_FAILED [UPDATE_COMMENT_FAILED]';

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL';

// GET COMPETENCES

export const GET_COMPETENCES = 'GET_COMPETENCES';
export const GET_COMPETENCES_SUCCESS = 'GET_COMPETENCES_SUCCESS';
export const GET_COMPETENCES_FAIL = 'GET_COMPETENCES_FAIL';

// download cv
export const GET_DOWNLOAD_CV = 'GET_DOWNLOAD_CV';
export const GET_DOWNLOAD_CV_SUCCESS = 'GET_DOWNLOAD_CV_SUCCESS';
export const GET_DOWNLOAD_CV_FAILED = 'GET_DOWNLOAD_CV_FAILED';

// UPDATE CONSULTANT STATUS
export const UPDATE_CONSULTANT_STATUS =
  'UPDATE CONSULTANT STATUS [UPDATE_CONSULTANT_STATUS]';
export const UPDATE_CONSULTANT_STATUS_SUCCES =
  'UPDATE CONSULTANT STATUS SUCCES [UPDATE_CONSULTANT_STATUS_SUCCES]';
export const UPDATE_CONSULTANT_STATUS_FAILED =
  'UPDATE CONSULTANT STATUS FAILED [UPDATE_CONSULTANT_STATUS_FAILED]';

// UPDATE CONSULTANT STATUS AND POST ACTIVITY
export const POST_ACTIVITY_UPDATE_ACTIVITY_STATUS =
  'POST ACTIVITY UPDATE ACTIVITY STATUS [POST_ACTIVITY_UPDATE_ACTIVITY_STATUS]';

export const RESET_CV = 'RESET_CV';

// delete activity
export const DELETE_ACTIVITY = 'DELETE ACTIVITY [DELETE_ACTIVITY]';
export const DELETE_ACTIVITY_SUCCES =
  'DELETE ACTIVITY SUCCES [DELETE_ACTIVITY_SUCCES]';
export const DELETE_ACTIVITY_FAILED =
  'DELETE ACTIVITY FAILED [DELETE_ACTIVITY_FAILED]';

// get all users
export const GET_USERS = 'GET USERS [GET_USERS]';
export const GET_USERS_SUCCES = 'GET USERS SUCCES [GET_USERS_SUCCES]';
export const GET_USERS_FAILED = 'GET USERS FAILED [GET_USERS_FAILED]';

//get exchange rates
export const GET_EXCHANGE_RATES = 'GET_EXCHANGE_RATES';
export const GET_EXCHANGE_RATES_SUCCES = 'GET_EXCHANGE_RATES_SUCCES';
export const GET_EXCHANGE_RATES_FAILED = 'GET_EXCHANGE_RATES_FAILED';

// Sort table
export const SORT_CONSULTANTS_BY_IC = 'SORT_CONSULTANTS_BY_IC';
export const SORT_CONSULTANTS = 'SORT_CONSULTANTS';

export const SYNCHRONIZE_CONSULTANT_WITH_PROGESSI =
  'SYNCHRONIZE_CONSULTANT_WITH_PROGESSI';
export const SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_SUCCES =
  'SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_SUCCES';
export const SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_FAILED =
  'SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_FAILED';

export const GET_CONSULTANT_FROM_PROGESSI = 'GET_CONSULTANT_FROM_PROGESSI';
export const GET_CONSULTANT_FROM_PROGESSI_SUCCES =
  'GET_CONSULTANT_FROM_PROGESSI_SUCCES';
export const GET_CONSULTANT_FROM_PROGESSI_FAILED =
  'GET_CONSULTANT_FROM_PROGESSI_FAILED';

export const CONSULTANT_STATUS = {
  INCOMING: 'Incoming IC',
  ONGOING: 'Ongoing IC',
  NONE: 'None',
};
