/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  AppBar,
  Avatar,
  ClickAwayListener,
  Grid,
  Menu,
  MenuItem,
  Popper,
  Toolbar,
  Tab,
  Tabs,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import LanguageIcon from '@material-ui/icons/Language';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { EnFlag, FrFLag } from 'assets/svg';
import { SET_LOCALE } from 'containers/LanguageProvider/constants';
import { GET_CONSULTANT_EMAIL } from 'containers/DashboardPage/constants';
import jwt from 'jwt-decode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Logo from 'utils/focus-ui-kit/Logo';
import Face3Icon from '@mui/icons-material/Face3';
import { GENDER_FEMALE } from 'containers/CreateProfile/constants';
import styled from 'styled-components';
import { useModal } from 'utils/utils';
import LogOutModal from 'utils/focus-ui-kit/Header/LogOutModal';
import Notifications from 'containers/Notifications';
import { GET_NOTIFICATIONS } from 'shared';
import ReorderIcon from '@mui/icons-material/Reorder';
import TimelineIcon from '@mui/icons-material/Timeline';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { ROLE_CONSULTANT } from 'utils/roles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    minHeight: '76px',
    justifyContent: 'space-between',
  },
  hr: {
    backgroundColor: 'rgba(151,151,151,0.15)',
    width: '85%',
    height: '1px',
    margin: '8px 0',
  },
  logo: {
    height: '32px',
    width: '149px',
  },
  header: {
    height: '76px',
    position: 'relative',
  },
  name: {
    marginLeft: '14px',
  },
  profileButton: {
    cursor: 'pointer',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    backgroundColor: '#0176BA',
  },

  profile: {
    display: 'flex',
    alignItems: 'center',
  },
  notification: {
    marginRight: '20px',
    marginTop: '5px',
    cursor: 'pointer',
  },
  langage: {
    display: 'flex',
    minHeight: '45px',
    justifyContent: 'space-between',
    width: '100px',
    cursor: 'pointer',
  },
  menuLanguage: {
    marginTop: '45px',
  },
  menuNotif: {
    marginTop: '30px',
  },
  menuItem: {
    color: '#323C47',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '6px',
    marginBottom: '6px',
  },
}));

const MenuAppBar = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorLang, setAnchorLang] = useState(null);
  const [anchorNotif, setAnchorNotif] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isNotificationsOpen, setNotificationsOpen] = useState(false);
  const open = Boolean(anchorEl);
  const token = localStorage.getItem('token');
  const gender = localStorage.getItem('gender');
  const role = localStorage.getItem('role');
  const subRole = localStorage.getItem('subRole');
  const fullName =
    localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');

  const dispatch = useDispatch();
  const { locale } = useSelector((state) => state.get('intl'));
  const intl = useIntl();

  const { consultantId } = useSelector((state) => state.get('dashboard'));

  const { notifications } = useSelector((state) => state.get('notifications'));

  const notificationsRef = useRef(null);

  useEffect(() => {
    dispatch({
      type: GET_NOTIFICATIONS,
      email: localStorage.getItem('email'),
      locale: locale,
    });
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    handleConsultantID();
  };

  const handleLanguageMenu = (event) => {
    setAnchorLang(event.currentTarget);
    setMenuOpen(true);
  };

  const handleNotifications = (event) => {
    if (!isNotificationsOpen) {
      setAnchorNotif(event.currentTarget);
      setNotificationsOpen(true);
    } else {
      setAnchorNotif(null);
      setNotificationsOpen(false);
    }
  };

  const handleClickAway = (event) => {
    if (!notificationsRef.current.contains(event.target)) {
      setNotificationsOpen(false);
      setAnchorNotif(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const { isShowing, toggle } = useModal();

  const handleLogOut = () => {
    handleClose();
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('country');
    localStorage.removeItem('role');
    localStorage.removeItem('gender');
    localStorage.removeItem('consultantId');
    localStorage.removeItem('subRole');
    localStorage.removeItem('scopeGeo');
    localStorage.removeItem('businessUnit');
    history.push('/login');
  };

  const handleLocalLang = useCallback(
    (lang) => dispatch({ type: SET_LOCALE, locale: lang }),
    [dispatch],
  );

  const handleLocalLangChange = (lan) => {
    handleLocalLang(lan);
    setAnchorLang(null);
    setMenuOpen(false);
  };

  const handleConsultantID = useCallback(
    () => dispatch({ type: GET_CONSULTANT_EMAIL }),
    [dispatch],
  );

  const handleProfile = () => {
    history.push(`/consultant-profile/${consultantId}`);
  };

  const getUnreadNotificationsLength = () => {
    return notifications
      ? notifications.filter((notification) => notification.read === false)
          .length
      : null;
  };

  const [value] = useState(
    window.location.pathname === '/'
      ? 0
      : window.location.pathname === '/reporting'
      ? 1
      : window.location.pathname === '/admin'
      ? 2
      : false,
  );

  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      newValue === 0
        ? history.push('/')
        : newValue === 2
        ? (window.location.href = '/admin')
        : history.push('/reporting');
    }
  };

  return (
    <AppBar color="inherit" position="static" className={classes.header}>
      <Toolbar className={classes.container}>
        <div>
          <Logo noText />
        </div>
        {!(role === ROLE_CONSULTANT && !subRole) && (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            centered
          >
            <Tab
              label={
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <ReorderIcon />
                  {intl.formatMessage({ id: 'dashboard' })}
                </div>
              }
              fullWidth={true}
            />
            <Tab
              label={
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <TimelineIcon />
                  {intl.formatMessage({ id: 'reporting' })}
                </div>
              }
              fullWidth={true}
            />
            {!subRole && (
              <Tab
                label={
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <AdminPanelSettingsOutlinedIcon />
                    {intl.formatMessage({ id: 'adminDashboard' })}
                  </div>
                }
                fullWidth={true}
              />
            )}
          </Tabs>
        )}

        <div className={classes.profile}>
          <Grid
            innerRef={notificationsRef}
            className={classes.notification}
            onClick={handleNotifications}
            justify="center"
            alignItems="center"
          >
            <Badge badgeContent={getUnreadNotificationsLength()} color="error">
              <NotificationsIcon color="primary" />
            </Badge>
          </Grid>
          <Grid
            className={classes.langage}
            onClick={handleLanguageMenu}
            data-testid="languageTrigger"
            justify="center"
            alignItems="center"
          >
            <LanguageIcon style={{ marginRight: '6px' }} />
            <span data-testid="language">{locale.toUpperCase()}</span>
            <ExpandMore />
          </Grid>
          <Menu
            className={classes.menuLanguage}
            id="simple-menu"
            data-testid="languageMenu"
            anchorEl={anchorLang}
            keepMounted
            open={isMenuOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MenuItem
              className={classes.menuItem}
              data-testid="francais"
              onClick={() => handleLocalLangChange('fr')}
            >
              <span style={{ fontFamily: 'Rubik' }}>
                {intl.formatMessage({ id: 'menu_item_fr' })}
              </span>
              <FrFLag />
            </MenuItem>
            <MenuItem
              data-testid="anglais"
              className={classes.menuItem}
              onClick={() => handleLocalLangChange('en')}
            >
              <span style={{ fontFamily: 'Rubik', fontWeight: 250 }}>
                {intl.formatMessage({ id: 'menu_item_en' })}
              </span>
              <EnFlag />
            </MenuItem>
          </Menu>

          <Grid
            onClick={handleMenu}
            container
            justify="center"
            alignItems="center"
            className={classes.profileButton}
          >
            <LogedUser>{fullName}</LogedUser>
            {gender === GENDER_FEMALE ? (
              <div className={classes.avatarContainer}>
                <Face3Icon style={{ color: '#fafafa' }} />
              </div>
            ) : (
              <Avatar
                alt={token && jwt(token).name}
                src="/static/images/avatar/1.jpg"
                className={classes.avatar}
              />
            )}
            <ExpandMore />
          </Grid>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
            className={classes.menu}
          >
            {consultantId && (
              <MenuItem onClick={handleProfile}>
                {intl.formatMessage({ id: 'profile' })}
              </MenuItem>
            )}
            <MenuItem onClick={() => toggle()}>
              {intl.formatMessage({ id: 'logout' })}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
      <LogOutModal
        isShowing={isShowing}
        hide={toggle}
        handleLogOut={handleLogOut}
        handleClose={handleClose}
      />
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Popper
          className={classes.menuNotif}
          anchorEl={anchorNotif}
          open={isNotificationsOpen}
          style={{ marginRight: '100px', zIndex: 1000 }}
        >
          <Notifications notifications={notifications} />
        </Popper>
      </ClickAwayListener>
    </AppBar>
  );
};

export default injectIntl(MenuAppBar);

export const LogedUser = styled.div`
  font-weight: 500;
  text-align: end;
  width: max-content;
  margin-right: 6px;
`;
