import {
  GET_ALL_ACTIVITIES,
  GET_ALL_ACTIVITIES_FAILED,
  GET_ALL_ACTIVITIES_SUCCES,
  GET_ACTIVITY,
  GET_ACTIVITY_FAILED,
  GET_ACTIVITY_SUCCESS,
} from 'shared';

const initialState = {
  activities: [],
  isActivitiesLoading: false,
  activity: undefined,
};

export function createActivitiesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ACTIVITIES:
      return {
        ...state,
        isActivitiesLoading: true,
      };
    case GET_ALL_ACTIVITIES_SUCCES:
      return {
        ...state,
        isActivitiesLoading: false,
        activities: action.activities,
      };
    case GET_ALL_ACTIVITIES_FAILED:
      return {
        ...state,
        isActivitiesLoading: true,
        activities: null,
      };
    case GET_ACTIVITY:
      return {
        ...state,
        activity: undefined,
      };
    case GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity: action.activity,
      };
    case GET_ACTIVITY_FAILED:
      return {
        ...state,
        activity: undefined,
      };
    default:
      return state;
  }
}
