import axios from 'axios';
import { history } from 'store';

const { REACT_APP_BASEURL } = process.env;

const Axios = axios.create({
  baseURL: REACT_APP_BASEURL,
});

Axios.CancelToken = axios.CancelToken;
Axios.isCancel = axios.isCancel;

/*
 * The interceptor here ensures that we check for the token in local storage every time an ajax request is made
 */
Axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const { headers } = config;

    if (token) {
      headers['Content-Type'] = 'application/json';

      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    /* istanbul ignore next */
    if (
      error &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      localStorage.removeItem('userId');
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
      localStorage.removeItem('country');
      localStorage.removeItem('role');
      localStorage.removeItem('gender');
      localStorage.removeItem('consultantId');
      localStorage.removeItem('subRole');
      localStorage.removeItem('scopeGeo');
      history.push('/login');
    }
    /* istanbul ignore next */
    return Promise.reject(error);
  },
);

export default Axios;
