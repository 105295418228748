import React from 'react';
import Maltem from 'assets/images/logo.png';
import { useHistory } from 'react-router-dom';
import { Image, Titles } from './styles';

const Logo = ({ width, height, noText }) => {
  const history = useHistory();
  return (
    <>
      <Image
        onClick={() => history.push('/')}
        src={Maltem}
        width={width}
        height={height}
      />
      {noText ? null : (
        <Titles>
          <span className="regular">Maltem</span>
          <span className="bold">Focus</span>
        </Titles>
      )}
    </>
  );
};

export default Logo;
