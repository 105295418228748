/* istanbul ignore file */
import {
  Collapse,
  IconButton,
  Table as TableUI,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { translate } from 'containers/services/translate';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import messages from 'utils/focus-ui-kit/dahsboard-dg-ui-kit/TableDashboard/messages';
import { handleStatusColor, handleStatusLabel } from 'utils/utils';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  tablerows: {
    color: '#6A6A6A',
    fontFamily: ' Inter',
    fontSize: '12.8px',
    fontWeight: 600,
    letterSpacing: '-0.04px',
    lineHeight: '20px',
  },
  cellcontainer: {
    padding: 0,
  },
});

const CollapsibleRow = (props) => {
  const { row, idx, eQuanNames, dashboardSubscriber, setQuery, query } = props;

  const {
    subscriberCollapsibleRowTitle,
    noSubscriberCollapsibleRow,
  } = messages;

  const classes = useStyles();
  return (
    <>
      <TablRows
        className={classes.tablerow}
        status_color={handleStatusColor(row.dop.status)}
        key={idx}
      >
        <StyledCell border align="left">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (query === idx) {
                setQuery(null);
              } else setQuery(idx);
            }}
          >
            {query === idx ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
          {row.dop.first_name} {row.dop.last_name}
        </StyledCell>
        <StyledCell border align="left">
          {handleStatusLabel(row.dop.status, props)}{' '}
        </StyledCell>
        <StyledCell border align="left">
          {moment(row.dop.updated_at).format('DD-MM-YYYY')}
        </StyledCell>
        <StyledCell border align="left">
          {row.dop.filiale}
        </StyledCell>
        {eQuanNames.map((title) =>
          row.equantity.map((el) => {
            if (el.name === title) {
              return (
                <StyledCell border align="center">
                  {new Intl.NumberFormat('de-DE').format(el.max)}
                </StyledCell>
              );
            }
            return null;
          })
        )}
        <StyledCell border align="center" />
      </TablRows>
      <TablRows status_color={handleStatusColor(row.dop.status)} key={row.id}>
        <TableCell
          colspan={15}
          align="center"
          className={classes.cellcontainer}
        >
          <Collapse in={query === idx} timeout="auto" unmountOnExit>
            <TableUI aria-label="simple table">
              <colgroup>
                <col width="9%" />
                <col width="5%" />
                <col width="8%" />
                <col width="7%" />
                <col width="7%" />
                <col width="7%" />
                <col width="7%" />
                <col width="7%" />
                <col width="7%" />
                <col width="7%" />
                <col width="7%" />
                <col width="7%" />
                <col width="6%" />
                <col width="8%" />
              </colgroup>
              <Souscripteur>
                {translate(subscriberCollapsibleRowTitle, props)}
              </Souscripteur>
              {dashboardSubscriber && dashboardSubscriber.length > 0 ? (
                dashboardSubscriber.map((souscripteur) => {
                  return (
                    <TablRows>
                      <StyledCell border align="left">
                        {souscripteur.dopSous.first_name}{' '}
                        {souscripteur.dopSous.last_name}
                      </StyledCell>
                      <StyledCell border align="left">
                        {handleStatusLabel(souscripteur.dopSous.status, props)}
                      </StyledCell>
                      <StyledCell border align="left">
                        {moment(souscripteur.dopSous.updated_at).format(
                          'DD-MM-YYYY'
                        )}
                      </StyledCell>
                      <StyledCell border align="left">
                        {souscripteur.dopSous.filiale}
                      </StyledCell>

                      {eQuanNames.map((title) =>
                        souscripteur.elementQaunSousc.map((elquanSc) => {
                          if (elquanSc.name === title) {
                            return (
                              <StyledCell border align="center">
                                {new Intl.NumberFormat('de-DE').format(
                                  elquanSc.max
                                )}
                              </StyledCell>
                            );
                          }
                          return null;
                        })
                      )}
                    </TablRows>
                  );
                })
              ) : (
                <TableCell
                  className={classes.cellbackground}
                  colspan={15}
                  align="center"
                >
                  <span> {translate(noSubscriberCollapsibleRow, props)}</span>
                </TableCell>
              )}
            </TableUI>
          </Collapse>
        </TableCell>
      </TablRows>
    </>
  );
};

const Souscripteur = styled.span`
  display: block;
  opacity: 0.66;
  color: #6a6a6a;
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: -0.04px;
`;

const TablRows = styled(TableRow)`
  color: white;
  border-left: 4px solid ${(props) => props.status_color};
`;

const StyledCell = styled(TableCell)`
  border-bottom: ${(props) => (props.border ? 'none' : '1px solid #E4E7EB')};
  border-top: ${(props) => (props.border ? 'none' : '1px solid #E4E7EB')};
  margin-left: ${(props) => (props ? 'none' : '1px solid ')};
`;

CollapsibleRow.propTypes = {
  row: PropTypes.arrayOf(PropTypes.object).isRequired,
  idx: PropTypes.string.isRequired,
  eQuanNames: PropTypes.arrayOf(PropTypes.object).isRequired,
  dashboardSubscriber: PropTypes.arrayOf(PropTypes.object).isRequired,
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
};

export default injectIntl(CollapsibleRow);
