import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

ProtectedRoute.defaultProps = {
  component: PropTypes.node,
  location: {},
};

ProtectedRoute.propTypes = {
  component: PropTypes.node,
  location: PropTypes.objectOf({}),
};

export default ProtectedRoute;
