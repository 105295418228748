import {
  SAVE_RESUME,
  SAVE_RESUME_SUCCES,
  SAVE_RESUME_FAILED,
  GET_RESUMES,
  GET_RESUMES_SUCCES,
  GET_RESUMES_FAILED,
  DELETE_RESUME,
  DELETE_RESUME_SUCCES,
  DELETE_RESUME_FAILED,
} from 'shared';

const initialState = {
  resumes: [],
  logos: null,
  resumesLoading: false,
  saveResumeStatus: '',
  deleteResumeStatus: '',
};

export function createResumesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RESUMES:
      return {
        ...state,
        resumesLoading: true,
      };
    case GET_RESUMES_SUCCES:
      return {
        ...state,
        resumesLoading: false,
        resumes: action.resumes,
        logos: action.logos,
      };
    case GET_RESUMES_FAILED:
      return {
        ...state,
        resumesLoading: false,
      };
    case SAVE_RESUME:
      return {
        ...state,
        resumesLoading: true,
        saveResumeStatus: 'loading',
      };
    case SAVE_RESUME_SUCCES:
      return {
        ...state,
        resumesLoading: false,
        saveResumeStatus: action.message,
      };
    case SAVE_RESUME_FAILED:
      return {
        ...state,
        resumesLoading: false,
        saveResumeStatus: action.message,
      };
    case DELETE_RESUME:
      return {
        ...state,
        resumesLoading: true,
        deleteResumeStatus: 'loading',
      };
    case DELETE_RESUME_SUCCES:
      return {
        ...state,
        resumesLoading: false,
        deleteResumeStatus: action.message,
        resumes: action.resumes,
      };
    case DELETE_RESUME_FAILED:
      return {
        ...state,
        resumesLoading: false,
        deleteResumeStatus: action.message,
      };
    default:
      return state;
  }
}
