export const GET_ALL_CONSULTANTS_NAMES = 'GET_ALL_CONSULTANTS_NAMES';
export const GET_ALL_CONSULTANTS_NAMES_SUCCES =
  'GET_ALL_CONSULTANTS_NAMES_SUCCES';
export const GET_ALL_CONSULTANTS_NAMES_FAILED =
  'GET_ALL_CONSULTANTS_NAMES_FAILED';

// post tracking point
export const POST_TRACKING_POINT = 'POST_TRACKING_POINT';
export const POST_TRACKING_POINT_SUCCES = 'POST_TRACKING_POINT_SUCCES';
export const POST_TRACKING_POINT_FAILED = 'POST_TRACKING_POINT_FAILED';

// get list  of tracking points
export const GET_TRACKING_POINTS = 'GET_TRACKING_POINTS';
export const GET_TRACKING_POINTS_SUCCES = 'GET_TRACKING_POINTS_SUCCES';
export const GET_TRACKING_POINTS_FAILED = 'GET_TRACKING_POINTS_FAILED';

// patch tracking point
export const PATCH_TRACKING_POINT = 'PATCH_TRACKING_POINT';
export const PATCH_TRACKING_POINT_SUCCES = 'PATCH_TRACKING_POINT_SUCCES';
export const PATCH_TRACKING_POINT_FAILED = 'PATCH_TRACKING_POINT_FAILED';

// get trackingP by id
export const GET_TRACKING_POINT_BYID = 'GET_TRACKING_POINT_BYID';
export const GET_TRACKING_POINT_BYID_SUCCES = 'PGET_TRACKING_POINT_BYID_SUCCES';
export const GET_TRACKING_POINT_BYID_FAILED = 'GET_TRACKING_POINT_BYID_FAILED';

// update tracking point
export const UPDATE_TRACKING_POINT_BYID = 'UPDATE_TRACKING_POINT_BYID';
export const UPDATE_TRACKING_POINT_BYID_SUCCES =
  'UPDATE_TRACKING_POINT_BYID_SUCCES';
export const UPDATE_TRACKING_POINT_BYID_FAILED =
  'UPDATE_TRACKING_POINT_BYID_FAILED';

// post trial period
export const POST_TRIAL_PERIOD = 'POST_TRIAL_PERIOD';
export const POST_TRIAL_PERIOD_SUCCES = 'POST_TRIAL_PERIOD_SUCCES';
export const POST_TRIAL_PERIOD_FAILED = 'POST_TRIAL_PERIOD_FAILED';

// get list  oftrial periods
export const GET_TRIAL_PERIODS = 'GET_TRIAL_PERIODS';
export const GET_TRIAL_PERIODS_SUCCES = 'GET_TRIAL_PERIODS_SUCCES';
export const GET_TRIAL_PERIODS_FAILED = 'GET_TRIAL_PERIODS_FAILED';

export const VALIDATE_TRIAL_POINT = 'VALIDATE_TRIAL_POINT';
export const VALIDATE_TRIAL_POINT_SUCCES = 'VALIDATE_TRIAL_POINT_SUCCES';
export const VALIDATE_TRIAL_POINT_FAILED = 'VALIDATE_TRIAL_POINT_FAILED';

export const UPLOADFILE = 'UPLOADFILE';
export const CANCEL_EDIT = 'CANCEL_EDIT';

// get trial period by id
export const GET_TRIAL_PERIOD_BYID = 'GET_TRIAL_PERIOD_BYID';
export const GET_TRIAL_PERIOD_BYID_SUCCES = 'GET_TRIAL_PERIOD_BYID_SUCCES';
export const GET_TRIAL_PERIOD_BYID_FAILED = 'GET_TRIAL_PERIOD_BYID_FAILED';

// update trail period by id
export const UPDATE_TRAIL_PERIOD_BYID = 'UPDATE_TRAIL_PERIOD_BYID';
export const UPDATE_TRAIL_PERIOD_BYID_SUCCES =
  'UPDATE_TRAIL_PERIOD_BYID_SUCCES';
export const UPDATE_TRAIL_PERIOD_BYID_FAILED =
  'UPDATE_TRAIL_PERIOD_BYID_FAILED';

// post assignment
export const POST_ASSIGNMENT = 'POST_ASSIGNMENT';
export const POST_ASSIGNMENT_SUCCES = 'POST_ASSIGNMENT_SUCCES';
export const POST_ASSIGNMENT_FAILED = 'POST_ASSIGNMENT_FAILED';

// get list of assignment
export const GET_LIST_ASSIGNMENTS = 'GET_LIST_ASSIGNMENTS';
export const GET_LIST_ASSIGNMENTS_SUCCES = 'GET_LIST_ASSIGNMENTS_SUCCES';
export const GET_LIST_ASSIGNMENTS_FAILED = 'GET_LIST_ASSIGNMENTS_FAILED';

// get assignment by id
export const GET_ASSIGNMENT_BYID = 'GET_ASSIGNMENT_BYID';
export const GET_ASSIGNMENT_BYID_SUCCES = 'GET_ASSIGNMENT_BYID_SUCCES';
export const GET_ASSIGNMENT_BYID_FAILED = 'GET_ASSIGNMENT_BYID_FAILED';

// update assignment by id
export const UPDATE_ASSIGNMENT_BYID = 'UPDATE_ASSIGNMENT_BYID';
export const UPDATE_ASSIGNMENT_BYID_SUCCES = 'UPDATE_ASSIGNMENT_BYID_SUCCES';
export const UPDATE_ASSIGNMENT_BYID_FAILED = 'UPDATE_ASSIGNMENT_BYID_FAILED';

// post career committe
export const POST_CAREER_COMMITTEE = 'POST_CAREER_COMMITTEE';
export const POST_CAREER_COMMITTEE_SUCCESS = 'POST_CAREER_COMMITTEE_SUCCESS';
export const POST_CAREER_COMMITTEE_FAILED = 'POST_CAREER_COMMITTEE_FAILED';

// get career committees
export const GET_CAREER_COMMITTEE_LIST = 'GET_CAREER_COMMITTEE_LIST';
export const GET_CAREER_COMMITTEE_LIST_SUCCESS =
  'GET_CAREER_COMMITTEE_LIST_SUCCESS';
export const GET_CAREER_COMMITTEE_LIST_FAILED =
  'GET_CAREER_COMMITTEE_LIST_FAILED';

// post hrbp & RC
export const GET_HRBP_RC = 'GET_HRBP_RC';
export const GET_HRBP_RC_SUCCESS = 'GET_HRBP_RC_SUCCESS';
export const GET_HRBP_RC_FAILED = 'GET_HRBP_RC_FAILED';

// validate committee
export const VALIDATE_COMMITTEE = 'VALIDATE_COMMITTEE';
export const VALIDATE_COMMITTEE_SUCCESS = 'VALIDATE_COMMITTEE_SUCCESS';
export const VALIDATE_COMMITTEE_FAILED = 'VALIDATE_COMMITTEE_FAILED';

// get committee by id
export const GET_COMMITTEE_BYID = 'GET_COMMITTEE_BYID';
export const GET_COMMITTEE_BYID_SUCCESS = 'GET_COMMITTEE_BYID_SUCCESS';
export const GET_COMMITTEE_BYID_FAILED = 'GET_COMMITTEE_BYID_FAILED';

// update committee by id
export const UPDATE_COMMITTEE_BYID = 'UPDATE_COMMITTEE_BYID';
export const UPDATE_COMMITTEE_BYID_SUCCESS = 'UPDATE_COMMITTEE_BYID_SUCCESS';
export const UPDATE_COMMITTEE_BYID_FAILED = 'UPDATE_COMMITTEE_BYID_FAILED';

export const MISSION_FOLLOW_UP = 'Suivi mission';
export const ANNUAL_INTERVIEW = 'Entretien annuel';
export const PROFESSIONAL_INTERVIEW = 'Entretien professionnel';
export const WORKLOAD_INTERVIEW = 'Entretien charge de travail';
export const UNITARY_INTERVIEW = 'Entretien unitaire';
export const TRIAL_PERIOD = 'Période d’essai';
export const CAREER_COMMITTEE = 'Comité de carrière';

export const trakingList = [
  { label: MISSION_FOLLOW_UP, id: 1 },
  { label: ANNUAL_INTERVIEW, id: 2 },
  { label: PROFESSIONAL_INTERVIEW, id: 4 },
  { label: WORKLOAD_INTERVIEW, id: 5 },
  { label: UNITARY_INTERVIEW, id: 6 },
  { label: TRIAL_PERIOD, id: 7 },
  { label: CAREER_COMMITTEE, id: 8 },
];

export const SECOND_TRACKING_PERIOD = 4;
export const LAST_TRACKING_PERIOD = 7;

export const STATUS_VALIDATE = 'valider';
export const STATUS_REFUSE = 'refusé';
export const STATUS_RENEW = 'renouveler';

export const LABEL_FIRST_FOLLOW_UP = 'Premier suivi';
export const LABEL_SECOND_FOLLOW_UP = 'Deuxieme suivi';
export const LABEL_LAST_FOLLOW_UP = 'Dernier suivi';

export const trackingListStatus = [
  {
    label: LABEL_FIRST_FOLLOW_UP,
    id: 1,
    status: [{ label: LABEL_FIRST_FOLLOW_UP, id: 1 }],
  },
  {
    label: LABEL_SECOND_FOLLOW_UP,
    id: 2,
    status: [
      { label: STATUS_VALIDATE, id: 1 },
      { label: STATUS_RENEW, id: 2 },
      { label: STATUS_REFUSE, id: 3 },
    ],
  },
  {
    label: LABEL_LAST_FOLLOW_UP,
    id: 3,
    status: [
      { label: STATUS_VALIDATE, id: 1 },
      { label: STATUS_REFUSE, id: 2 },
    ],
  },
];

export const trainingList = [
  { label: 'Soft skills', id: 1 },
  { label: 'Méthodologie', id: 2 },
  { label: 'Management', id: 4 },
  { label: 'Technique', id: 5 },
  { label: 'Métier', id: 6 },
  { label: 'Linguistique', id: 7 },
];
