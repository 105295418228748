import * as React from 'react';

const CommentIcon = (props) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 10.8333C15 11.2459 14.8361 11.6416 14.5444 11.9333C14.2527 12.225 13.857 12.3889 13.4444 12.3889H4.11111L1 15.5V3.05556C1 2.643 1.16389 2.24733 1.45561 1.95561C1.74733 1.66389 2.143 1.5 2.55556 1.5H13.4444C13.857 1.5 14.2527 1.66389 14.5444 1.95561C14.8361 2.24733 15 2.643 15 3.05556V10.8333Z"
        //stroke="#808794"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CommentIcon;
