import { selectLocale } from 'containers/LanguageProvider/selectors';
import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import enTransaltions from 'translations/en.json';
import frTransaltions from 'translations/fr.json';

const translations = { fr: frTransaltions, en: enTransaltions };

const LanguageProvider = ({ locale, children }) => {
  return (
    <IntlProvider locale={locale} key={locale} messages={translations[locale]}>
      {children}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  messages: PropTypes.shape({}).isRequired,
};

const mapStateToProps = createStructuredSelector({
  locale: selectLocale(),
});

export default connect(mapStateToProps)(LanguageProvider);
