import { defineMessages } from 'react-intl';

export default defineMessages({
  tableDgLabelRowsPerPage: {
    id: 'table_dg_label_rows_per_page',
  },
  statusTableDgCell: {
    id: 'table_dg_cell_status',
  },
  tableDgNoData: {
    id: 'table_dg_no_data',
  },
  LastUpdateTableDgCell: {
    id: 'last_update_table_dg_cell',
  },
  filialeTableDgCell: {
    id: 'table_dg_cell_filiale',
  },
  aviationTableDgCell: {
    id: 'table_dg_cell_aviation',
  },
  machineryBreakdownTableDgCell: {
    id: 'table_dg_cell_machinery_reakdown',
  },
  marineHullTableDgCell: {
    id: 'table_dg_cell_marine_hull',
  },
  cyberTableDgCell: {
    id: 'table_dg_cell_cyber',
  },
  generalLiabilityTableDgCell: {
    id: 'table_dg_cell_general_liability',
  },
  dandOTableDgCell: {
    id: 'table_dg_cell_d_&_o',
  },
  earandCarTableDgCell: {
    id: 'table_dg_cell_earand_car',
  },
  cargoTableDgCell: {
    id: 'table_dg_cell_cargo',
  },
  propretyTableDgCell: {
    id: 'table_dg_cell_proprety',
  },
  politicalRisksTableDgCell: {
    id: 'table_dg_cell_political_risks',
  },
  directeurTechniqueTableDgCell: {
    id: 'table_dg_cell_directeur_technique',
  },
  elementsQuantitatifsTableDg: {
    id: 'table_dg_elements_quantitatifs',
  },
  subscriberCollapsibleRowTitle: {
    id: 'collapsible_row_title_subscriber',
  },
  noSubscriberCollapsibleRow: {
    id: 'collapsible_row_no_subscriber',
  },
  filterTableDgSearch: {
    id: 'filter_table_dg_search',
  },
  filterTableDgReset: {
    id: 'filter_table_dg_reset',
  },
  filterTableDgExportBtn: {
    id: 'filter_table_dg_export_btn',
  },
  tableDgLabelDisplayedRows: {
    id: 'table_dg_label_displayed_rows',
  },
  filterTableDgStatus: {
    id: 'filter_table_dg_status',
  },
  filterTableDgFiliale: {
    id: 'filter_table_dg_filiale',
  },
  filterTableDgRole: {
    id: 'filter_table_dg_role',
  },
});
