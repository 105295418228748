/* istanbul ignore file */
import { UnfoldMore } from '@material-ui/icons';
import { translate } from 'containers/services/translate';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import MenuItem from 'utils/focus-ui-kit/SideMenu/MenuItem';
import messages from 'utils/focus-ui-kit/SideMenu/messages';

const SideMenu = ({ isCollapsed, handleToggle, ...props }) => {
  const role = localStorage.getItem('role');
  const {
    sideNavHome,
    sideNavElements,
    sideNavDashboard,
    sideNavMyDelegation,
  } = messages;
  return (
    <Container isCollapsed={isCollapsed}>
      <Menu>
        {role === 'superadmin' && (
          <MenuItem
            to="/Dashboard"
            title={translate(sideNavDashboard, props)}
            isCollapsed={isCollapsed}
          />
        )}
        <MenuItem
          to="/"
          title={translate(sideNavHome, props)}
          isCollapsed={isCollapsed}
        />
        {role === 'superadmin' && (
          <MenuItem
            to="/elements_qualitatifs"
            title={translate(sideNavElements, props)}
            isCollapsed={isCollapsed}
          />
        )}
        {(role === 'Directeur Technique' || role === 'Souscripteur') && (
          <MenuItem
            to="/my_delegation"
            title={translate(sideNavMyDelegation, props)}
            isCollapsed={isCollapsed}
          />
        )}
      </Menu>
      <UnfoldMore className="toogle_btn" onClick={() => handleToggle()} />
    </Container>
  );
};

const Container = styled.div`
  width: ${(props) => (props.isCollapsed ? '66px' : '262px')};
  background-color: #003865;
  transition: 0.5s;
  position: relative;
  overflow: hidden;

  .toogle_btn {
    fill: white;
    position: absolute;
    top: 19px;
    right: 15px;
    transform: rotate(90deg);
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  white-space: nowrap;
  min-width: 100%;
`;

SideMenu.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
};

export default injectIntl(SideMenu);
