import {
  SAVE_RESUME,
  SAVE_RESUME_SUCCES,
  SAVE_RESUME_FAILED,
  GET_RESUMES,
  GET_RESUMES_SUCCES,
  GET_RESUMES_FAILED,
  DELETE_RESUME,
  DELETE_RESUME_SUCCES,
  DELETE_RESUME_FAILED,
} from 'shared';

import { all, call, put, takeLatest } from 'redux-saga/effects';
import Axios from 'utils/Axios';

const { REACT_APP_BASEURL } = process.env;

export const putResumeApi = (values) => {
  return Axios.put(`${REACT_APP_BASEURL}/resumes/save`, values);
};

export function* putResumeSaga({ values }) {
  try {
    const res = yield call(putResumeApi, values);
    if (res.status === 200) {
      yield put({
        type: SAVE_RESUME_SUCCES,
        message: res.data.message,
      });
    } else {
      yield put({
        type: SAVE_RESUME_FAILED,
        message: res.data.message,
      });
    }
  } catch (err) {
    yield put({
      type: SAVE_RESUME_FAILED,
      message: 'failed',
    });
  }
}

export function* watchPutResumeSaga() {
  yield takeLatest(SAVE_RESUME, putResumeSaga);
}

export const deleteResumeApi = (values) => {
  return Axios.put(`${REACT_APP_BASEURL}/resumes/delete/`, values);
};

export function* deleteResumeSaga({ name, userId }) {
  try {
    const res = yield call(deleteResumeApi, { name, userId });
    if (res.status === 200) {
      yield put({
        type: DELETE_RESUME_SUCCES,
        message: res.data.message,
        resumes: res.data.resumes,
      });
    } else {
      yield put({
        type: DELETE_RESUME_FAILED,
        message: res.data.message,
      });
    }
  } catch (err) {
    yield put({
      type: DELETE_RESUME_FAILED,
      message: 'failed',
    });
  }
}

export function* watchDeleteResumeSaga() {
  yield takeLatest(DELETE_RESUME, deleteResumeSaga);
}

export const getResumesApi = (id) => {
  return Axios.get(`${REACT_APP_BASEURL}/resumes/get/${id}`);
};

export function* getResumesSaga({ id }) {
  try {
    const res = yield call(getResumesApi, id);
    if (res.status === 200) {
      yield put({
        type: GET_RESUMES_SUCCES,
        resumes: res.data.resumes,
        logos: res.data.logos,
      });
    } else {
      yield put({
        type: GET_RESUMES_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_RESUMES_FAILED,
    });
  }
}

export function* watchGetResumesSaga() {
  yield takeLatest(GET_RESUMES, getResumesSaga);
}

export function* rootResumesSaga() {
  yield all([
    watchGetResumesSaga(),
    watchPutResumeSaga(),
    watchDeleteResumeSaga(),
  ]);
}
