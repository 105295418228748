import {
  GET_TRACKING_POINTS,
  GET_TRACKING_POINTS_SUCCES,
  GET_TRACKING_POINTS_FAILED,
  POST_TRACKING_POINT,
  POST_TRACKING_POINT_SUCCES,
  POST_TRACKING_POINT_FAILED,

  // patch tracking point
  PATCH_TRACKING_POINT,
  PATCH_TRACKING_POINT_SUCCES,
  PATCH_TRACKING_POINT_FAILED,

  //get by id
  GET_TRACKING_POINT_BYID,
  GET_TRACKING_POINT_BYID_SUCCES,
  GET_TRACKING_POINT_BYID_FAILED,

  // update tracking point by id
  UPDATE_TRACKING_POINT_BYID,
  UPDATE_TRACKING_POINT_BYID_SUCCES,
  UPDATE_TRACKING_POINT_BYID_FAILED,

  // post trail period
  POST_TRIAL_PERIOD,
  POST_TRIAL_PERIOD_SUCCES,
  POST_TRIAL_PERIOD_FAILED,

  // trail period list
  GET_TRIAL_PERIODS,
  GET_TRIAL_PERIODS_SUCCES,
  GET_TRIAL_PERIODS_FAILED,

  // validate a tracking point
  VALIDATE_TRIAL_POINT,
  VALIDATE_TRIAL_POINT_SUCCES,
  VALIDATE_TRIAL_POINT_FAILED,

  // get trail period by id
  GET_TRIAL_PERIOD_BYID,
  GET_TRIAL_PERIOD_BYID_SUCCES,
  GET_TRIAL_PERIOD_BYID_FAILED,

  // update trail period by id
  UPDATE_TRAIL_PERIOD_BYID,
  UPDATE_TRAIL_PERIOD_BYID_SUCCES,
  UPDATE_TRAIL_PERIOD_BYID_FAILED,

  // post assignement
  POST_ASSIGNMENT,
  POST_ASSIGNMENT_SUCCES,
  POST_ASSIGNMENT_FAILED,

  // list of  assignements
  GET_LIST_ASSIGNMENTS,
  GET_LIST_ASSIGNMENTS_SUCCES,
  GET_LIST_ASSIGNMENTS_FAILED,

  // get assignment by id
  GET_ASSIGNMENT_BYID,
  GET_ASSIGNMENT_BYID_SUCCES,
  GET_ASSIGNMENT_BYID_FAILED,

  // update assignment by id
  UPDATE_ASSIGNMENT_BYID,
  UPDATE_ASSIGNMENT_BYID_SUCCES,
  UPDATE_ASSIGNMENT_BYID_FAILED,

  // post career committee
  POST_CAREER_COMMITTEE,
  POST_CAREER_COMMITTEE_SUCCESS,
  POST_CAREER_COMMITTEE_FAILED,

  // get list of committes
  GET_CAREER_COMMITTEE_LIST,
  GET_CAREER_COMMITTEE_LIST_SUCCESS,
  GET_CAREER_COMMITTEE_LIST_FAILED,

  // get hrbp and RC
  GET_HRBP_RC,
  GET_HRBP_RC_SUCCESS,
  GET_HRBP_RC_FAILED,

  // validate committee
  VALIDATE_COMMITTEE,
  VALIDATE_COMMITTEE_SUCCESS,
  VALIDATE_COMMITTEE_FAILED,

  //  validate committee by id
  GET_COMMITTEE_BYID,
  GET_COMMITTEE_BYID_SUCCESS,
  GET_COMMITTEE_BYID_FAILED,

  // update committee by id
  UPDATE_COMMITTEE_BYID,
  UPDATE_COMMITTEE_BYID_SUCCESS,
  UPDATE_COMMITTEE_BYID_FAILED,
} from './constants';

import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import Axios from 'utils/Axios';
import { toast } from 'react-toastify';

const { REACT_APP_BASEURL } = process.env;

export const getListTrackingPointaAPI = (payload) => {
  return Axios.get(
    `${REACT_APP_BASEURL}/trackingpoint/list?userId=${payload.userId}&consultantId=${payload.consultantId}`,
  );
};

export function* getListTrackingPointSaga(paylaod) {
  try {
    const res = yield call(getListTrackingPointaAPI, paylaod);

    if (res.status === 200) {
      yield put({
        type: GET_TRACKING_POINTS_SUCCES,
        trackigPOintList: res.data.trackingPoints,
      });
    } else {
      yield put({
        type: GET_TRACKING_POINTS_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_TRACKING_POINTS_FAILED,
    });
  }
}

export function* watchGetListTrackingPointSaga() {
  yield takeLatest(GET_TRACKING_POINTS, getListTrackingPointSaga);
}

export const postTrackingPoinAPI = (payload) => {
  return Axios.post(`${REACT_APP_BASEURL}/trackingpoint/add`, payload);
};

export function* postTrackingPoinSaga(payload) {
  try {
    const formData = new FormData();
    const myUploadFile = yield select(
      (state) => state.get('careerMonitoring').uploadFile,
    );

    formData.append('values', JSON.stringify(payload.values));
    formData.append('uploadedFile', myUploadFile);

    const res = yield call(postTrackingPoinAPI, formData);

    if (res.status === 201) {
      yield put({
        type: POST_TRACKING_POINT_SUCCES,
        status: res.data.status,
        uploadFile: null,
      });
    } else {
      yield put({
        type: POST_TRACKING_POINT_FAILED,
        status: 'failed',
        uploadFile: null,
      });
      toast.error('Error adding the tracking point');
    }
  } catch (err) {
    yield put({
      type: POST_TRACKING_POINT_FAILED,
      status: 'failed',
      uploadFile: null,
    });
  }
}

export function* watchPostTrackingPoin() {
  yield takeLatest(POST_TRACKING_POINT, postTrackingPoinSaga);
}

// patch tracking point
export const patchTrackingPoinAPI = (payload) => {
  return Axios.patch(
    `${REACT_APP_BASEURL}/trackingpoint/update/${payload.id}/${payload._id}`,
  );
};

export function* patchTrackingPoinSaga(values) {
  try {
    const res = yield call(patchTrackingPoinAPI, values);

    if (res.status === 200) {
      yield put({
        type: PATCH_TRACKING_POINT_SUCCES,
        status: res.data.status,
      });
    } else {
      yield put({
        type: PATCH_TRACKING_POINT_FAILED,
        status: 'failed',
      });
      toast.error('Error updating the tracking point');
    }
  } catch (err) {
    yield put({
      type: PATCH_TRACKING_POINT_FAILED,
      status: 'failed',
    });
  }
}

export function* watchPatchTrackingPoin() {
  yield takeLatest(PATCH_TRACKING_POINT, patchTrackingPoinSaga);
}

// get by id
export const getTrackingPointByIdAPI = (id) => {
  return Axios.get(`${REACT_APP_BASEURL}/trackingpoint/by/${id}`);
};

export function* getTrackingPointByIdSaga({ id }) {
  try {
    const res = yield call(getTrackingPointByIdAPI, id);

    if (res.status === 200) {
      yield put({
        type: GET_TRACKING_POINT_BYID_SUCCES,
        trackingPointById: res.data.trackpointById,
      });
    } else {
      yield put({
        type: GET_TRACKING_POINT_BYID_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_TRACKING_POINT_BYID_FAILED,
    });
  }
}

export function* watchGetTrackingPointByIdSaga() {
  yield takeLatest(GET_TRACKING_POINT_BYID, getTrackingPointByIdSaga);
}

// update tracking point item
export const updateTrackingPointAPI = ({ id, _id, formData }) => {
  return Axios.put(`${REACT_APP_BASEURL}/trackingpoint/${id}/${_id}`, formData);
};

export function* updateTrackingPointSaga(payload) {
  try {
    const { id, _id, values } = payload;
    const formData = new FormData();
    const myUploadFile = yield select(
      (state) => state.get('careerMonitoring').uploadFile,
    );

    formData.append('values', JSON.stringify(values));
    formData.append('uploadedFile', myUploadFile);

    const res = yield call(updateTrackingPointAPI, { id, _id, formData });

    if (res.status === 200) {
      yield put({
        type: UPDATE_TRACKING_POINT_BYID_SUCCES,
        status: res.data,
      });
    } else {
      yield put({
        type: UPDATE_TRACKING_POINT_BYID_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_TRACKING_POINT_BYID_FAILED,
    });
  }
}

export function* watchupdateTrackingPoint() {
  yield takeLatest(UPDATE_TRACKING_POINT_BYID, updateTrackingPointSaga);
}

// section of  trial period
export const postTrialPeriodAPI = (payload) => {
  return Axios.post(`${REACT_APP_BASEURL}/trialperiod/add`, payload);
};

export function* postTrialPeriodSaga(payload) {
  try {
    const formData = new FormData();
    const myUploadFile = yield select(
      (state) => state.get('careerMonitoring').uploadFile,
    );

    formData.append('values', JSON.stringify(payload.values));
    formData.append('uploadedFile', myUploadFile);

    const res = yield call(postTrialPeriodAPI, formData);

    if (res.status === 201) {
      yield put({
        type: POST_TRIAL_PERIOD_SUCCES,
        uploadFile: null,
        // data: res.data,
      });
    } else {
      yield put({
        type: POST_TRIAL_PERIOD_FAILED,
        status: 'failed',
        uploadFile: null,
      });
      toast.error('Error adding the  trail period');
    }
  } catch (err) {
    yield put({
      type: POST_TRIAL_PERIOD_FAILED,
      status: 'failed',
      uploadFile: null,
    });
  }
}

export function* watchpostTrialPeriod() {
  yield takeLatest(POST_TRIAL_PERIOD, postTrialPeriodSaga);
}

//list of trail periods
export const getListTrialPeriodAPI = (payload) => {
  return Axios.get(
    `${REACT_APP_BASEURL}/trialperiod/list?userId=${payload.userId}&consultantId=${payload.consultantId}`,
  );
};

export function* getListTrialPeriodSaga(paylaod) {
  try {
    const res = yield call(getListTrialPeriodAPI, paylaod);

    if (res.status === 200) {
      yield put({
        type: GET_TRIAL_PERIODS_SUCCES,
        trialPeriodsList: res.data.TrialPeriods,
      });
    } else {
      yield put({
        type: GET_TRIAL_PERIODS_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_TRIAL_PERIODS_FAILED,
    });
  }
}

export function* watchGetListTrialPeriodSaga() {
  yield takeLatest(GET_TRIAL_PERIODS, getListTrialPeriodSaga);
}

// validate a trial point

export const validateTrialPeriodAPI = (payload) => {
  return Axios.patch(
    `${REACT_APP_BASEURL}/trialperiod/validate/${payload.id}/${payload._id}`,
  );
};

export function* validateTrialPeriodSaga(values) {
  try {
    const res = yield call(validateTrialPeriodAPI, values);

    if (res.status === 200) {
      yield put({
        type: VALIDATE_TRIAL_POINT_SUCCES,
        status: res.data.status,
      });
    } else {
      yield put({
        type: VALIDATE_TRIAL_POINT_FAILED,
        status: res.data.status,
      });
      toast.error('Error updating the trial period');
    }
  } catch (err) {
    yield put({
      type: VALIDATE_TRIAL_POINT_FAILED,
      status: 'failed',
    });
  }
}

export function* watchValidateTrialPeriodSaga() {
  yield takeLatest(VALIDATE_TRIAL_POINT, validateTrialPeriodSaga);
}

// get trial period by id
export const getTrialPeriodByIdAPI = (id) => {
  return Axios.get(`${REACT_APP_BASEURL}/trialperiod/by/${id}`);
};

export function* getTrialPeriodByIdSaga({ id }) {
  try {
    const res = yield call(getTrialPeriodByIdAPI, id);

    if (res.status === 200) {
      yield put({
        type: GET_TRIAL_PERIOD_BYID_SUCCES,
        trialPeriodById: res.data.data,
        selectedTrialPeriod: id,
      });
    } else {
      yield put({
        type: GET_TRIAL_PERIOD_BYID_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_TRIAL_PERIOD_BYID_FAILED,
    });
  }
}

export function* watchGetTrialPeriodByIdSaga() {
  yield takeLatest(GET_TRIAL_PERIOD_BYID, getTrialPeriodByIdSaga);
}

// update trail period item
export const updateTrialPeriodAPI = ({ id, _id, formData }) => {
  return Axios.put(`${REACT_APP_BASEURL}/trialperiod/${id}/${_id}`, formData);
};

export function* updateTrialPeriodSaga(payload) {
  try {
    const { id, _id, values } = payload;
    const formData = new FormData();
    const myUploadFile = yield select(
      (state) => state.get('careerMonitoring').uploadFile,
    );

    formData.append('values', JSON.stringify(values));
    formData.append('uploadedFile', myUploadFile);

    const res = yield call(updateTrialPeriodAPI, { id, _id, formData });

    if (res.status === 200) {
      yield put({
        type: UPDATE_TRAIL_PERIOD_BYID_SUCCES,
        status: res.data,
        uploadFile: null,
      });
    } else {
      yield put({
        type: UPDATE_TRAIL_PERIOD_BYID_FAILED,
        uploadFile: null,
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_TRAIL_PERIOD_BYID_FAILED,
      uploadFile: null,
    });
  }
}

export function* watchUpdateTrialPeriodSaga() {
  yield takeLatest(UPDATE_TRAIL_PERIOD_BYID, updateTrialPeriodSaga);
}

// assignment section
export const postAssignmentAPI = (payload) => {
  return Axios.post(`${REACT_APP_BASEURL}/assignment/add`, payload.values);
};

export function* postAssignmentSaga(payload) {
  try {
    const res = yield call(postAssignmentAPI, payload);

    if (res.status === 201) {
      yield put({
        type: POST_ASSIGNMENT_SUCCES,
      });
    } else {
      yield put({
        type: POST_ASSIGNMENT_FAILED,
        status: 'failed',
      });
      toast.error(res.data.message);
    }
  } catch (err) {
    yield put({
      type: POST_ASSIGNMENT_FAILED,
      status: 'failed',
    });
    toast.error(
      err.response
        ? err.response.data.message
        : 'An unexpected error occurred. Please try again.',
    );
  }
}

export function* watchAssignmentSaga() {
  yield takeLatest(POST_ASSIGNMENT, postAssignmentSaga);
}

//list of assignments
export const getListAssignmentAPI = (payload) => {
  return Axios.get(
    `${REACT_APP_BASEURL}/assignment/list?userId=${payload.userId}&consultantId=${payload.consultantId}`,
  );
};

export function* getListAssignmentSaga(paylaod) {
  try {
    const res = yield call(getListAssignmentAPI, paylaod);

    if (res.status === 200) {
      yield put({
        type: GET_LIST_ASSIGNMENTS_SUCCES,
        assignmentList: res.data.assignmentList,
      });
    } else {
      yield put({
        type: GET_LIST_ASSIGNMENTS_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_LIST_ASSIGNMENTS_FAILED,
    });
  }
}

export function* watchGetListAssignmentSaga() {
  yield takeLatest(GET_LIST_ASSIGNMENTS, getListAssignmentSaga);
}

// get assignment byid
export const getAssignmentAPI = (id) => {
  return Axios.get(`${REACT_APP_BASEURL}/assignment/by/${id}`);
};

export function* getAssignmentSaga({ id }) {
  try {
    const res = yield call(getAssignmentAPI, id);

    if (res.status === 200) {
      yield put({
        type: GET_ASSIGNMENT_BYID_SUCCES,
        assignmentById: res.data.assignmentById,
        selectedassignment: id,
      });
    } else {
      yield put({
        type: GET_ASSIGNMENT_BYID_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_ASSIGNMENT_BYID_FAILED,
    });
  }
}

export function* watchGetAssignmentSaga() {
  yield takeLatest(GET_ASSIGNMENT_BYID, getAssignmentSaga);
}

// update assignment  item
export const updateAssignmentAPI = ({ id, _id, values }) => {
  return Axios.put(`${REACT_APP_BASEURL}/assignment/${id}/${_id}`, values);
};

export function* updateAssignmentSaga(payload) {
  try {
    const { id, _id, values } = payload;

    const res = yield call(updateAssignmentAPI, { id, _id, values });

    if (res.status === 200) {
      yield put({
        type: UPDATE_ASSIGNMENT_BYID_SUCCES,
        status: res.data,
      });
    } else {
      yield put({
        type: UPDATE_ASSIGNMENT_BYID_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_ASSIGNMENT_BYID_FAILED,
    });
  }
}

export function* watchUpdateAssignmentSaga() {
  yield takeLatest(UPDATE_ASSIGNMENT_BYID, updateAssignmentSaga);
}

// section of  career committee
export const postCareerCommitteAPI = (payload) => {
  return Axios.post(`${REACT_APP_BASEURL}/careercommittee/add`, payload);
};

export function* postCareerCommitteSaga(payload) {
  try {
    const formData = new FormData();
    const myUploadFile = yield select(
      (state) => state.get('careerMonitoring').uploadFile,
    );

    formData.append('values', JSON.stringify(payload.values));
    formData.append('uploadedFile', myUploadFile);

    const res = yield call(postCareerCommitteAPI, formData);

    if (res.status === 201) {
      yield put({
        type: POST_CAREER_COMMITTEE_SUCCESS,
        uploadFile: null,
      });
    } else {
      yield put({
        type: POST_CAREER_COMMITTEE_FAILED,
        status: 'failed',
        uploadFile: null,
      });
      toast.error('Error adding the  committee');
    }
  } catch (err) {
    yield put({
      type: POST_CAREER_COMMITTEE_FAILED,
      status: 'failed',
      uploadFile: null,
    });
  }
}

export function* watchpostCareerCommitteSaga() {
  yield takeLatest(POST_CAREER_COMMITTEE, postCareerCommitteSaga);
}

//list of career commettees
export const getCareerCommitteListAPI = (payload) => {
  return Axios.get(
    `${REACT_APP_BASEURL}/careercommittee/list?userId=${payload.userId}&consultantId=${payload.consultantId}`,
  );
};

export function* getCareerCommitteListSaga(paylaod) {
  try {
    const res = yield call(getCareerCommitteListAPI, paylaod);

    if (res.status === 200) {
      yield put({
        type: GET_CAREER_COMMITTEE_LIST_SUCCESS,
        careerCommittees: res.data.careerCommittees,
      });
    } else {
      yield put({
        type: GET_CAREER_COMMITTEE_LIST_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_CAREER_COMMITTEE_LIST_FAILED,
    });
  }
}

export function* watchGetCareerCommitteListSaga() {
  yield takeLatest(GET_CAREER_COMMITTEE_LIST, getCareerCommitteListSaga);
}

// get hrbp & rc

export const getHrbpRcApi = () => {
  return Axios.get(`${REACT_APP_BASEURL}/user/hrbp&rc`);
};
export function* getHrbpRcApiSaga() {
  try {
    const res = yield call(getHrbpRcApi);
    if (res.status === 200) {
      yield put({
        type: GET_HRBP_RC_SUCCESS,
        hrbpList: res.data.hrbpList,
        consultantRefList: res.data.consultantRefList,
      });
    } else {
      yield put({
        type: GET_HRBP_RC_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_HRBP_RC_FAILED,
    });
  }
}

export function* watchGetHrbpRcApiSaga() {
  yield takeLatest(GET_HRBP_RC, getHrbpRcApiSaga);
}

// validate committee
export const validateCommitteeAPI = (payload) => {
  return Axios.patch(
    `${REACT_APP_BASEURL}/careercommittee/validate/${payload.id}/${payload._id}`,
  );
};

export function* validateCommitteeSaga(values) {
  try {
    const res = yield call(validateCommitteeAPI, values);

    if (res.status === 200) {
      yield put({
        type: VALIDATE_COMMITTEE_SUCCESS,
        status: res.data.status,
      });
    } else {
      yield put({
        type: VALIDATE_COMMITTEE_FAILED,
        status: res.data.status,
      });
      toast.error('Error updating the trial period');
    }
  } catch (err) {
    yield put({
      type: VALIDATE_COMMITTEE_FAILED,
      status: 'failed',
    });
  }
}

export function* watchValidateCommitteeSaga() {
  yield takeLatest(VALIDATE_COMMITTEE, validateCommitteeSaga);
}

// get committee by id
export const getcommitteeByIdAPI = (id) => {
  return Axios.get(`${REACT_APP_BASEURL}/careercommittee/by/${id}`);
};

export function* getcommitteeByIdSaga({ id }) {
  try {
    const res = yield call(getcommitteeByIdAPI, id);

    if (res.status === 200) {
      yield put({
        type: GET_COMMITTEE_BYID_SUCCESS,
        committeeById: res.data.committeeById,
        selectedCommittee: id,
      });
    } else {
      yield put({
        type: GET_COMMITTEE_BYID_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_COMMITTEE_BYID_FAILED,
    });
  }
}

export function* watchGetcommitteeByIdSaga() {
  yield takeLatest(GET_COMMITTEE_BYID, getcommitteeByIdSaga);
}

// update committe by id
export const updateCommitteByIdAPI = ({ id, _id, formData }) => {
  return Axios.put(
    `${REACT_APP_BASEURL}/careercommittee/${id}/${_id}`,
    formData,
  );
};

export function* updateCommitteByIdSaga(payload) {
  try {
    const { id, _id, values } = payload;
    const formData = new FormData();
    const myUploadFile = yield select(
      (state) => state.get('careerMonitoring').uploadFile,
    );

    formData.append('values', JSON.stringify(values));
    formData.append('uploadedFile', myUploadFile);

    const res = yield call(updateCommitteByIdAPI, { id, _id, formData });

    if (res.status === 200) {
      yield put({
        type: UPDATE_COMMITTEE_BYID_SUCCESS,
        status: res.data,
        uploadFile: null,
      });
    } else {
      yield put({
        type: UPDATE_COMMITTEE_BYID_FAILED,
        uploadFile: null,
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_COMMITTEE_BYID_FAILED,
      uploadFile: null,
    });
  }
}

export function* watchUpdateCommitteByIdSaga() {
  yield takeLatest(UPDATE_COMMITTEE_BYID, updateCommitteByIdSaga);
}

export default function* rootCareerMonitoringSaga() {
  yield all([
    watchGetListTrackingPointSaga(),
    watchPostTrackingPoin(),
    watchPatchTrackingPoin(),
    watchGetTrackingPointByIdSaga(),
    watchupdateTrackingPoint(),
    watchpostTrialPeriod(),
    watchGetListTrialPeriodSaga(),
    watchValidateTrialPeriodSaga(),
    watchGetTrialPeriodByIdSaga(),
    watchUpdateTrialPeriodSaga(),
    watchAssignmentSaga(),
    watchGetListAssignmentSaga(),
    watchGetAssignmentSaga(),
    watchUpdateAssignmentSaga(),
    watchpostCareerCommitteSaga(),
    watchGetCareerCommitteListSaga(),
    watchGetHrbpRcApiSaga(),
    watchValidateCommitteeSaga(),
    watchGetcommitteeByIdSaga(),
    watchUpdateCommitteByIdSaga(),
  ]);
}
