// inject app sagas
import rootConsultantSaga from 'containers/CreateProfile/saga';
import rootDashSaga from 'containers/DashboardPage/saga';
import rootHomeSaga from 'containers/HomePage/saga';
import rootTableSaga from 'containers/table/saga';
import rootCareerMonitoringSaga from 'containers/DetailsPage/career-monitoring/saga';
import rootKpiSaga from 'containers/Reporting/saga';
import {
  rootCountriesSaga,
  rootActivitiesSaga,
  rootNotificationsSaga,
  rootResumesSaga,
} from 'store/sagas';

export default [
  rootConsultantSaga,
  rootDashSaga,
  rootHomeSaga,
  rootTableSaga,
  rootCountriesSaga,
  rootActivitiesSaga,
  rootNotificationsSaga,
  rootResumesSaga,
  rootCareerMonitoringSaga,
  rootKpiSaga,
];
