import {
  GET_INTERCONTRACT_QUANTITY_COUNTRIES,
  GET_INTERCONTRACT_QUANTITY_COUNTRIES_SUCCES,
  GET_INTERCONTRACT_QUANTITY_COUNTRIES_FAILED,
  GET_INTERCONTRACT_QUANTITY_COMMUNITIES,
  GET_INTERCONTRACT_QUANTITY_COMMUNITIES_FAILED,
  GET_INTERCONTRACT_QUANTITY_COMMUNITIES_SUCCES,
  GET_INTERCONTRACT_COST_COUNTRIES_FAILED,
  GET_INTERCONTRACT_COST_COUNTRIES_SUCCES,
  GET_INTERCONTRACT_COST_COUNTRIES,
  GET_INTERCONTRACT_COST_COMMUNITIES_FAILED,
  GET_INTERCONTRACT_COST_COMMUNITIES_SUCCES,
  GET_INTERCONTRACT_COST_COMMUNITIES,
  GET_INTERCONTRACT_UNIQUE_COUNTRIES,
  GET_INTERCONTRACT_UNIQUE_COUNTRIES_SUCCES,
  GET_INTERCONTRACT_UNIQUE_COUNTRIES_FAILED,
  GET_INTERCONTRACT_UNIQUE_COMMUNITIES,
  GET_INTERCONTRACT_UNIQUE_COMMUNITIES_SUCCES,
  GET_INTERCONTRACT_UNIQUE_COMMUNITIES_FAILED,
  GET_YTD_FAILED,
  GET_YTD_SUCCES,
  GET_YTD,
  GET_HR_KPI_SUCCES,
  GET_HR_KPI_FAILED,
  GET_HR_KPI,
} from 'containers/Reporting/constants';

import { all, call, put, takeLatest } from 'redux-saga/effects';
import Axios from 'utils/Axios';

const { REACT_APP_BASEURL } = process.env;

export const getIntercontractQuantityCountriesAPI = (payload) => {
  return Axios.post(
    `${REACT_APP_BASEURL}/kpi/weeklyintercontractcountbycountries`,
    payload.values,
  );
};

export function* getIntercontractQuantityCountriesSaga(values) {
  try {
    const res = yield call(getIntercontractQuantityCountriesAPI, values);
    if (res.status === 200) {
      yield put({
        type: GET_INTERCONTRACT_QUANTITY_COUNTRIES_SUCCES,
        intercontractsQuantityCountries:
          res.data.intercontractsQuantityCountries,
      });
    } else {
      yield put({
        type: GET_INTERCONTRACT_QUANTITY_COUNTRIES_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: GET_INTERCONTRACT_QUANTITY_COUNTRIES_FAILED,
      status: 'failed',
    });
  }
}

export function* watchIntercontractQuantityCountries() {
  yield takeLatest(
    GET_INTERCONTRACT_QUANTITY_COUNTRIES,
    getIntercontractQuantityCountriesSaga,
  );
}

export const getIntercontractQuantityCommunitiesAPI = (payload) => {
  return Axios.post(
    `${REACT_APP_BASEURL}/kpi/weeklyintercontractcountbycommunities`,
    payload.values,
  );
};

export function* getIntercontractQuantityCommunitiesSaga(values) {
  try {
    const res = yield call(getIntercontractQuantityCommunitiesAPI, values);
    if (res.status === 200) {
      yield put({
        type: GET_INTERCONTRACT_QUANTITY_COMMUNITIES_SUCCES,
        intercontractsQuantityCommunities:
          res.data.intercontractsQuantityCommunities,
      });
    } else {
      yield put({
        type: GET_INTERCONTRACT_QUANTITY_COMMUNITIES_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: GET_INTERCONTRACT_QUANTITY_COMMUNITIES_FAILED,
      status: 'failed',
    });
  }
}

export function* watchIntercontractQuantityCommunitites() {
  yield takeLatest(
    GET_INTERCONTRACT_QUANTITY_COMMUNITIES,
    getIntercontractQuantityCommunitiesSaga,
  );
}

export const getIntercontractCostCountriesAPI = (payload) => {
  return Axios.post(
    `${REACT_APP_BASEURL}/kpi/weeklyintercontractcostbycountries`,
    payload.values,
  );
};

export function* getIntercontractCostCountriesSaga(values) {
  try {
    const res = yield call(getIntercontractCostCountriesAPI, values);
    if (res.status === 200) {
      yield put({
        type: GET_INTERCONTRACT_COST_COUNTRIES_SUCCES,
        intercontractsCostCountries: res.data.intercontractsCostCountries,
      });
    } else {
      yield put({
        type: GET_INTERCONTRACT_COST_COUNTRIES_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: GET_INTERCONTRACT_COST_COUNTRIES_FAILED,
      status: 'failed',
    });
  }
}

export function* watchIntercontractCostCountries() {
  yield takeLatest(
    GET_INTERCONTRACT_COST_COUNTRIES,
    getIntercontractCostCountriesSaga,
  );
}

export const getIntercontractCostCommunitiesAPI = (payload) => {
  return Axios.post(
    `${REACT_APP_BASEURL}/kpi/weeklyintercontractcostbycommunities`,
    payload.values,
  );
};

export function* getIntercontractCostCommunitiesSaga(values) {
  try {
    const res = yield call(getIntercontractCostCommunitiesAPI, values);
    if (res.status === 200) {
      yield put({
        type: GET_INTERCONTRACT_COST_COMMUNITIES_SUCCES,
        intercontractsCostCommunities: res.data.intercontractsCostCommunities,
      });
    } else {
      yield put({
        type: GET_INTERCONTRACT_COST_COMMUNITIES_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: GET_INTERCONTRACT_COST_COMMUNITIES_FAILED,
      status: 'failed',
    });
  }
}

export function* watchIntercontractCostCommunities() {
  yield takeLatest(
    GET_INTERCONTRACT_COST_COMMUNITIES,
    getIntercontractCostCommunitiesSaga,
  );
}

export const getIntercontractUniqueCountriesAPI = (payload) => {
  return Axios.post(
    `${REACT_APP_BASEURL}/kpi/weeklyintercontractuniquebycountries`,
    payload.values,
  );
};

export function* getIntercontractUniqueCountriesSaga(values) {
  try {
    const res = yield call(getIntercontractUniqueCountriesAPI, values);
    if (res.status === 200) {
      yield put({
        type: GET_INTERCONTRACT_UNIQUE_COUNTRIES_SUCCES,
        intercontractsUniqueCountries: res.data.intercontractsUniqueCountries,
      });
    } else {
      yield put({
        type: GET_INTERCONTRACT_UNIQUE_COUNTRIES_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: GET_INTERCONTRACT_UNIQUE_COUNTRIES_FAILED,
      status: 'failed',
    });
  }
}

export function* watchIntercontractUniqueCountries() {
  yield takeLatest(
    GET_INTERCONTRACT_UNIQUE_COUNTRIES,
    getIntercontractUniqueCountriesSaga,
  );
}

export const getIntercontractUniqueCommunitiesAPI = (payload) => {
  return Axios.post(
    `${REACT_APP_BASEURL}/kpi/weeklyintercontractuniquebycommunities`,
    payload.values,
  );
};

export function* getIntercontractUniqueCommunitiesSaga(values) {
  try {
    const res = yield call(getIntercontractUniqueCommunitiesAPI, values);
    if (res.status === 200) {
      yield put({
        type: GET_INTERCONTRACT_UNIQUE_COMMUNITIES_SUCCES,
        intercontractsUniqueCommunities:
          res.data.intercontractsUniqueCommunities,
      });
    } else {
      yield put({
        type: GET_INTERCONTRACT_UNIQUE_COMMUNITIES_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: GET_INTERCONTRACT_UNIQUE_COMMUNITIES_FAILED,
      status: 'failed',
    });
  }
}

export function* watchIntercontractUniqueCommunities() {
  yield takeLatest(
    GET_INTERCONTRACT_UNIQUE_COMMUNITIES,
    getIntercontractUniqueCommunitiesSaga,
  );
}

export const getYtdAPI = (payload) => {
  return Axios.post(`${REACT_APP_BASEURL}/kpi/ytd`, payload.values);
};

export function* getYtdSaga(values) {
  try {
    const res = yield call(getYtdAPI, values);
    if (res.status === 200) {
      yield put({
        type: GET_YTD_SUCCES,
        ytdCount: res.data.ytdCount,
        ytdCost: res.data.ytdCost,
      });
    } else {
      yield put({
        type: GET_YTD_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: GET_YTD_FAILED,
      status: 'failed',
    });
  }
}

export function* watchYtd() {
  yield takeLatest(GET_YTD, getYtdSaga);
}

export const getHrKpiAPI = (payload) => {
  return Axios.post(`${REACT_APP_BASEURL}/kpi/hr`, payload.values);
};

export function* getHrKpiSaga(values) {
  try {
    const res = yield call(getHrKpiAPI, values);
    if (res.status === 200) {
      yield put({
        type: GET_HR_KPI_SUCCES,
        hrKpi: res.data.hrKpi,
      });
    } else {
      yield put({
        type: GET_HR_KPI_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: GET_HR_KPI_FAILED,
      status: 'failed',
    });
  }
}

export function* watchHrKpi() {
  yield takeLatest(GET_HR_KPI, getHrKpiSaga);
}

export default function* rootDelegationSaga() {
  yield all([
    watchIntercontractQuantityCountries(),
    watchIntercontractQuantityCommunitites(),
    watchIntercontractCostCountries(),
    watchIntercontractCostCommunities(),
    watchIntercontractUniqueCountries(),
    watchIntercontractUniqueCommunities(),
    watchYtd(),
    watchHrKpi(),
  ]);
}
