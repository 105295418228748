import * as React from 'react';

const ListIcon = (props) => {
  return (
    <svg width={20} height={13} viewBox="0 0 20 13">
      <path
        d="M10 0C8.39 0 7.083 1.273 7.083 2.844c0 1.57 1.306 2.844 2.917 2.844 1.61 0 2.917-1.274 2.917-2.844C12.917 1.274 11.61 0 10 0zM3.75 2.438c-1.15 0-2.083.909-2.083 2.03 0 1.123.932 2.032 2.083 2.032a2.11 2.11 0 001.473-.595c.39-.38.61-.898.61-1.436 0-.539-.22-1.056-.61-1.437a2.11 2.11 0 00-1.473-.595zm12.5 0c-1.15 0-2.083.909-2.083 2.03 0 1.123.932 2.032 2.083 2.032a2.11 2.11 0 001.473-.595c.39-.38.61-.898.61-1.436 0-1.122-.932-2.032-2.083-2.032zM10 7.311c-1.947 0-5.833.954-5.833 2.844v2.031c0 .449.373.813.833.813h10c.46 0 .833-.364.833-.813v-2.03c0-1.891-3.886-2.845-5.833-2.845zm-6.751.856C1.93 8.366 0 9.254 0 10.323v1.864c0 .449.373.813.833.813H2.5v-2.844c0-.78.288-1.436.749-1.988zm13.502 0c.461.552.749 1.207.749 1.988V13h1.667c.46 0 .833-.364.833-.813v-1.864c0-1.07-1.931-1.957-3.249-2.155z"
        {...props}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ListIcon;
