/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Hidden, Link } from '@material-ui/core';
import { SaveAlt, Search } from '@material-ui/icons';
import { Filale, status } from 'containers/CreateProfile/constants';
import { translate } from 'containers/services/translate';
import { Form, Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { useJsonToCsv } from 'react-json-csv';
import styled from 'styled-components';
import { Button, Input, Select } from 'utils/focus-ui-kit';
import messages from 'utils/focus-ui-kit/dahsboard-dg-ui-kit/TableDashboard/messages';
import { getNameFromToken } from 'utils/utils';

const FiltersDashboard = ({
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  type,
  setType,
  handleGetdashboardDt,
  dashboardDt,
  ...props
}) => {
  const {
    filterTableDgSearch,
    filterTableDgReset,
    filterTableDgExportBtn,
    filterTableDgStatus,
    filterTableDgFiliale,
  } = messages;

  /* istanbul ignore next */
  const handleClear = () => {
    setRowsPerPage(5);
    setPage(0);
    setType('normal');
    handleGetdashboardDt({
      page: page + 1,
      size: rowsPerPage,
    });
  };

  const dateE = moment(new Date()).format('DD-MM-YYYY');
  const token = localStorage.getItem('token');
  const filename = `${dateE}-${getNameFromToken(token)}-Powers-detail-export`;
  const style = {
    padding: '30px',
  };

  const fields = {
    created_by: 'Emetteur de la délégation',
    filiale: 'Filiale',
    first_name: 'Nom',
    last_name: 'Prénom',
    email: 'Adresse mail',
    created_at: 'Sent on',
    name: 'Element quantitative',
    max: 'Maximum',
  };

  const { saveAsCsv } = useJsonToCsv();
  const handleCsvFile = /* istanbul ignore next */ () => {
    const data = [];
    dashboardDt &&
      dashboardDt.forEach((element, i) => {
        element.equantity.forEach((eq, j) => {
          if (i === j + i) {
            data.push({
              created_by: element.dop.created_by,
              filiale: element.dop.filiale,
              first_name: element.dop.first_name,
              last_name: element.dop.last_name,
              email: element.dop.email,
              created_at: element.dop.created_at,
              name: eq.name,
              max: eq.max,
            });
          } else {
            data.push({
              name: eq.name,
              max: eq.max,
            });
          }
        });
      });
    if (Object.keys(data).length > 0) {
      saveAsCsv({ data, fields, filename, style });
    }
  };

  return (
    <Formik
      initialValues={{
        search: '',
        filiale: '',
        status: '',
      }}
      //  onSubmit={handleFormSubmit}
    >
      {({ resetForm, handleSubmit }) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item lg={4} md={12}>
              <Input
                name="search"
                type="text"
                placeholder={translate(filterTableDgSearch, props)}
                icon={<Search />}
              />
            </Grid>
            <Grid item lg={1} md={4}>
              <Select
                name="filiale"
                type="text"
                placeholder={translate(filterTableDgFiliale, props)}
                options={Filale}
              />
            </Grid>
            <Grid item lg={1} md={4}>
              <Select
                name="status"
                type="text"
                placeholder={translate(filterTableDgStatus, props)}
                options={status}
                statusLabel
              />
            </Grid>
            <Grid item lg={1} md={4}>
              <Button
                height={48}
                bgcolor="#0075D0"
                textcolor="white"
                endIcon={null}
                startIcon={null}
                onClick={handleSubmit}
              >
                {translate(filterTableDgSearch, props)}
              </Button>
            </Grid>
            <CustomGrid item lg={1} md={4}>
              <Link
                underline="none"
                onClick={
                  /* istanbul ignore next */ () => {
                    handleClear();
                    resetForm();
                  }
                }
              >
                {translate(filterTableDgReset, props)}
              </Link>
            </CustomGrid>
            <Hidden only={['md', 'sm', 'xs']}>
              <Grid item lg={1} />
            </Hidden>
            <Grid item lg={1} md={4} />
            <Grid item lg={2} md={4}>
              <Button
                height={48}
                bgcolor="#63B545"
                textcolor="white"
                endIcon={<SaveAlt />}
                onClick={handleCsvFile}
              >
                {translate(filterTableDgExportBtn, props)}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const CustomGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    cursor: pointer;
  }
`;

FiltersDashboard.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  handleGetdashboardDt: PropTypes.func.isRequired,
  dashboardDt: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default injectIntl(FiltersDashboard);
