import {
  GET_ALL_ACTIVITIES,
  GET_ALL_ACTIVITIES_FAILED,
  GET_ALL_ACTIVITIES_SUCCES,
  GET_ACTIVITY,
  GET_ACTIVITY_FAILED,
  GET_ACTIVITY_SUCCESS,
} from 'shared';

import { all, call, put, takeLatest } from 'redux-saga/effects';
import Axios from 'utils/Axios';

const { REACT_APP_BASEURL } = process.env;

export const getActivitiesApi = (consultantId) => {
  return Axios.get(
    `${REACT_APP_BASEURL}/activities/consultant/${consultantId}`,
  );
};

export function* getActivitiesSaga({ consultantId }) {
  try {
    const res = yield call(getActivitiesApi, consultantId);

    if (res.status === 200) {
      yield put({
        type: GET_ALL_ACTIVITIES_SUCCES,
        activities: res.data,
      });
    } else {
      yield put({
        type: GET_ALL_ACTIVITIES_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_ALL_ACTIVITIES_FAILED,
    });
  }
}

export const getActivityApiByCode = (id) => {
  return Axios.get(`${REACT_APP_BASEURL}/activities/${id}`);
};

export function* getActivityByCodeSaga({ code }) {
  try {
    const res = yield call(getActivityApiByCode, code);
    if (res.status === 200) {
      yield put({
        type: GET_ACTIVITY_SUCCESS,
        activity: res.data,
      });
    } else {
      yield put({
        type: GET_ACTIVITY_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_ACTIVITY_FAILED,
    });
  }
}

export function* watchGetActivities() {
  yield takeLatest(GET_ALL_ACTIVITIES, getActivitiesSaga);
  yield takeLatest(GET_ACTIVITY, getActivityByCodeSaga);
}

export function* rootActivitiesSaga() {
  yield all([watchGetActivities()]);
}
