import {
  GET_ALL_COUNTRIES,
  GET_ALL_COUNTRIES_FAILED,
  GET_ALL_COUNTRIES_SUCCES,
} from 'shared';
import { formatCountries } from 'utils/formatters';

const initialState = {
  countries: [],
  isCountriesLoading: false,
};

export function createCountriesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COUNTRIES:
      return {
        ...state,
        isCountriesLoading: true,
      };
    case GET_ALL_COUNTRIES_SUCCES:
      return {
        ...state,
        isCountriesLoading: false,
        countries: formatCountries(action.countries),
      };
    case GET_ALL_COUNTRIES_FAILED:
      return {
        ...state,
        isCountriesLoading: true,
        countries: null,
      };
    default:
      return state;
  }
}
