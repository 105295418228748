import { NavigateNext } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StaticItem = (props) => {
  const { title, count, to, init, handlefilter } = props;

  return (
    <Container onClick={handlefilter} {...props}>
      <span>{count}</span>
      <span>{title}</span>
      {to && init && <NavigateNext />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  ${(props) => props.to && props.init && 'background-color: #f0f2f7'};
  padding: 4px 8px;
  border-radius: 16px;
  cursor: pointer;

  span {
    font-size: 11px;
    letter-spacing: 0;
    line-height: 13px;
  }

  span:nth-child(2) {
    color: #323c47;
    margin-left: 9px;
    margin-right: 4px;
  }

  svg {
    font-size: 1rem;
    fill: rgba(50, 60, 71, 0.33);
  }
`;

StaticItem.defaultProps = {
  init: false,
  to: '',
};

StaticItem.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  init: PropTypes.bool,
  to: PropTypes.string,
  handlefilter: PropTypes.func.isRequired,
};

export default StaticItem;
