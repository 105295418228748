import * as React from 'react';

const DelegIcon = (props) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16">
      <path
        d="M2.4 0h8a2.4 2.4 0 012.4 2.4v.8h.8A2.4 2.4 0 0116 5.6v8a2.4 2.4 0 01-2.4 2.4h-8a2.4 2.4 0 01-2.4-2.4v-.8h-.8A2.4 2.4 0 010 10.4v-8A2.4 2.4 0 012.4 0zm2.4 13.6a.8.8 0 00.8.8h8a.8.8 0 00.8-.8V8H4.8v5.6zm0-7.2h9.6v-.8a.8.8 0 00-.8-.8h-8a.8.8 0 00-.8.8v.8zm-3.2 4a.8.8 0 00.8.8h.8V5.6a2.4 2.4 0 01.144-.8H1.6v5.6zm0-7.2h9.6v-.8a.8.8 0 00-.8-.8h-8a.8.8 0 00-.8.8v.8z"
        {...props}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default DelegIcon;
