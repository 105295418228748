import {
  POST_TRACKING_POINT,
  POST_TRACKING_POINT_SUCCES,
  POST_TRACKING_POINT_FAILED,
  GET_TRACKING_POINTS,
  GET_TRACKING_POINTS_SUCCES,
  GET_TRACKING_POINTS_FAILED,

  // patch tracking point
  PATCH_TRACKING_POINT,
  PATCH_TRACKING_POINT_SUCCES,
  PATCH_TRACKING_POINT_FAILED,

  //get by id
  GET_TRACKING_POINT_BYID,
  GET_TRACKING_POINT_BYID_SUCCES,
  GET_TRACKING_POINT_BYID_FAILED,

  // update tracking point by id
  UPDATE_TRACKING_POINT_BYID,
  UPDATE_TRACKING_POINT_BYID_SUCCES,
  UPDATE_TRACKING_POINT_BYID_FAILED,

  // post trail period
  POST_TRIAL_PERIOD,
  POST_TRIAL_PERIOD_SUCCES,
  POST_TRIAL_PERIOD_FAILED,

  // list of trail period
  GET_TRIAL_PERIODS,
  GET_TRIAL_PERIODS_SUCCES,
  GET_TRIAL_PERIODS_FAILED,

  // UPLOADFILE
  UPLOADFILE,

  // validate a tracking point
  VALIDATE_TRIAL_POINT,
  VALIDATE_TRIAL_POINT_SUCCES,
  VALIDATE_TRIAL_POINT_FAILED,

  // get trail period by id
  GET_TRIAL_PERIOD_BYID,
  GET_TRIAL_PERIOD_BYID_SUCCES,
  GET_TRIAL_PERIOD_BYID_FAILED,

  // update trail period by id
  UPDATE_TRAIL_PERIOD_BYID,
  UPDATE_TRAIL_PERIOD_BYID_SUCCES,
  UPDATE_TRAIL_PERIOD_BYID_FAILED,
  CANCEL_EDIT,

  // list of  assignements
  GET_LIST_ASSIGNMENTS,
  GET_LIST_ASSIGNMENTS_SUCCES,
  GET_LIST_ASSIGNMENTS_FAILED,

  // post assignement
  POST_ASSIGNMENT,
  POST_ASSIGNMENT_SUCCES,
  POST_ASSIGNMENT_FAILED,

  // get assignment by id
  GET_ASSIGNMENT_BYID,
  GET_ASSIGNMENT_BYID_SUCCES,
  GET_ASSIGNMENT_BYID_FAILED,

  // update assignment by id
  UPDATE_ASSIGNMENT_BYID,
  UPDATE_ASSIGNMENT_BYID_SUCCES,
  UPDATE_ASSIGNMENT_BYID_FAILED,

  // post career committee
  POST_CAREER_COMMITTEE,
  POST_CAREER_COMMITTEE_SUCCESS,
  POST_CAREER_COMMITTEE_FAILED,

  // get list of committes
  GET_CAREER_COMMITTEE_LIST,
  GET_CAREER_COMMITTEE_LIST_SUCCESS,
  GET_CAREER_COMMITTEE_LIST_FAILED,

  // get hrbp and RC
  GET_HRBP_RC,
  GET_HRBP_RC_SUCCESS,
  GET_HRBP_RC_FAILED,

  // validate committee
  VALIDATE_COMMITTEE,
  VALIDATE_COMMITTEE_SUCCESS,
  VALIDATE_COMMITTEE_FAILED,

  // get committe by id
  GET_COMMITTEE_BYID,
  GET_COMMITTEE_BYID_SUCCESS,
  GET_COMMITTEE_BYID_FAILED,

  // update committee by id
  UPDATE_COMMITTEE_BYID,
  UPDATE_COMMITTEE_BYID_SUCCESS,
  UPDATE_COMMITTEE_BYID_FAILED,
} from './constants';

const initialState = {
  reloading: true,
  trackigPOintList: [],
  trackigPOintListLoading: false,
  patchLoading: true,
  currentTrackingpoint: null,
  LoadingcurrentTrackingpoint: true,
  isEditable: false,
  trailpreloading: true,
  trialPeriodsList: [],
  trialPeriodsListLoader: false,
  uploadFile: null,
  validateLoading: true,
  currentTrialPeriod: null,
  LoadingcurrentTrialPeriod: false,
  isEditableTrialPeriod: false,
  selectedTrialPeriod: null,
  trailReloading: false,
  assignmentList: [],
  assignmentListLoading: false,
  assignmentReloading: false,
  isEditableAssignment: false,

  currentAssignment: null,
  LoadingcurrentAssignment: false,
  isEditableAsignment: false,
  updateAssignmentReloading: false,

  committesListLoading: false,
  committesList: [],

  hrbpRcLoading: false,
  hrbpList: [],
  consultantRefList: [],
  validateCommitteeLoading: false,

  currentCommittee: null,
  LoadingCurrentCommittee: false,
  isEditableCommittee: false,
  selectedCommittee: null,
  Committeereloading: false,
};

const careerMonitoringReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_TRACKING_POINT:
      return {
        ...state,
        reloading: true,
      };
    case POST_TRACKING_POINT_SUCCES:
      return {
        ...state,
        reloading: false,
        uploadFile: null,
      };
    case POST_TRACKING_POINT_FAILED:
      return {
        ...state,
        reloading: false,
        uploadFile: null,
      };

    // list of tracking point
    case GET_TRACKING_POINTS:
      return {
        ...state,
        trackigPOintListLoading: true,
      };
    case GET_TRACKING_POINTS_SUCCES:
      return {
        ...state,
        trackigPOintListLoading: false,
        trackigPOintList: action.trackigPOintList,
      };
    case GET_TRACKING_POINTS_FAILED:
      return {
        ...state,
        trackigPOintListLoading: false,
        trackigPOintList: [],
      };

    // patch tracking point
    case PATCH_TRACKING_POINT:
      return {
        ...state,
        patchLoading: true,
      };
    case PATCH_TRACKING_POINT_SUCCES:
      return {
        ...state,
        patchLoading: false,
      };
    case PATCH_TRACKING_POINT_FAILED:
      return {
        ...state,
        patchLoading: false,
      };

    // tracking point by id
    case GET_TRACKING_POINT_BYID:
      return {
        ...state,
        currentTrackingpoint: null,
        LoadingcurrentTrackingpoint: true,
        isEditable: false,
      };
    case GET_TRACKING_POINT_BYID_SUCCES:
      return {
        ...state,
        currentTrackingpoint: action.trackingPointById,
        LoadingcurrentTrackingpoint: false,
        isEditable: true,
      };
    case GET_TRACKING_POINT_BYID_FAILED:
      return {
        ...state,
        currentTrackingpoint: null,
        LoadingcurrentTrackingpoint: false,
        isEditable: false,
      };

    case UPDATE_TRACKING_POINT_BYID:
      return {
        ...state,
        updatedReloading: true,
      };
    case UPDATE_TRACKING_POINT_BYID_SUCCES:
      return {
        ...state,
        updatedReloading: false,
        isEditable: false,
        uploadFile: null,
      };
    case UPDATE_TRACKING_POINT_BYID_FAILED:
      return {
        ...state,
        updatedReloading: false,
        uploadFile: null,
      };

    // trial period reducers:
    case POST_TRIAL_PERIOD:
      return {
        ...state,
        trailpreloading: true,
      };
    case POST_TRIAL_PERIOD_SUCCES:
      return {
        ...state,
        trailpreloading: false,
        uploadFile: null,
      };
    case POST_TRIAL_PERIOD_FAILED:
      return {
        ...state,
        trailpreloading: false,
        uploadFile: null,
      };

    // list of tracking point
    case GET_TRIAL_PERIODS:
      return {
        ...state,
        trialPeriodsList: [],
        trialPeriodsListLoader: true,
      };
    case GET_TRIAL_PERIODS_SUCCES:
      return {
        ...state,
        trialPeriodsListLoader: false,
        trialPeriodsList: action.trialPeriodsList,
      };
    case GET_TRIAL_PERIODS_FAILED:
      return {
        ...state,
        trialPeriodsListLoader: false,
        trialPeriodsList: [],
      };

    case UPLOADFILE:
      return {
        ...state,
        uploadFile: action.file,
      };

    // validate trial period
    case VALIDATE_TRIAL_POINT:
      return {
        ...state,
        validateLoading: true,
      };
    case VALIDATE_TRIAL_POINT_SUCCES:
      return {
        ...state,
        validateLoading: false,
      };
    case VALIDATE_TRIAL_POINT_FAILED:
      return {
        ...state,
        validateLoading: false,
      };

    // tracking point by id
    case GET_TRIAL_PERIOD_BYID:
      return {
        ...state,
        currentTrialPeriod: null,
        LoadingcurrentTrialPeriod: true,
        isEditableTrialPeriod: false,
      };
    case GET_TRIAL_PERIOD_BYID_SUCCES:
      return {
        ...state,
        currentTrialPeriod: action.trialPeriodById,
        LoadingcurrentTrialPeriod: false,
        isEditableTrialPeriod: true,
        selectedTrialPeriod: action.selectedTrialPeriod,
      };
    case GET_TRIAL_PERIOD_BYID_FAILED:
      return {
        ...state,
        currentTrialPeriod: null,
        LoadingcurrentTrialPeriod: false,
        isEditableTrialPeriod: false,
      };

    // update trail period
    case UPDATE_TRAIL_PERIOD_BYID:
      return {
        ...state,
        trailReloading: true,
      };
    case UPDATE_TRAIL_PERIOD_BYID_SUCCES:
      return {
        ...state,
        trailReloading: false,
        isEditableTrialPeriod: false,
      };
    case UPDATE_TRAIL_PERIOD_BYID_FAILED:
      return {
        ...state,
        trailReloading: false,
      };

    case CANCEL_EDIT:
      return {
        ...state,
        isEditable: action.isEditable,
        isEditableTrialPeriod: action.isEditableTrialPeriod,
        isEditableAsignment: action.isEditableAsignment,
        isEditableCommittee: action.isEditableCommittee,
      };

    // list of assignments
    case GET_LIST_ASSIGNMENTS:
      return {
        ...state,
        assignmentList: [],
        assignmentListLoading: true,
      };
    case GET_LIST_ASSIGNMENTS_SUCCES:
      return {
        ...state,
        assignmentListLoading: false,
        assignmentList: action.assignmentList,
      };
    case GET_LIST_ASSIGNMENTS_FAILED:
      return {
        ...state,
        assignmentListLoading: false,
        assignmentList: [],
      };

    // post assignment
    case POST_ASSIGNMENT:
      return {
        ...state,
        assignmentReloading: true,
      };
    case POST_ASSIGNMENT_SUCCES:
      return {
        ...state,
        assignmentReloading: false,
      };
    case POST_ASSIGNMENT_FAILED:
      return {
        ...state,
        assignmentReloading: false,
      };

    // get assignment by id
    case GET_ASSIGNMENT_BYID:
      return {
        ...state,
        currentAssignment: null,
        LoadingcurrentAssignment: true,
        isEditableAsignment: false,
      };
    case GET_ASSIGNMENT_BYID_SUCCES:
      return {
        ...state,
        currentAssignment: action.assignmentById,
        LoadingcurrentAssignment: false,
        isEditableAsignment: true,
        selectedassignment: action.selectedassignment,
      };
    case GET_ASSIGNMENT_BYID_FAILED:
      return {
        ...state,
        currentAssignment: null,
        LoadingcurrentAssignment: false,
        isEditableAsignment: false,
      };

    // update assignment by id
    case UPDATE_ASSIGNMENT_BYID:
      return {
        ...state,
        updateAssignmentReloading: true,
      };
    case UPDATE_ASSIGNMENT_BYID_SUCCES:
      return {
        ...state,
        updateAssignmentReloading: false,
        isEditableAsignment: false,
      };
    case UPDATE_ASSIGNMENT_BYID_FAILED:
      return {
        ...state,
        updateAssignmentReloading: false,
        isEditableAsignment: false,
      };

    // list of committees
    case GET_CAREER_COMMITTEE_LIST:
      return {
        ...state,
        committesList: [],
        committesListLoading: true,
      };
    case GET_CAREER_COMMITTEE_LIST_SUCCESS:
      return {
        ...state,
        committesListLoading: false,
        committesList: action.careerCommittees,
      };
    case GET_CAREER_COMMITTEE_LIST_FAILED:
      return {
        ...state,
        committesListLoading: false,
        committesList: [],
      };

    // post career committee
    case POST_CAREER_COMMITTEE:
      return {
        ...state,
        committeeReloading: true,
      };
    case POST_CAREER_COMMITTEE_SUCCESS:
      return {
        ...state,
        committeeReloading: false,
      };
    case POST_CAREER_COMMITTEE_FAILED:
      return {
        ...state,
        committeeReloading: false,
      };

    // list of hrbp and CR
    case GET_HRBP_RC:
      return {
        ...state,
        hrbpRcLoading: true,
        hrbpList: [],
        consultantRefList: [],
      };
    case GET_HRBP_RC_SUCCESS:
      return {
        ...state,
        hrbpRcLoading: false,
        hrbpList: action.hrbpList,
        consultantRefList: action.consultantRefList,
      };
    case GET_HRBP_RC_FAILED:
      return {
        ...state,
        hrbpRcLoading: false,
        hrbpList: [],
        consultantRefList: [],
      };

    // validate committee
    case VALIDATE_COMMITTEE:
      return {
        ...state,
        validateCommitteeLoading: true,
      };
    case VALIDATE_COMMITTEE_SUCCESS:
      return {
        ...state,
        validateCommitteeLoading: false,
      };
    case VALIDATE_COMMITTEE_FAILED:
      return {
        ...state,
        validateCommitteeLoading: false,
      };

    // get committe by id
    case GET_COMMITTEE_BYID:
      return {
        ...state,
        currentCommittee: null,
        LoadingCurrentCommittee: true,
        isEditableCommittee: false,
      };
    case GET_COMMITTEE_BYID_SUCCESS:
      return {
        ...state,
        currentCommittee: action.committeeById,
        LoadingCurrentCommittee: false,
        isEditableCommittee: true,
        selectedCommittee: action.selectedCommittee,
      };
    case GET_COMMITTEE_BYID_FAILED:
      return {
        ...state,
        currentCommittee: null,
        LoadingCurrentCommittee: false,
        isEditableCommittee: false,
      };

    // update committee by id
    case UPDATE_COMMITTEE_BYID:
      return {
        ...state,
        Committeereloading: true,
      };
    case UPDATE_COMMITTEE_BYID_SUCCESS:
      return {
        ...state,
        Committeereloading: false,
        uploadFile: null,
      };
    case UPDATE_COMMITTEE_BYID_FAILED:
      return {
        ...state,
        Committeereloading: false,
        uploadFile: null,
      };

    default:
      return state;
  }
};

export default careerMonitoringReducer;
