import PropTypes from 'prop-types';
import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';
import styled from 'styled-components';

const Spinner = ({ loading, color, size, noHeight }) => (
  <Container noHeight={noHeight}>
    <SyncLoader
      sizeUnit="px"
      size={size || 10}
      color={color || '#0176BA'}
      loading={loading}
    />
  </Container>
);

export const Container = styled.div`
  width: 100%;
  height: ${({ noHeight }) => noHeight || '896px'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

Spinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  noHeight: PropTypes.string.isRequired,
};

export default Spinner;
