import React from 'react';
import { COLORS, DELEGATION_STATUS } from 'constants/constants';
import { translate } from 'containers/services/translate';
import jwt from 'jwt-decode';
import { useState } from 'react';
import messages from 'utils/messages';
import momentb from 'moment-business-days';
import moment from 'moment';
import holidays from 'date-holidays';
import countryName from 'country-list-js';
import { INTERCONTRAT } from 'shared';
import { PdfIcon, WordIcon, ExcelIcon, ImageIcon } from 'assets/svg';
import {
  ROLE_ADMIN,
  ROLE_COMMERCIAL,
  ROLE_CONSULTANT,
  ROLE_CONSULTANT_REF,
  ROLE_COO,
  ROLE_HRBP,
  ROLE_PRACTICE_LEADER,
} from './roles';

const {
  statusInValidation,
  statusNeedToBeReviewed,
  statusRead,
  statusSent,
  statusValidated,
  statusDeleted,
  statusDenied,
  statusInActive,
  statusUploaded,
  roleSubscriber,
  roleHeadOfUnderwriting,
} = messages;

const handleStatusColor = (status) => {
  switch (status) {
    case DELEGATION_STATUS.INVALIDATION:
      return COLORS.BLACK;
    case DELEGATION_STATUS.NEEDTOBEREVIEWED:
      return COLORS.ORANGE;
    case DELEGATION_STATUS.READ:
      return COLORS.BLUE;
    case DELEGATION_STATUS.SENT:
      return COLORS.GREY;
    case DELEGATION_STATUS.VALIDATED:
      return COLORS.GREEN;
    case DELEGATION_STATUS.INACTIVE:
      return COLORS.YELLOW;
    case DELEGATION_STATUS.UPLOADED:
      return COLORS.PINK;
    default:
      return COLORS.RED;
  }
};

const handleStatusLabel = (status, props) => {
  switch (status) {
    case DELEGATION_STATUS.INVALIDATION:
      return translate(statusInValidation, props);
    case DELEGATION_STATUS.NEEDTOBEREVIEWED:
      return translate(statusNeedToBeReviewed, props);
    case DELEGATION_STATUS.READ:
      return translate(statusRead, props);
    case DELEGATION_STATUS.SENT:
      return translate(statusSent, props);
    case DELEGATION_STATUS.VALIDATED:
      return translate(statusValidated, props);
    case DELEGATION_STATUS.DELETED:
      return translate(statusDeleted, props);
    case DELEGATION_STATUS.DENIED:
      return translate(statusDenied, props);
    case DELEGATION_STATUS.INACTIVE:
      return translate(statusInActive, props);
    case DELEGATION_STATUS.UPLOADED:
      return translate(statusUploaded, props);
    default:
      return null;
  }
};
const handleRoleLabel = (role, props) => {
  switch (role) {
    case 'Directeur Technique':
      return translate(roleHeadOfUnderwriting, props);
    case 'Souscripteur':
      return translate(roleSubscriber, props);
    default:
      return null;
  }
};

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return 'rgba(0, 117, 201, 0.53)';
};

const getNameFromToken = (token) => {
  return token && jwt(token).name;
};

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  function toggle() {
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    toggle,
  };
};

const useComentModal = () => {
  const [isShowing, setIsShowing] = useState(null);

  function toggle(id) {
    setIsShowing(id);
  }

  return {
    isShowing,
    toggle,
  };
};

const countBuissnessDays = (start, end, country) => {
  if (country === undefined) country = 'Maroc';
  const found = countryName.findByName(country);
  const ISO = found ? found.code.iso2 : 'MA';
  const hd = new holidays();
  hd.init(ISO);
  //getting the year range beetween the start and the end for substracting the holiday days
  const yearStart = moment(start).format('YYYY');
  const diffYears = moment(end).diff(moment(start), 'years');
  let arrHolidays = hd.getHolidays(yearStart);
  let hol = arrHolidays.map((arrHolidays) =>
    moment(arrHolidays.date).format('MM-DD-YYYY'),
  );
  let i = 1;
  while (i <= diffYears) {
    arrHolidays = hd.getHolidays(String(parseInt(yearStart) + i));
    hol = hol.concat(
      arrHolidays.map((arrHolidays) =>
        moment(arrHolidays.date).format('MM-DD-YYYY'),
      ),
    );
    i++;
  }
  momentb.updateLocale(country, {
    holidays: hol,
    holidayFormat: 'MM-DD-YYYY',
  });
  //setting the local holidays
  if (start > new Date().setUTCHours(0, 0, 0, 0)) {
    return momentb(
      moment(new Date().setUTCHours(0, 0, 0, 0))
        .add(1, 'days')
        .format('MM-DD-YYYY'),
      'MM-DD-YYYY',
    ).businessDiff(
      momentb(moment(start).add(1, 'days').format('MM-DD-YYYY'), 'MM-DD-YYYY'),
      true,
    );
  } else {
    return momentb(
      moment(end).add(1, 'days').format('MM-DD-YYYY'),
      'MM-DD-YYYY',
    ).businessDiff(
      momentb(moment(start).format('MM-DD-YYYY'), 'MM-DD-YYYY'),
      true,
    );
  }
};

const getICTime = ({ sales }, country) => {
  let time = null;

  // Get last IC sale
  const lastSaleIntercontract = sales
    .filter((e) => e.activityType === INTERCONTRAT)
    .reduce(
      (last, current) =>
        !last || moment(current.startDate).isAfter(moment(last.startDate))
          ? current
          : last,
      null,
    );

  if (lastSaleIntercontract) {
    const start = Date.parse(lastSaleIntercontract.startDate);
    const dateNow = Date.now();
    const end = lastSaleIntercontract.endDate
      ? Date.parse(lastSaleIntercontract.endDate)
      : dateNow;
    if ((dateNow >= start && dateNow <= end) || start > dateNow) {
      time = {
        start,
        countBusinessDays: countBuissnessDays(start, dateNow, country),
      };
    }
  }

  return time;
};

const renderFileIcon = (fileExtension) => {
  switch (fileExtension) {
    case 'pdf':
      return <PdfIcon />;
    case 'xlsx':
    case 'xls':
      return <ExcelIcon />;
    case 'docx':
      return <WordIcon />;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return <ImageIcon />;
    default:
      return null;
  }
};

const checkAccess = (
  {
    role,
    subRole,
    userCountry,
    userId,
    consultantId,
    consultant,
    userBU,
    userScopeGeo = [],
    authorizedPersonnalAccess = false,
  },
  accessType,
) => {
  switch (accessType) {
    case 'default':
      return (
        role === ROLE_ADMIN ||
        role === ROLE_COMMERCIAL ||
        (role === ROLE_COO && userCountry === consultant?.country) ||
        (role === ROLE_HRBP && userId === consultant?.hrbp.id) ||
        (role === ROLE_HRBP && userScopeGeo.includes(consultant?.country)) ||
        (role === ROLE_CONSULTANT &&
          subRole === ROLE_CONSULTANT_REF &&
          userId === consultant?.consultantRef.id) ||
        (role === ROLE_CONSULTANT &&
          subRole === ROLE_PRACTICE_LEADER &&
          userCountry === consultant?.country &&
          userBU.includes(consultant?.businessUnit[0])) ||
        (authorizedPersonnalAccess && consultantId === consultant._id)
      );
    case 'monitoring':
      return (
        role === ROLE_ADMIN ||
        (role === ROLE_COMMERCIAL && userCountry === consultant?.country) ||
        (role === ROLE_COO && userCountry === consultant?.country) ||
        (role === ROLE_HRBP && userId === consultant?.hrbp.id) ||
        (role === ROLE_HRBP && userScopeGeo.includes(consultant?.country)) ||
        (role === ROLE_CONSULTANT &&
          subRole === ROLE_CONSULTANT_REF &&
          userCountry === consultant?.country) ||
        userId === consultant?.consultantRef.id ||
        (role === ROLE_CONSULTANT &&
          subRole === ROLE_PRACTICE_LEADER &&
          userCountry === consultant?.country &&
          userBU.includes(consultant?.businessUnit[0])) ||
        (authorizedPersonnalAccess && consultantId === consultant._id)
      );
    case 'edit':
      return (
        role === ROLE_ADMIN ||
        (role === ROLE_COMMERCIAL && userCountry === consultant?.country) ||
        (role === ROLE_COO && userCountry === consultant?.country) ||
        (role === ROLE_HRBP && userId === consultant?.hrbp.id) ||
        (role === ROLE_HRBP && userScopeGeo.includes(consultant?.country)) ||
        (role === ROLE_CONSULTANT &&
          subRole === ROLE_CONSULTANT_REF &&
          userCountry === consultant?.country) ||
        (role === ROLE_CONSULTANT &&
          subRole === ROLE_PRACTICE_LEADER &&
          userCountry === consultant?.country &&
          userBU.includes(consultant?.businessUnit[0])) ||
        (authorizedPersonnalAccess && consultantId === consultant._id)
      );
    case 'activity':
      return (
        role === ROLE_ADMIN ||
        (role === ROLE_COMMERCIAL && userCountry === consultant?.country) ||
        (role === ROLE_COO && userCountry === consultant?.country) ||
        (role === ROLE_HRBP && userId === consultant?.hrbp.id) ||
        (role === ROLE_HRBP && userScopeGeo.includes(consultant?.country)) ||
        (role === ROLE_CONSULTANT &&
          subRole === ROLE_CONSULTANT_REF &&
          userId === consultant?.consultantRef.id) ||
        (role === ROLE_CONSULTANT &&
          subRole === ROLE_PRACTICE_LEADER &&
          userCountry === consultant?.country &&
          userBU.includes(consultant?.businessUnit[0])) ||
        (authorizedPersonnalAccess && consultantId === consultant._id)
      );
    case 'resume':
      return (
        role === ROLE_ADMIN ||
        role === ROLE_COMMERCIAL ||
        role === ROLE_COO ||
        role === ROLE_HRBP ||
        (role === ROLE_CONSULTANT &&
          subRole === ROLE_CONSULTANT_REF &&
          userId === consultant?.consultantRef.id) ||
        (role === ROLE_CONSULTANT &&
          subRole === ROLE_PRACTICE_LEADER &&
          userCountry === consultant?.country &&
          consultant?.businessUnit?.some((bu) => userBU.includes(bu))) ||
        (authorizedPersonnalAccess && consultantId === consultant._id)
      );
    default:
      return false;
  }
};

export {
  handleStatusColor,
  useModal,
  getNameFromToken,
  getColor,
  handleStatusLabel,
  handleRoleLabel,
  countBuissnessDays,
  getICTime,
  renderFileIcon,
  useComentModal,
  checkAccess,
};
