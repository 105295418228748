import {
  CLEAR_STEP,
  initForm,
  //
  POST_CONSULTANT,
  POST_CONSULTANT_FAILED,
  POST_CONSULTANT_SUCCES,
  UPDATE_CONSULTANT,
  UPDATE_CONSULTANT_FAILED,
  UPDATE_CONSULTANT_SUCCES,
  UPDATE_ARCHIVE,
  UPDATE_ARCHIVE_SUCCES,
  UPDATE_ARCHIVE_FAILED,

  // FOLLOW
  UPDATE_FOLLOW,
  UPDATE_FOLLOW_SUCCESS,
  UPDATE_FOLLOW_FAILED,
  //
  SAVE_STEP,

  //all jobs
  GET_ALL_JOBS,
  GET_ALL_JOBS_FAILED,
  GET_ALL_JOBS_SUCCES,
} from 'containers/CreateProfile/constants';

const initialState = {
  form: initForm(),
  certificationsFiles: [],
  diplomaFiles: [],
  loadingConsultant: false,
  status: null,
  createdConsultantId: null,
  loadingJobs: false,
  allJobs: [],
  archive: null,
  enableModal: false,
};

const createConsultantReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_STEP:
      return {
        ...state,
        form: { ...state.form, ...action.values },
        certificationsFiles:
          action.certificationsFiles || state.certificationsFiles,
        diplomaFiles: action.diplomaFiles || state.diplomaFiles,
      };
    case CLEAR_STEP:
      return { ...state, form: initForm(), status: null };
    case POST_CONSULTANT:
      return { ...state, loadingConsultant: true };
    case POST_CONSULTANT_SUCCES:
      return {
        ...state,
        loadingConsultant: false,
        status: action.status,
        createdConsultantId: action.created_consultant_id,
        enableModal: action.enableModal,
      };
    case POST_CONSULTANT_FAILED:
      return { ...state, loadingConsultant: false, status: action.status };

    case UPDATE_CONSULTANT:
      return { ...state, loadingConsultant: true };
    case UPDATE_CONSULTANT_SUCCES:
      return {
        ...state,
        loadingConsultant: false,
        status: action.status,
        createdConsultantId: action.updated_consultant_id,
        form: { ...state.form, ...action.values },
        certificationsFiles: [],
        diplomaFiles: [],
        enableModal: action.enableModal,
      };
    case UPDATE_CONSULTANT_FAILED:
      return {
        ...state,
        loadingConsultant: false,
        status: action.status,
      };

    case GET_ALL_JOBS:
      return { ...state, loadingJobs: true };
    case GET_ALL_JOBS_SUCCES:
      return {
        ...state,
        loadingJobs: false,
        allJobs: action.allJobs,
      };
    case GET_ALL_JOBS_FAILED:
      return {
        ...state,
        loadingJobs: false,
      };
    case UPDATE_ARCHIVE:
      return {
        ...state,
      };
    case UPDATE_ARCHIVE_SUCCES:
      return {
        ...state,
        archive: action.archive,
      };
    case UPDATE_ARCHIVE_FAILED:
      return {
        ...state,
      };

    case UPDATE_FOLLOW:
      return {
        ...state,
        isLoadingConsultant: true,
      };
    case UPDATE_FOLLOW_SUCCESS:
      return {
        ...state,
        isLoadingConsultant: false,
        consultantId: action.consultant,
        businessId: action.businessId,
      };
    case UPDATE_FOLLOW_FAILED:
      return {
        ...state,
        isLoadingConsultant: true,
      };
    default:
      return state;
  }
};

export default createConsultantReducer;
