import React from 'react';

const UploadIcon = (props) => (
  <svg
    width="44"
    height="56"
    viewBox="0 0 54 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.2059 57.5034V14.8844C53.2376 14.415 53.0619 13.9553 52.7249 13.626L39.5485 0.483161C39.2192 0.147965 38.7596 -0.0272245 38.2901 0.0034464H8.73529C4.18707 0.0034464 0.5 3.68111 0.5 8.21773V57.5034C0.5 62.0401 4.18707 65.7177 8.73529 65.7177H44.9706C49.5188 65.7177 53.2059 62.0401 53.2059 57.5034ZM8.73529 3.28916H36.7353V14.7892C36.7353 15.6965 37.4727 16.432 38.3824 16.432H49.9118V57.5034C49.9118 60.2254 47.6995 62.432 44.9706 62.432H8.73529C6.00636 62.432 3.79412 60.2254 3.79412 57.5034V8.21773C3.79412 5.49576 6.00636 3.28916 8.73529 3.28916ZM40.0294 5.61216V13.1463H47.5828L40.0294 5.61216ZM26.8529 50.9287V28.6122V28.6089L33.9221 35.6633C34.5661 36.3057 35.6103 36.3057 36.2544 35.6633C36.8984 35.0209 36.8984 33.9794 36.2544 33.337L26.372 23.4799C26.063 23.1709 25.6434 22.9972 25.2059 22.9972C24.7683 22.9972 24.3488 23.1709 24.0398 23.4799L14.1574 33.337C13.5134 33.9794 13.5134 35.0209 14.1574 35.6633C14.8014 36.3057 15.8456 36.3057 16.4896 35.6633L23.5588 28.6089V50.9287C23.5588 51.8361 24.2962 52.5716 25.2059 52.5716C26.1155 52.5716 26.8529 51.8361 26.8529 50.9287Z"
      fill="#808794"
    />
  </svg>
);
export default UploadIcon;
