import { createTheme } from '@material-ui/core/styles';

const AppTheme = createTheme({
  typography: {
    fontFamily: 'Rubik',
  },
  palette: {
    type: 'light',
    primary: {
      main: '#0176BA',
      dark: '#0176BA',
    },
    secondary: {
      main: '#DD3938',
    },
  },
});

export default AppTheme;
