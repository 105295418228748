import moment from 'moment';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILED,
  GET_NOTIFICATIONS_SUCCES,
  SEND_NOTIFICATION,
  SEND_NOTIFICATION_FAILED,
  SEND_NOTIFICATION_SUCCES,
  UPDATE_LAST_REMINDER,
  UPDATE_LAST_REMINDER_FAILED,
  UPDATE_LAST_REMINDER_SUCCES,
  UPDATE_READ,
  UPDATE_READ_SUCCES,
} from 'shared';

const initialState = {
  notifications: [],
  isNotificationsLoading: false,
  lastReminderDate: null,
  isUpdateReminderDateLoading: false,
};

const sortNotifs = (notifications) => {
  return notifications?.sort((notifA, notifB) => {
    return moment(notifB.created_at) - moment(notifA.created_at);
  });
};

export function createNotificationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        isNotificationsLoading: true,
      };
    case GET_NOTIFICATIONS_SUCCES:
      return {
        ...state,
        isNotificationsLoading: false,
        notifications: sortNotifs(action.notifications),
      };
    case GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        isNotificationsLoading: true,
        notifications: null,
      };
    case UPDATE_READ:
      return {
        ...state,
        isNotificationsLoading: true,
      };
    case UPDATE_READ_SUCCES:
      return {
        ...state,
        isNotificationsLoading: false,
        notifications: sortNotifs(action.notifications),
      };
    case GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        isNotificationsLoading: true,
      };
    case UPDATE_LAST_REMINDER:
      return {
        ...state,
        isUpdateReminderDateLoading: true,
      };
    case UPDATE_LAST_REMINDER_SUCCES:
      return {
        ...state,
        lastReminderDate: action.lastReminderDate,
        isUpdateReminderDateLoading: false,
      };
    case UPDATE_LAST_REMINDER_FAILED:
      return {
        ...state,
        isUpdateReminderDateLoading: false,
      };
    case SEND_NOTIFICATION:
      return {
        ...state,
        isSendingNotification: true,
      };
    case SEND_NOTIFICATION_SUCCES:
      return {
        ...state,
        isSendingNotification: false,
      };
    case SEND_NOTIFICATION_FAILED:
      return {
        ...state,
        isSendingNotification: false,
      };
    default:
      return state;
  }
}
