import {
  GET_ALL_CONSULTANTS,
  GET_ALL_CONSULTANTS_FAILED,
  GET_ALL_CONSULTANTS_SUCCES,
  GET_CONSULTANT_BYID,
  GET_CONSULTANT_BYID_SUCCES,
  GET_CONSULTANT_BYID_FAILED,
  GET_CONSULTANT_EMAIL,
  GET_CONSULTANT_EMAIL_SUCCES,
  GET_CONSULTANT_EMAIL_FAILED,
  POST_ACTIVITY_FAILED,
  POST_ACTIVITY_SUCCESS,
  POST_ACTIVITY,
  POST_CLIENT,
  POST_CLIENT_SUCCES,
  POST_CLIENT_FAILED,
  GET_CLIENTS,
  GET_CLIENTS_SUCCES,
  GET_CLIENTS_FAILED,
  POST_SALARY,
  POST_SALARY_SUCCES,
  POST_SALARY_FAILED,
  POST_FILTERS,
  POST_FILTERS_SUCCES,
  POST_FILTERS_FAILED,
  GET_ALL_CERTIFICATIONS,
  GET_ALL_CERTIFICATIONS_SUCCESS,
  GET_ALL_CERTIFICATIONS_FAILED,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_SUCCES,
  UPDATE_ACTIVITY_FAILED,
  UPDATE_ACTIVITY_STATUS_SUCCES,
  UPDATE_ACTIVITY_STATUS_FAILED,
  UPDATE_ACTIVITY_STATUS,
  POST_ACTIVITY_UPDATE_ACTIVITY_STATUS,
  UPDATE_COMMENT,
  UPDATE_COMMENT_SUCCES,
  UPDATE_COMMENT_FAILED,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAIL,
  GET_COMPETENCES_SUCCESS,
  GET_COMPETENCES_FAIL,
  GET_COMPETENCES,
  // dowload cv
  GET_DOWNLOAD_CV,
  GET_DOWNLOAD_CV_SUCCESS,
  GET_DOWNLOAD_CV_FAILED,
  UPDATE_CONSULTANT_STATUS_FAILED,
  UPDATE_CONSULTANT_STATUS_SUCCES,
  UPDATE_CONSULTANT_STATUS,
  //delete activity
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCES,
  DELETE_ACTIVITY_FAILED,
  // get all users
  GET_USERS,
  GET_USERS_SUCCES,
  GET_USERS_FAILED,
  //get exchange rates
  GET_EXCHANGE_RATES,
  GET_EXCHANGE_RATES_SUCCES,
  GET_EXCHANGE_RATES_FAILED,
  // synchrnize wth progessi
  SYNCHRONIZE_CONSULTANT_WITH_PROGESSI,
  SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_SUCCES,
  SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_FAILED,

  // get consultant by id from progessi
  GET_CONSULTANT_FROM_PROGESSI,
  GET_CONSULTANT_FROM_PROGESSI_SUCCES,
  GET_CONSULTANT_FROM_PROGESSI_FAILED,
} from 'containers/DashboardPage/constants';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import Axios from 'utils/Axios';
import { getICTime } from 'utils/utils';
import { toast } from 'react-toastify';

const { REACT_APP_BASEURL } = process.env;

export const getConsultantAPI = () => {
  return Axios.get(`${REACT_APP_BASEURL}/consultant/all`);
};

export function* getConsultantsSaga({ values }) {
  try {
    const res = yield call(getConsultantAPI, values);

    if (res.status === 200) {
      // Retrieve IC time by consultants
      const allConsultants = res.data.consultants.map((c) => ({
        ...c,
        timeIC: getICTime(c, c.country),
      }));
      yield put({
        type: GET_ALL_CONSULTANTS_SUCCES,
        allConsultants,
      });
    } else {
      yield put({
        type: GET_ALL_CONSULTANTS_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_ALL_CONSULTANTS_FAILED,
    });
  }
}

export function* watchGetConsultant() {
  yield takeLatest(GET_ALL_CONSULTANTS, getConsultantsSaga);
}

export const getConsultantByIdAPI = (id) => {
  return Axios.get(`${REACT_APP_BASEURL}/consultant/by/${id}`);
};

export function* getConsultantByIdSaga({ id }) {
  try {
    const res = yield call(getConsultantByIdAPI, id);
    if (res.status === 200) {
      yield put({
        type: GET_CONSULTANT_BYID_SUCCES,
        consultant: res.data.result,
        currentFromProgessi: res.data.currentFromProgessi,
      });
    } else {
      yield put({
        type: GET_CONSULTANT_BYID_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_CONSULTANT_BYID_FAILED,
    });
  }
}

export function* watchGetConsultantById() {
  yield takeLatest(GET_CONSULTANT_BYID, getConsultantByIdSaga);
}

// get from progessi by id
export const getConsultantFromProgessiByIdAPI = (id) => {
  return Axios.get(`${REACT_APP_BASEURL}/consultant/fromprgessi/by/${id}`);
};

export function* getConsultantFromProgessiByIdSaga({ id }) {
  try {
    const res = yield call(getConsultantFromProgessiByIdAPI, id);
    if (res.status === 200) {
      yield put({
        type: GET_CONSULTANT_FROM_PROGESSI_SUCCES,
        currentFromProgessi: res.data.result,
      });
    } else {
      yield put({
        type: GET_CONSULTANT_FROM_PROGESSI_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_CONSULTANT_FROM_PROGESSI_FAILED,
    });
  }
}

export function* watchGetConsultantFromProgessiById() {
  yield takeLatest(
    GET_CONSULTANT_FROM_PROGESSI,
    getConsultantFromProgessiByIdSaga,
  );
}

// get consultant by email
export const getConsultantByEmail = (email) => {
  return Axios.post(`${REACT_APP_BASEURL}/consultant/bymail`, { email });
};

export function* getConsultantByEmailSaga() {
  try {
    const email = localStorage.getItem('email');
    const res = yield call(getConsultantByEmail, email);
    if (res.status === 200) {
      yield put({
        type: GET_CONSULTANT_EMAIL_SUCCES,
        consultantId: res.data.id,
      });
    } else {
      yield put({
        type: GET_CONSULTANT_EMAIL_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_CONSULTANT_EMAIL_FAILED,
    });
  }
}

export function* watchGetConsultantByEmail() {
  yield takeLatest(GET_CONSULTANT_EMAIL, getConsultantByEmailSaga);
}

export const postActivityAPI = (payload) => {
  return Axios.post(
    `${REACT_APP_BASEURL}/consultant/activities/${payload.id}`,
    payload.values,
  );
};

export function* postActivitySaga(values) {
  try {
    // const values = yield select((state) => state.get('dashboard').activities);
    const res = yield call(postActivityAPI, values);

    if (res.status === 200) {
      yield put({
        type: POST_ACTIVITY_SUCCESS,
        status: res.data.status,
      });
      toast.success('Activity added sucessfully');
    } else {
      yield put({
        type: POST_ACTIVITY_FAILED,
        status: 'failed',
      });
      toast.error('Error adding Activity');
    }
  } catch (err) {
    yield put({
      type: POST_ACTIVITY_FAILED,
      status: 'failed',
    });
  }
}

export function* watchPostActivity() {
  yield takeLatest(POST_ACTIVITY, postActivitySaga);
}

export const postClientAPI = (payload) => {
  return Axios.post(`${REACT_APP_BASEURL}/client/add`, payload.values);
};

export function* postClientSaga(values) {
  try {
    const res = yield call(postClientAPI, values);

    if (res.status === 201) {
      yield put({
        type: POST_CLIENT_SUCCES,
        status: res.data.status,
      });
    } else {
      yield put({
        type: POST_CLIENT_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: POST_ACTIVITY_FAILED,
      status: 'failed',
    });
  }
}

export function* watchPostClient() {
  yield takeLatest(POST_CLIENT, postClientSaga);
}

export const getClientsAPI = () => {
  return Axios.get(`${REACT_APP_BASEURL}/client/all`);
};
export function* getClientsSaga() {
  try {
    const res = yield call(getClientsAPI);
    if (res.status === 200) {
      yield put({
        type: GET_CLIENTS_SUCCES,
        clients: res.data.allClients,
      });
    } else {
      yield put({
        type: GET_CLIENTS_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_CLIENTS_FAILED,
    });
  }
}

export function* watchGetClients() {
  yield takeLatest(GET_CLIENTS, getClientsSaga);
}

// certification saga

export const getCertificationsApi = () => {
  return Axios.get(`${REACT_APP_BASEURL}/certification/all`);
};

export function* getCertificationsSaga() {
  try {
    const res = yield call(getCertificationsApi);
    if (res.status === 200) {
      yield put({
        type: GET_ALL_CERTIFICATIONS_SUCCESS,
        allCertifications: res.data.allCertifications,
      });
    } else {
      yield put({
        type: GET_ALL_CERTIFICATIONS_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_ALL_CERTIFICATIONS_FAILED,
    });
  }
}

export function* watchGetCertification() {
  yield takeLatest(GET_ALL_CERTIFICATIONS, getCertificationsSaga);
}

// salary saga
export const postSalaryAPI = (payload) => {
  return Axios.post(
    `${REACT_APP_BASEURL}/consultant/salary/${payload.id}`,
    payload.values,
  );
};

export function* postSalarySaga(values) {
  try {
    const res = yield call(postSalaryAPI, values);

    if (res.status === 200) {
      yield put({
        type: POST_SALARY_SUCCES,
        salaryStatus: res.data.status,
      });
    } else {
      yield put({
        type: POST_SALARY_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: POST_SALARY_FAILED,
      status: 'failed',
    });
  }
}

export function* watchPostSalary() {
  yield takeLatest(POST_SALARY, postSalarySaga);
}

// filters
export const postFilterAPI = (payload) => {
  return Axios.post(`${REACT_APP_BASEURL}/consultant/filters`, payload.query);
};

export function* postFilterSaga(query) {
  try {
    const res = yield call(postFilterAPI, query);

    if (res.status === 200) {
      const allConsultants = res.data.consultants.map((c) => ({
        ...c,
        timeIC: getICTime(c, c.country),
      }));
      yield put({
        type: POST_FILTERS_SUCCES,
        allConsultants,
      });
    } else {
      yield put({
        type: POST_FILTERS_FAILED,
        status: 'filter failed',
      });
    }
  } catch (err) {
    yield put({
      type: POST_FILTERS_FAILED,
      status: 'filter failed',
    });
  }
}

export function* watchPostFilter() {
  yield takeLatest(POST_FILTERS, postFilterSaga);
}

export const updateActivityAPI = (payload) => {
  return Axios.put(
    `${REACT_APP_BASEURL}/consultant/activity/updatesales/${payload.id}/${payload._id}`,
    payload.data,
  );
};

export function* updateActivitySaga(payload) {
  try {
    const res = yield call(updateActivityAPI, payload);

    if (res.status === 200) {
      yield put({
        type: UPDATE_ACTIVITY_SUCCES,
        status: res.data.status,
        message: res.data.message,
        updatedsalesDates: res.data.updated,
      });
    } else {
      yield put({
        type: UPDATE_ACTIVITY_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_ACTIVITY_FAILED,
      status: 'failed',
    });
  }
}

export function* watchUpdateActivity() {
  yield takeLatest(UPDATE_ACTIVITY, updateActivitySaga);
}

export const updateActivityStatusAPI = (payload) => {
  return Axios.put(
    `${REACT_APP_BASEURL}/consultant/activity/sales/${payload.id}/${payload._id}`,
    payload.data,
  );
};

export function* updateActivityStatusSaga(payload) {
  try {
    const res = yield call(updateActivityStatusAPI, payload);

    if (res.status === 200) {
      yield put({
        type: UPDATE_ACTIVITY_STATUS_SUCCES,
        status: res.data.status,
        message: res.data.message,
        updatedsalesStatus: res.data.updated,
      });
    } else {
      yield put({
        type: UPDATE_ACTIVITY_STATUS_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_ACTIVITY_STATUS_FAILED,
      status: 'failed',
    });
  }
}

export function* watchUpdateActivityStatus() {
  yield takeLatest(UPDATE_ACTIVITY_STATUS, updateActivityStatusSaga);
}

export function* postActivityUpdateActivityStatusSaga(payload) {
  try {
    const res1 = yield call(updateActivityStatusAPI, payload);
    if (res1.status === 200) {
      try {
        const res2 = yield call(postActivityAPI, payload);
        if (res2.status === 200) {
          yield put({
            type: POST_ACTIVITY_SUCCESS,
            status: res2.data.status,
          });
        } else {
          yield put({
            type: POST_ACTIVITY_FAILED,
            status: 'failed',
          });
        }
      } catch (err) {
        yield put({
          type: POST_ACTIVITY_FAILED,
          status: 'failed',
        });
      }

      yield put({
        type: UPDATE_ACTIVITY_STATUS_SUCCES,
        status: res1.data.status,
        message: res1.data.message,
        updatedsalesStatus: res1.data.updated,
      });
      toast.success('Activity added sucessfully');
    } else {
      yield put({
        type: UPDATE_ACTIVITY_STATUS_FAILED,
        status: 'failed',
      });
      toast.error('Error adding Activity');
    }
  } catch (err) {
    yield put({
      type: UPDATE_ACTIVITY_STATUS_FAILED,
      status: 'failed',
    });
    toast.error('Error adding Activity');
  }
}

export function* watchPostActivityUpdateActivityStatus() {
  yield takeLatest(
    POST_ACTIVITY_UPDATE_ACTIVITY_STATUS,
    postActivityUpdateActivityStatusSaga,
  );
}

export const updateCommentAPI = (payload) => {
  return Axios.put(
    `${REACT_APP_BASEURL}/consultant/activity/updatecomment/${payload.id}/${payload._id}`,
    payload.data,
  );
};

export function* updateCommentSaga(payload) {
  try {
    const res = yield call(updateCommentAPI, payload);

    if (res.status === 200) {
      yield put({
        type: UPDATE_COMMENT_SUCCES,
        updatedsalesStatus: res.data.updated,
      });
    } else {
      yield put({
        type: UPDATE_COMMENT_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_COMMENT_FAILED,
      status: 'failed',
    });
  }
}

export function* watchUpdateComment() {
  yield takeLatest(UPDATE_COMMENT, updateCommentSaga);
}

export const postMailApi = (payload) => {
  return Axios.put(`${REACT_APP_BASEURL}/consultant/sendMail/${payload.id}`);
};

export function* postMailSaga(payload) {
  try {
    const res = yield call(postMailApi, payload);

    if (res.status === 201) {
      yield put({
        type: SEND_EMAIL_SUCCESS,
        status: res.data.status,
      });
    } else {
      yield put({
        type: SEND_EMAIL_FAIL,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: SEND_EMAIL_FAIL,
      status: 'failed',
    });
  }
}

export function* watchPostEmail() {
  yield takeLatest(SEND_EMAIL, postMailSaga);
}

// competences saga

export const getComptencesApi = () => {
  return Axios.get(`${REACT_APP_BASEURL}/competence/allCompetences`);
};
export function* getComptencesSaga() {
  try {
    const res = yield call(getComptencesApi);
    if (res.status === 200) {
      yield put({
        type: GET_COMPETENCES_SUCCESS,
        allCompetences: res.data.allCompetences,
      });
    } else {
      yield put({
        type: GET_COMPETENCES_FAIL,
      });
    }
  } catch (err) {
    yield put({
      type: GET_COMPETENCES_FAIL,
    });
  }
}

export function* watchGetCompetences() {
  yield takeLatest(GET_COMPETENCES, getComptencesSaga);
}

export const downloadCvApi = (payload) => {
  const { downloadParams, id } = payload;
  if (!downloadParams.file) {
    downloadParams.file = 'docx';
  }
  return Axios.get(
    `${REACT_APP_BASEURL}/consultant/cv/${id}?filetype=${downloadParams.file}&city=${downloadParams.city}&lang=${downloadParams.language}`,
  );
};

export function* downloadCvSaga(payload) {
  try {
    const res = yield call(downloadCvApi, payload);
    if (res.status === 200) {
      yield put({
        type: GET_DOWNLOAD_CV_SUCCESS,
        cv: res.data.cv,
      });
    } else {
      yield put({
        type: GET_DOWNLOAD_CV_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_DOWNLOAD_CV_FAILED,
    });
  }
}

export function* watchDownloadCvSaga() {
  yield takeLatest(GET_DOWNLOAD_CV, downloadCvSaga);
}

// update consultant status
export const updateConsultantStatusAPI = (payload) => {
  return Axios.put(
    `${REACT_APP_BASEURL}/consultant/updateConsultantStatus/${payload.id}`,
    payload.data,
  );
};

export function* updateConsultantStatusSaga(payload) {
  try {
    const res = yield call(updateConsultantStatusAPI, payload);
    if (res.status === 200) {
      yield put({
        type: UPDATE_CONSULTANT_STATUS_SUCCES,
        updatedconsultantStatus: res.data.updated,
      });
    } else {
      yield put({
        type: UPDATE_CONSULTANT_STATUS_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_CONSULTANT_STATUS_FAILED,
      status: 'failed',
    });
  }
}

export function* watchUpdateConsultantStatus() {
  yield takeLatest(UPDATE_CONSULTANT_STATUS, updateConsultantStatusSaga);
}

// delete activity
export const deleteActivityAPI = (payload) => {
  return Axios.delete(
    `${REACT_APP_BASEURL}/consultant/activity/delete/${payload.id}/${payload._id}`,
  );
};

export function* deleteActivitySaga(payload) {
  try {
    const res = yield call(deleteActivityAPI, payload);

    if (res.status === 200) {
      yield put({
        type: DELETE_ACTIVITY_SUCCES,
        deleteStatus: res.data.status,
        message: res.data.message,
      });
    } else {
      yield put({
        type: DELETE_ACTIVITY_FAILED,
        deleteStatus: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: DELETE_ACTIVITY_FAILED,
      deleteStatus: 'failed',
    });
  }
}

export function* watchDeleteActivity() {
  yield takeLatest(DELETE_ACTIVITY, deleteActivitySaga);
}

// get users
export const getAllUsersAPI = () => {
  return Axios.get(`${REACT_APP_BASEURL}/user/all`);
};

export function* getAllUsersSaga() {
  try {
    const res = yield call(getAllUsersAPI);

    if (res.status === 200) {
      yield put({
        type: GET_USERS_SUCCES,
        users: res.data.allUsers,
        message: res.data.message,
      });
    } else {
      yield put({
        type: GET_USERS_FAILED,
        message: res.data.message,
      });
    }
  } catch (err) {
    yield put({
      type: GET_USERS_FAILED,
      message: 'failed',
    });
  }
}

export function* watchGetAllUsers() {
  yield takeLatest(GET_USERS, getAllUsersSaga);
}

export const getExchangeRatesApi = () => {
  return Axios.get(`${REACT_APP_BASEURL}/exchangerate/`);
};
export function* getExchangeRatesSaga() {
  try {
    const res = yield call(getExchangeRatesApi);
    if (res.status === 200) {
      yield put({
        type: GET_EXCHANGE_RATES_SUCCES,
        exchangeRates: res.data.exchangeRates,
      });
    } else {
      yield put({
        type: GET_EXCHANGE_RATES_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_EXCHANGE_RATES_FAILED,
    });
  }
}

export function* watchGetExchangeRates() {
  yield takeLatest(GET_EXCHANGE_RATES, getExchangeRatesSaga);
}

export const synchronizeWithProgessiAPI = (payload) => {
  return Axios.put(
    `${REACT_APP_BASEURL}/consultant/progessi/${payload.idProgessi}/${payload.consultantId}`,
  );
};

export function* synchronizeWithProgessiSaga(payload) {
  try {
    const res = yield call(synchronizeWithProgessiAPI, payload);

    if (res.status === 200) {
      yield put({
        type: SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_SUCCES,
      });
      toast.success('data was synchronized  sucessfully');
    } else {
      yield put({
        type: SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_FAILED,
      });
      toast.error(`Your data does not match with the consultant`);
    }
  } catch (err) {
    toast.error(`Something went wrong`);
    yield put({
      type: SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_FAILED,
    });
  }
}

export function* watchSynchronizeWithProgessiSaga() {
  yield takeLatest(
    SYNCHRONIZE_CONSULTANT_WITH_PROGESSI,
    synchronizeWithProgessiSaga,
  );
}

export default function* rootDelegationSaga() {
  yield all([
    watchGetConsultant(),
    watchGetConsultantById(),
    watchGetConsultantByEmail(),
    watchPostActivity(),
    watchPostClient(),
    watchGetClients(),
    watchPostSalary(),
    watchGetCertification(),
    watchPostFilter(),
    watchUpdateActivity(),
    watchUpdateActivityStatus(),
    watchPostEmail(),
    watchUpdateActivityStatus(),
    watchGetCompetences(),
    watchDownloadCvSaga(),
    watchUpdateConsultantStatus(),
    watchDeleteActivity(),
    watchGetAllUsers(),
    watchPostActivityUpdateActivityStatus(),
    watchUpdateComment(),
    watchGetExchangeRates(),
    watchSynchronizeWithProgessiSaga(),
    watchGetConsultantFromProgessiById(),
  ]);
}
