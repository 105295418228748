import React from 'react';

const CheckCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-5-6h24v24H-5z" />
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M13.71 1.21a1 1 0 0 0-1.42 0L4.84 8.67 1.71 5.53A1.022 1.022 0 1 0 .29 7l3.84 3.84a1 1 0 0 0 1.42 0l8.16-8.16a1 1 0 0 0 0-1.47z"
      />
    </g>
  </svg>
);

export default CheckCircle;
