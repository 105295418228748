import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Card,
  Title,
  Description,
  Header,
  Link,
  MarkWrapper,
  Container,
} from './styles';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Checkbox, Tooltip } from '@material-ui/core';
import { UPDATE_READ } from 'shared';

const NotificationCard = ({ notification }) => {
  const [isExpand, setIsExpand] = useState(false);
  const [isRead, setRead] = useState(notification.read);

  useEffect(() => {
    setRead(notification.read);
  }, [notification]);

  const dispatch = useDispatch();

  const history = useHistory();
  const intl = useIntl();
  const { locale } = useSelector((state) => state.get('intl'));

  moment.locale(locale);

  const date = moment(new Date(notification.created_at));

  const handleMark = () => {
    const values = {
      _id: notification._id,
      read: !isRead,
      email: localStorage.getItem('email'),
      language: locale,
    };
    setRead(!isRead);
    dispatch({ type: UPDATE_READ, values });
  };

  const handleClick = () => {
    setIsExpand(!isExpand);
  };

  const handleLink = () => {
    if (!isRead) {
      handleMark();
    }
    history.push(notification.link);
  };

  const getDuration = () => {
    if (moment().isSame(date, 'days')) {
      const duration = moment.duration(moment().diff(date));

      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      if (hours)
        return hours > 1
          ? intl.formatMessage({ id: 'hours_ago' }, { hours: hours })
          : intl.formatMessage({ id: 'hour_ago' }, { hour: hours });
      if (minutes)
        return minutes > 1
          ? intl.formatMessage({ id: 'minutes_ago' }, { minutes: minutes })
          : intl.formatMessage({ id: 'minute_ago' }, { minute: minutes });
      if (seconds)
        return seconds > 1
          ? intl.formatMessage({ id: 'seconds_ago' }, { seconds: seconds })
          : intl.formatMessage({ id: 'second_ago' }, { second: seconds });
    } else {
      return intl.formatMessage(
        { id: 'receipt_date' },
        { date: date.format('DD MMM YYYY'), hour: date.format('HH:mm') },
      );
    }
  };

  return (
    <Container>
      <Card read={isRead} isExpand={isExpand}>
        <div style={{ height: '100%' }} onClick={handleClick}>
          <Header>
            <Title>{notification.title}</Title>
            {getDuration()}
          </Header>
          <Description isExpand={isExpand}>{notification.body}</Description>
          {isExpand && (
            <>
              <Link onClick={handleLink}> {notification.link_description}</Link>
            </>
          )}
        </div>
      </Card>
      <MarkWrapper>
        <Tooltip
          title={
            isRead
              ? intl.formatMessage({ id: 'mark_as_unread' })
              : intl.formatMessage({ id: 'mark_as_read' })
          }
        >
          <Checkbox
            style={{
              fontSize: '16px',
              width: '16px',
              height: '16px',
              top: '5px',
              left: '-25px',
              backgroundColor: '#fff',
            }}
            checked={!isRead}
            onClick={handleMark}
            icon={<CheckCircleOutlineIcon />}
            checkedIcon={<CheckCircleIcon color="primary" />}
          />
        </Tooltip>
      </MarkWrapper>
    </Container>
  );
};

export default NotificationCard;
