import * as React from 'react';

const ElQuaIcon = (props) => {
  return (
    <svg width={22} height={18} viewBox="0 0 22 18">
      <g {...props} fillRule="nonzero">
        <path d="M20.066 0H1.934C.867 0 0 .882 0 1.966v9.961c0 1.084.867 1.966 1.934 1.966h.859v2.796c0 .723.578 1.311 1.289 1.311H8.98c.711 0 1.29-.588 1.29-1.31v-2.797h9.796c1.067 0 1.934-.882 1.934-1.966V1.966C22 .882 21.133 0 20.066 0zM8.98 16.69H4.082v-3.802c0-1.396.916-2.84 2.45-2.84 1.532 0 2.448 1.444 2.448 2.84v3.801zM4.77 6.946c0-.988.79-1.792 1.761-1.792.972 0 1.762.804 1.762 1.792 0 .987-.79 1.79-1.762 1.79-.971 0-1.761-.803-1.761-1.79zm15.94 4.98a.65.65 0 01-.644.656h-9.808c-.063-.925-.41-1.828-.972-2.517a3.736 3.736 0 00-.804-.736 3.117 3.117 0 001.1-2.383c0-1.71-1.369-3.102-3.05-3.102-1.683 0-3.052 1.391-3.052 3.102 0 .956.429 1.813 1.1 2.383a3.735 3.735 0 00-.803.736c-.563.689-.91 1.592-.973 2.517h-.87a.65.65 0 01-.645-.656V1.966a.65.65 0 01.645-.655h18.132a.65.65 0 01.645.655v9.961z" />
        <g transform="translate(12 5)">
          <path d="M6.318 0H2.682C2.305 0 2 .224 2 .5s.305.5.682.5h3.636C6.695 1 7 .776 7 .5S6.695 0 6.318 0zM6.318 2H2.682C2.305 2 2 2.224 2 2.5s.305.5.682.5h3.636C6.695 3 7 2.776 7 2.5S6.695 2 6.318 2zM6.318 4H2.682C2.305 4 2 4.224 2 4.5s.305.5.682.5h3.636C6.695 5 7 4.776 7 4.5S6.695 4 6.318 4z" />
          <circle cx={0.5} cy={4.5} r={1} />
          <circle cx={0.5} cy={2.5} r={1} />
          <circle cx={0.5} cy={0.5} r={1} />
        </g>
      </g>
    </svg>
  );
};

export default ElQuaIcon;
