import React from "react";

const PresentationIcon = props => (
  <svg style={{ marginRight: 8 }} width={16} height={16} {...props}>
    <path
      fill="#0054DF"
      d="M4.695 7.438L1.45 9.108a1.2 1.2 0 00-.65 1.067v.624a1.2 1.2 0 001.2 1.2.4.4 0 110 .8 2 2 0 01-2-2v-.624a2 2 0 011.085-1.779L3.77 7.014a4 4 0 115.94-5.26.4.4 0 11-.662.449 3.2 3.2 0 10-4.312 4.53.4.4 0 01-.042.704v.001zm3.274 3.414l-3.265 1.484A1.2 1.2 0 004 13.43V14a1.2 1.2 0 001.2 1.2H14a1.2 1.2 0 001.2-1.2v-.57a1.2 1.2 0 00-.704-1.093l-3.265-1.484A3.97 3.97 0 019.6 11.2a3.986 3.986 0 01-1.631-.347zm-.831-.5a4 4 0 114.924 0l2.766 1.256A2 2 0 0116 13.43V14a2 2 0 01-2 2H5.2a2 2 0 01-2-2.001v-.57a2 2 0 011.172-1.82l2.766-1.257zm2.462.047a3.2 3.2 0 100-6.4 3.2 3.2 0 000 6.4z"
    />
  </svg>
);

export default PresentationIcon;
