import { FormControl, InputLabel, Select } from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React  from 'react';
import {useRef } from 'react';

const MaterialSelect = (props) => {
  const [field, meta] = useField(props);
  const { label, data, esqualade } = props;

  const ref = useRef([]);
  const addMissingMaterialClasses = (el) => {
    if (el) {
      if (!el.classList.contains('MuiInputLabel-shrink'))
        el.classList.add('MuiInputLabel-shrink')
        
      if (!el.classList.contains('MuiFormLabel-filled'))
        el.classList.add('MuiFormLabel-filled')
      el.setAttribute("style", "background: #fff ; padding: 0 5px")
    }
  }

  return (
    <div style={{ marginBottom: 56 }}>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel ref={addMissingMaterialClasses} htmlFor="outlined-age-native-simple">{label}</InputLabel>
        <Select
          inputProps={{
            name: field.name,
            id: 'outlined-age-native-simple',
          }}
          native
          {...field}
          {...props}
        >
          <option aria-label="None" value="" />
          {data.map((option) => (
            <option key={option.id} value={esqualade ? option.id : option.name}>
              {option.name}
            </option>
          ))}
        </Select>
      </FormControl>
      {
        /* istanbul ignore next */
        meta.touched && meta.error ? (
          <div className="error" style={{ color: 'red' }}>
            {meta.error}
          </div>
        ) : null
      }
    </div>
  );
};

MaterialSelect.propTypes = {
  label: PropTypes.string.isRequired,
  esqualade: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MaterialSelect;
