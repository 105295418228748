import {
  GET_ALL_CONSULTANTS,
  GET_ALL_CONSULTANTS_SUCCES,
  GET_ALL_CONSULTANTS_FAILED,
  GET_CONSULTANT_BYID,
  GET_CONSULTANT_BYID_FAILED,
  GET_CONSULTANT_BYID_SUCCES,
  GET_CONSULTANT_EMAIL,
  GET_CONSULTANT_EMAIL_SUCCES,
  GET_CONSULTANT_EMAIL_FAILED,
  POST_ACTIVITY,
  POST_ACTIVITY_FAILED,
  POST_ACTIVITY_SUCCESS,
  GET_CLIENTS_FAILED,
  GET_CLIENTS_SUCCES,
  GET_CLIENTS,
  POST_CLIENT_FAILED,
  POST_CLIENT_SUCCES,
  POST_CLIENT,
  POST_SALARY,
  POST_SALARY_SUCCES,
  POST_SALARY_FAILED,
  HIDE_FILTERS_COUNT,
  POST_FILTERS,
  POST_FILTERS_SUCCES,
  POST_FILTERS_FAILED,
  CLEAR_SELECTED_CONSULTANT,
  GET_ALL_CERTIFICATIONS,
  GET_ALL_CERTIFICATIONS_SUCCESS,
  GET_ALL_CERTIFICATIONS_FAILED,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_SUCCES,
  UPDATE_ACTIVITY_FAILED,
  UPDATE_ACTIVITY_STATUS,
  UPDATE_ACTIVITY_STATUS_SUCCES,
  UPDATE_ACTIVITY_STATUS_FAILED,
  POST_ACTIVITY_UPDATE_ACTIVITY_STATUS,
  UPDATE_COMMENT,
  UPDATE_COMMENT_SUCCES,
  UPDATE_COMMENT_FAILED,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAIL,
  GET_COMPETENCES,
  GET_COMPETENCES_SUCCESS,
  GET_COMPETENCES_FAIL,
  // dowload cv
  GET_DOWNLOAD_CV,
  GET_DOWNLOAD_CV_SUCCESS,
  GET_DOWNLOAD_CV_FAILED,
  UPDATE_CONSULTANT_STATUS,
  UPDATE_CONSULTANT_STATUS_SUCCES,
  UPDATE_CONSULTANT_STATUS_FAILED,
  // reset cv
  RESET_CV,
  //deletre activity
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCES,
  DELETE_ACTIVITY_FAILED,
  // sort table
  SORT_CONSULTANTS_BY_IC,
  SORT_CONSULTANTS,
  //get all users
  GET_USERS,
  GET_USERS_SUCCES,
  GET_USERS_FAILED,
  GET_EXCHANGE_RATES,
  GET_EXCHANGE_RATES_SUCCES,
  GET_EXCHANGE_RATES_FAILED,
  SYNCHRONIZE_CONSULTANT_WITH_PROGESSI,
  SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_SUCCES,
  SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_FAILED,

  // get consultant by id from progessi
  GET_CONSULTANT_FROM_PROGESSI,
  GET_CONSULTANT_FROM_PROGESSI_SUCCES,
  GET_CONSULTANT_FROM_PROGESSI_FAILED,
} from 'containers/DashboardPage/constants';

import { initForm } from '../CreateProfile/constants';

const initialState = {
  // consultant
  allConsultants: [],
  sortIcDirection: '',
  consultantLoding: false,
  consultant: null,
  consultantId: null,
  consultantByEmail: false,
  activityLoding: false,
  activity: null,
  clientLoding: null,
  clients: null,
  activityStatus: null,
  clientStatus: null,
  salaryLoding: null,
  filterconsultantLoding: false,
  displayFilterCount: false,
  certifications: [],
  updatedsalesDates: false,
  updateActivityLoading: false,
  updateactivityStatusLoding: false,
  updatedsalesStatus: false,
  cvLoading: false,
  cv: null,
  deleteActivityLoding: false,
  deleteStatus: false,
  users: [],
  usersLoading: false,
  usersStatus: null,
  exchangeRatesLoading: false,
  exchangeRates: {},
  synchronizeLoader: false,
  currentFromProgessi: null,
  infoLoading: false,
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONSULTANTS:
      return {
        ...state,
        consultantLoding: false,
      };
    case GET_ALL_CONSULTANTS_SUCCES:
      return {
        ...state,
        consultantLoding: true,
        allConsultants: action.allConsultants,
      };
    case GET_ALL_CONSULTANTS_FAILED:
      return {
        ...state,
        allConsultants: null,
        consultantLoding: false,
      };
    case SORT_CONSULTANTS_BY_IC:
      return {
        ...state,
        allConsultants: action.sortedConsultants,
        sortIcDirection: action.sortBy,
      };
    case SORT_CONSULTANTS:
      return {
        ...state,
        allConsultants: action.sortedConsultants,
        sortIcDirection: action.sortBy,
      };
    case GET_CONSULTANT_BYID:
      return {
        ...state,
        consultantLoding: true,
        // TO avoid double rerender
        activityStatus: null,
        deleteStatus: null,
        updatedsalesDates: null,
      };
    case GET_CONSULTANT_BYID_SUCCES:
      return {
        ...state,
        consultantLoding: false,
        consultant: action.consultant,
      };
    case GET_CONSULTANT_BYID_FAILED:
      return {
        ...state,
        consultant: null,
        consultantLoding: false,
      };
    case POST_ACTIVITY:
      return { ...state, activityLoding: true, activityStatus: null };
    case POST_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityLoding: false,
        activityStatus: 'success',
      };
    case POST_ACTIVITY_FAILED:
      return { ...state, activityLoding: false, activityStatus: 'failed' };
    case POST_CLIENT:
      return { ...state, clientLoding: true };
    case POST_CLIENT_SUCCES:
      return {
        ...state,
        clientLoding: false,
        clientStatus: action.status,
      };
    case POST_CLIENT_FAILED:
      return { ...state, clientLoding: false };

    case GET_CONSULTANT_EMAIL:
      return {
        ...state,
        consultantByEmail: true,
      };
    case GET_CONSULTANT_EMAIL_SUCCES:
      return {
        ...state,
        consultantByEmail: false,
        consultantId: action.consultantId,
      };
    case GET_CONSULTANT_EMAIL_FAILED:
      return {
        ...state,
        consultantByEmail: false,
        consultantId: null,
      };

    case GET_CLIENTS:
      return {
        ...state,
        clientLoding: true,
        clients: action.clients,
      };
    case GET_CLIENTS_SUCCES:
      return {
        ...state,
        clientLoding: false,
        clients: action.clients,
      };
    case GET_CLIENTS_FAILED:
      return {
        ...state,
        clients: null,
        clientLoding: false,
      };

    // certifications
    case GET_ALL_CERTIFICATIONS:
      return {
        ...state,
        certificationLoading: true,
        certifications: action.allCertifications,
      };
    case GET_ALL_CERTIFICATIONS_SUCCESS:
      return {
        ...state,
        certificationLoading: false,
        certifications: action.allCertifications,
      };
    case GET_ALL_CERTIFICATIONS_FAILED:
      return {
        ...state,
        certificationLoading: true,
        certifications: null,
      };
    // Salary
    case POST_SALARY:
      return { ...state, salaryLoding: true };
    case POST_SALARY_SUCCES:
      return {
        ...state,
        salaryLoding: false,
        salaryStatus: action.salaryStatus,
      };
    case POST_SALARY_FAILED:
      return { ...state, salaryLoding: false };

    case POST_FILTERS:
      return { ...state, filterconsultantLoding: true };
    case HIDE_FILTERS_COUNT:
      return { ...state, displayFilterCount: false };
    case POST_FILTERS_SUCCES:
      return {
        ...state,
        filterconsultantLoding: false,
        allConsultants: action.allConsultants,
        displayFilterCount: true,
      };
    case POST_FILTERS_FAILED:
      return { ...state, filterconsultantLoding: false };
    case CLEAR_SELECTED_CONSULTANT:
      return {
        ...state,
        consultant: initForm(),
      };

    // update activity
    case UPDATE_ACTIVITY:
      return {
        ...state,
        updateActivityLoading: true,
        updatedsalesDates: 'failed',
      };
    case UPDATE_ACTIVITY_SUCCES:
      return {
        ...state,
        updateActivityLoading: false,
        updatedsalesDates: 'success',
      };
    case UPDATE_ACTIVITY_FAILED:
      return {
        ...state,
        updateActivityLoading: false,
        updatedsalesDates: 'failed',
      };

    // update activity status
    case UPDATE_ACTIVITY_STATUS:
      return {
        ...state,
        updateactivityStatusLoding: true,
        updatedsalesStatus: 'failed',
      };
    case UPDATE_ACTIVITY_STATUS_SUCCES:
      return {
        ...state,
        updateactivityStatusLoding: false,
        updatedsalesStatus: action.updatedsalesStatus,
      };
    case UPDATE_ACTIVITY_STATUS_FAILED:
      return {
        ...state,
        updateactivityStatusLoding: false,
        updatedsalesStatus: 'failed',
      };

    //update activity status and post new activity
    case POST_ACTIVITY_UPDATE_ACTIVITY_STATUS:
      return {
        ...state,
        updateactivityStatusLoding: true,
        updatedsalesStatus: 'failed',
        activityLoding: true,
        activityStatus: 'failed',
      };

    // update comment
    case UPDATE_COMMENT:
      return {
        ...state,
        updateActivityLoading: true,
      };
    case UPDATE_COMMENT_SUCCES:
      return {
        ...state,
        updateActivityLoading: false,
        updatedsalesStatus: action.updatedsalesStatus,
      };
    case UPDATE_COMMENT_FAILED:
      return {
        ...state,
        updateActivityLoading: false,
      };

    // send email
    case SEND_EMAIL:
      return { ...state, sendingEmail: true };

    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendingEmail: false,
        sendingEmailSuccess: action.status,
      };
    case SEND_EMAIL_FAIL:
      return {
        ...state,
        sendingEmail: false,
        sendingEmailSuccess: false,
      };
    // get skills
    case GET_COMPETENCES:
      return {
        ...state,
        loadingCompetences: true,
        allCompetences: action.allCompetences,
      };

    case GET_COMPETENCES_SUCCESS:
      return {
        ...state,
        loadingCompetences: false,
        allCompetences: action.allCompetences,
      };

    case GET_COMPETENCES_FAIL:
      return { ...state, loadingCompetences: false, allCompetences: [] };

    // download cv
    case GET_DOWNLOAD_CV:
      return {
        ...state,
        cvLoading: true,
      };
    case GET_DOWNLOAD_CV_SUCCESS:
      return {
        ...state,
        cvLoading: false,
        cv: action.cv,
      };
    case GET_DOWNLOAD_CV_FAILED:
      return {
        ...state,
        cv: null,
        cvLoading: false,
      };

    // update consultant status
    case UPDATE_CONSULTANT_STATUS:
      return {
        ...state,
        updatedconsultantStatus: 'failed',
      };
    case UPDATE_CONSULTANT_STATUS_SUCCES:
      return {
        ...state,
        updatedconsultantStatus: action.updatedconsultantStatus,
      };
    case UPDATE_CONSULTANT_STATUS_FAILED:
      return {
        ...state,
        updatedconsultantStatus: 'failed',
      };
    // reset cv
    case RESET_CV:
      return {
        ...state,
        cv: null,
        cvLoading: false,
      };

    // update activity
    case DELETE_ACTIVITY:
      return {
        ...state,
        deleteActivityLoding: true,
        deleteStatus: 'failed',
      };
    case DELETE_ACTIVITY_SUCCES:
      return {
        ...state,
        deleteActivityLoding: false,
        deleteStatus: 'success',
      };
    case DELETE_ACTIVITY_FAILED:
      return {
        ...state,
        deleteActivityLoding: false,
        deleteStatus: 'failed',
      };

    //get all users
    case GET_USERS:
      return {
        ...state,
        usersLoading: true,
      };
    case GET_USERS_SUCCES:
      return {
        ...state,
        users: action.users,
        usersLoading: false,
        usersStatus: action.message,
      };
    case GET_USERS_FAILED:
      return {
        ...state,
        usersLoading: false,
        usersStatus: 'failed',
      };
    //get all users
    case GET_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRatesLoading: true,
      };
    case GET_EXCHANGE_RATES_SUCCES:
      return {
        ...state,
        exchangeRates: action.exchangeRates,
        exchangeRatesLoading: false,
      };
    case GET_EXCHANGE_RATES_FAILED:
      return {
        ...state,
        exchangeRatesLoading: false,
      };

    case SYNCHRONIZE_CONSULTANT_WITH_PROGESSI:
      return {
        ...state,
        synchronizeLoader: true,
      };

    case SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_SUCCES:
      return {
        ...state,
        synchronizeLoader: false,
      };

    case SYNCHRONIZE_CONSULTANT_WITH_PROGESSI_FAILED:
      return {
        ...state,
        synchronizeLoader: false,
      };

    // get consultant form progessi by id
    case GET_CONSULTANT_FROM_PROGESSI:
      return {
        ...state,
        infoLoading: true,
        currentFromProgessi: null,
      };

    case GET_CONSULTANT_FROM_PROGESSI_SUCCES:
      return {
        ...state,
        infoLoading: false,
        currentFromProgessi: action.currentFromProgessi,
      };

    case GET_CONSULTANT_FROM_PROGESSI_FAILED:
      return {
        ...state,
        infoLoading: false,
        currentFromProgessi: null,
      };

    default:
      return state;
  }
};

export default DashboardReducer;
