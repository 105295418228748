import React from 'react';
import { Table, Thead, Tbody, Th, Td } from './styles';
import Spinner from '../Spinner';

export default ({ rows, columns, nb_page, isLoading, selectedRow }) => {
  if (isLoading) {
    return <Spinner loading={isLoading} />;
  } else {
    return (
      <>
        <Table>
          <Thead>
            <tr>
              {columns.map((item, idx) => (
                <Th key={idx}>{item.title}</Th>
              ))}
            </tr>
          </Thead>
          {rows.map((item, idx) => (
            <Tbody key={idx} onClick={() => selectedRow(item)}>
              <tr>
                {columns.map((col, idx) => (
                  <Td key={idx}>{item[col.name]}</Td>
                ))}
              </tr>
            </Tbody>
          ))}
        </Table>
      </>
    );
  }
};
