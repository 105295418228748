import React, { lazy, Suspense } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { isSafari } from 'react-device-detect';
import styled from 'styled-components';

import { ThemeProvider } from '@material-ui/styles';
import AppTheme from 'constants/theme';
import LanguageProvider from 'containers/LanguageProvider';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configureStore, { history } from 'store';
import ProtectedRoute from 'utils/protected';
import ErrorBoundary from './ErrorBoundary';
import { Spinner } from 'utils/focus-ui-kit';

const store = configureStore();

// Lazy-loaded components
const HomePage = lazy(() => import('containers/HomePage'));
const ResetPass = lazy(() => import('containers/HomePage/reset-pass-form'));
const DashboardPage = lazy(() => import('containers/DashboardPage'));
const ListePage = lazy(() => import('containers/ListePage'));
const DetailsPage = lazy(() => import('containers/DetailsPage'));
const CreateProfile = lazy(() => import('containers/CreateProfile'));
const CreateFullProfile = lazy(() =>
  import('containers/CreateProfile/CreateFullProfile'),
);
const Resume = lazy(() => import('containers/Resume'));
const Reporting = lazy(() => import('containers/Reporting'));
const NotFound = lazy(() => import('containers/NotFoundPage'));

const App = () => {
  if (isSafari) {
    return (
      <Safari>
        Safari is not supported. Download Chrome/Opera/Firefox/Edge.
      </Safari>
    );
  }
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={AppTheme}>
          <CssBaseline />
          <LanguageProvider>
            <Router history={history}>
              <Suspense fallback={<Spinner loading={true} />}>
                <Switch>
                  <Route exact path="/login" component={HomePage} />
                  <Route
                    exact
                    path="/reset-password/:id"
                    component={ResetPass}
                  />
                  <ProtectedRoute exact path="/" component={DashboardPage} />
                  <ProtectedRoute
                    exact
                    path="/liste_utilisateurs"
                    component={ListePage}
                  />
                  <ProtectedRoute
                    exact
                    path="/consultant-profile/:id"
                    component={DetailsPage}
                  />
                  <ProtectedRoute
                    exact
                    path="/create_profile"
                    component={CreateProfile}
                  />
                  <ProtectedRoute
                    exact
                    path="/create-full-profile"
                    component={CreateFullProfile}
                  />
                  <ProtectedRoute
                    exact
                    path="/edit-full-profile/:id"
                    component={CreateFullProfile}
                  />
                  <ProtectedRoute exact path="/resume/:id" component={Resume} />
                  <ProtectedRoute
                    exact
                    path="/reporting"
                    component={Reporting}
                  />
                  <Route path="*" component={NotFound} />
                </Switch>
              </Suspense>
            </Router>
            <ToastContainer
              position="top-left"
              autoClose={6000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </LanguageProvider>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;

export const Safari = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Rubik;
`;
