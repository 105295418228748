import { createGlobalStyle } from 'styled-components';
import Rubrik from 'assets/fonts/36d815a5133e11bc3bbbc8361b43959e.woff';
import './index.css';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  h1, h2 {
    font-size: 24px;
    color: #222328;
    letter-spacing: 0;
    line-height: 28px;
    font-weight: 400;
  }

  h3 {
    color: #767874;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
  }

  .MuiMenu-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .MuiMenuItem-root {
    color: #0075C9 !important;
  }

  @font-face {
  font-family: 'Rubrik';
  src: local('Rubrik'), local('Rubrik'),
  url(${Rubrik}) format('woff');
}


`;
