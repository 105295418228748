import {
  GET_ALL_COMPETENCES,
  GET_ALL_COMPETENCES_FAILED,
  GET_ALL_COMPETENCES_SUCCES,
} from 'containers/table/constants';

const initialState = {
  competences: [],
  competencesLoading: false,
};

function createCompetencesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COMPETENCES:
      return {
        ...state,
        competencesLoading: true,
      };
    case GET_ALL_COMPETENCES_SUCCES:
      return {
        ...state,
        competencesLoading: false,
        competences: action.allCompetences,
      };
    case GET_ALL_COMPETENCES_FAILED:
      return {
        ...state,
        competencesLoading: true,
        competences: null,
      };
    default:
      return state;
  }
}

export default createCompetencesReducer;
