import styled from 'styled-components';

export const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-top: 50px;
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3f42;
`;
export const Mision = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 13.5px;
  width: 50px;
  height: 27px;
  text-align: center;
  align-items: center;
  align-self: center;
  border: ${(props) => (props.themegb <= 2 ? ' solid 1px #e0e3e8' : '#df0000')};
  color: ${(props) => (props.themegb <= 2 ? '#3e3f42' : '#df0000')};
  background-color: ${(props) =>
    props.themegb <= 2 ? '' : 'rgba(223, 0, 0, 0.08)'};
`;

export const PresentationNumber = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 5px 20px;
  margin-right: 128px;
  border-radius: 13.5px;
  border: ${(props) =>
    props.number <= 2 ? 'solid 1px #e0e3e8' : 'solid 1px #df0000'};
  color: ${(props) => (props.number <= 2 ? '#3e3f42' : '#df0000')};
  background-color: ${(props) =>
    props.number <= 2 ? '' : 'rgba(223, 0, 0, 0.08)'};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-left: 4px solid transparent;
  border-top: solid 1px #eef1f5;
  position: relative;
  .noData {
  }

  .td-container {
    position: relative;
  }

  .icon1 {
    display: none;
    position: absolute;
    right: 32px;
    width: 32px;
    height: 32px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px;
    border: solid 1px #e0e3e8;
    background-image: linear-gradient(
      to bottom,
      #fafbfc,
      rgba(238, 241, 245, 0.44)
    );
    &:hover {
      background-color: #0054df;
      background-image: none;

      svg path {
        fill: white;
      }
    }
  }

  .icon2 {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 72px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #e0e3e8;
    background-image: linear-gradient(
      to bottom,
      #fafbfc,
      rgba(238, 241, 245, 0.44)
    );
    &:hover {
      background-color: #0054df;
      background-image: none;

      svg path {
        fill: white;
      }
    }
  }
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  &:nth-child(even) {
    background-color: #fafbfc;
  }

  &:hover {
    border-left: 4px solid #0054df;
    cursor: pointer;

    .icon1 {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon2 {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    td {
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3e3f42;
    }
  }
`;

export const Th = styled.th`
  font-family: Rubik;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #3e3f42;
  text-align: left;
  padding: 18px 0 18px 32px;
`;

export const Td = styled.td`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3f42;
  padding: 18px 0 18px 32px;
`;
