import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
} from 'containers/HomePage/constants';

const initialState = {
  message: null,
  forgot_success: null,
  forgot_error: null,
  loadingForgot: false,
  loadingReset: false,
  updated: null,
};

const createLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return { ...state, loading: true };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: null,
      };
    case GET_USER_FAILED:
      return { ...state, loading: false, message: action.message };

    case FORGOT_PASSWORD:
      return { ...state, loadingForgot: true };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingForgot: false,
        forgot_success: action.forgot_success,
        forgot_error: null,
        message: action.message,
      };
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        loadingForgot: false,
        forgot_error: action.forgot_error,
        message: action.message,
      };

    case RESET_PASSWORD:
      return { ...state, loadingReset: false };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingReset: false,
        updated: action.updated,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loadingReset: false,
        updated: action.updated,
      };

    default:
      return state;
  }
};

export default createLoginReducer;
