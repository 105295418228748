import {
  GET_INTERCONTRACT_QUANTITY_COUNTRIES,
  GET_INTERCONTRACT_QUANTITY_COUNTRIES_SUCCES,
  GET_INTERCONTRACT_QUANTITY_COUNTRIES_FAILED,
  GET_INTERCONTRACT_QUANTITY_COMMUNITIES,
  GET_INTERCONTRACT_QUANTITY_COMMUNITIES_SUCCES,
  GET_INTERCONTRACT_QUANTITY_COMMUNITIES_FAILED,
  GET_INTERCONTRACT_COST_COUNTRIES_FAILED,
  GET_INTERCONTRACT_COST_COUNTRIES_SUCCES,
  GET_INTERCONTRACT_COST_COUNTRIES,
  GET_INTERCONTRACT_COST_COMMUNITIES_FAILED,
  GET_INTERCONTRACT_COST_COMMUNITIES_SUCCES,
  GET_INTERCONTRACT_COST_COMMUNITIES,
  GET_INTERCONTRACT_UNIQUE_COUNTRIES,
  GET_INTERCONTRACT_UNIQUE_COUNTRIES_SUCCES,
  GET_INTERCONTRACT_UNIQUE_COUNTRIES_FAILED,
  GET_INTERCONTRACT_UNIQUE_COMMUNITIES,
  GET_INTERCONTRACT_UNIQUE_COMMUNITIES_SUCCES,
  GET_INTERCONTRACT_UNIQUE_COMMUNITIES_FAILED,
  GET_YTD,
  GET_YTD_SUCCES,
  GET_YTD_FAILED,
  GET_HR_KPI,
  GET_HR_KPI_SUCCES,
  GET_HR_KPI_FAILED,
} from 'containers/Reporting/constants';

const initialState = {
  intercontractsQuantityCountries: [],
  intercontractsQuantityCommunities: [],
  intercontractsCostCountries: [],
  intercontractsCostCommunities: [],
  intercontractsUniqueCountries: [],
  intercontractsUniqueCommunities: [],
  ytdCount: 0,
  ytdCost: 0,
  hrKpi: [],
  hrKpiLoading: false,
};

const createKpiReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INTERCONTRACT_QUANTITY_COUNTRIES:
      return {
        ...state,
      };
    case GET_INTERCONTRACT_QUANTITY_COUNTRIES_SUCCES:
      return {
        ...state,
        intercontractsQuantityCountries: action.intercontractsQuantityCountries,
      };
    case GET_INTERCONTRACT_QUANTITY_COUNTRIES_FAILED:
      return {
        ...state,
      };

    case GET_INTERCONTRACT_QUANTITY_COMMUNITIES:
      return {
        ...state,
      };
    case GET_INTERCONTRACT_QUANTITY_COMMUNITIES_SUCCES:
      return {
        ...state,
        intercontractsQuantityCommunities:
          action.intercontractsQuantityCommunities,
      };
    case GET_INTERCONTRACT_QUANTITY_COMMUNITIES_FAILED:
      return {
        ...state,
      };

    case GET_INTERCONTRACT_COST_COUNTRIES:
      return {
        ...state,
      };
    case GET_INTERCONTRACT_COST_COUNTRIES_SUCCES:
      return {
        ...state,
        intercontractsCostCountries: action.intercontractsCostCountries,
      };
    case GET_INTERCONTRACT_COST_COUNTRIES_FAILED:
      return {
        ...state,
      };

    case GET_INTERCONTRACT_COST_COMMUNITIES:
      return {
        ...state,
      };
    case GET_INTERCONTRACT_COST_COMMUNITIES_SUCCES:
      return {
        ...state,
        intercontractsCostCommunities: action.intercontractsCostCommunities,
      };
    case GET_INTERCONTRACT_COST_COMMUNITIES_FAILED:
      return {
        ...state,
      };

    case GET_INTERCONTRACT_UNIQUE_COUNTRIES:
      return {
        ...state,
      };
    case GET_INTERCONTRACT_UNIQUE_COUNTRIES_SUCCES:
      return {
        ...state,
        intercontractsUniqueCountries: action.intercontractsUniqueCountries,
      };
    case GET_INTERCONTRACT_UNIQUE_COUNTRIES_FAILED:
      return {
        ...state,
      };

    case GET_INTERCONTRACT_UNIQUE_COMMUNITIES:
      return {
        ...state,
      };
    case GET_INTERCONTRACT_UNIQUE_COMMUNITIES_SUCCES:
      return {
        ...state,
        intercontractsUniqueCommunities: action.intercontractsUniqueCommunities,
      };
    case GET_INTERCONTRACT_UNIQUE_COMMUNITIES_FAILED:
      return {
        ...state,
      };

    case GET_YTD:
      return {
        ...state,
      };
    case GET_YTD_SUCCES:
      return {
        ...state,
        ytdCount: action.ytdCount,
        ytdCost: action.ytdCost,
      };
    case GET_YTD_FAILED:
      return {
        ...state,
      };

    case GET_HR_KPI:
      return {
        ...state,
        hrKpiLoading: true,
      };
    case GET_HR_KPI_SUCCES:
      return {
        ...state,
        hrKpi: action.hrKpi,
        hrKpiLoading: false,
      };
    case GET_HR_KPI_FAILED:
      return {
        ...state,
        hrKpiLoading: false,
      };

    default:
      return state;
  }
};

export default createKpiReducer;
