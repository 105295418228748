import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TitlesSection = ({ title, subTitle, isTitle }) => {
  return (
    <Container>
      {isTitle ? <h1>{title}</h1> : <h2>{title}</h2>}
      <h3>{subTitle}</h3>
    </Container>
  );
};

const Container = styled.div`
  h1 {
    margin-bottom: 4px;
    font-family: Rubik;
    font-size: 28px;
    font-weight: 500;
  },
  h2 {
    font-family: Rubik;
    margin-bottom: 4px;
  },
  h3 {
    font-family: Rubik;
  }
`;

TitlesSection.defaultProps = {
  isTitle: false,
};

TitlesSection.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  isTitle: PropTypes.bool,
};

export default TitlesSection;
