import { DEFAULT_LOCALE } from 'containers/LanguageProvider/constants';
import { createSelector } from 'reselect';

export const selectIntl = (state) => state.get('intl');

export const selectLocale = () =>
  createSelector(selectIntl, (intl) => {
    if (intl && intl.locale) {
      return intl.locale;
    }
    return ['fr', 'en'].includes(DEFAULT_LOCALE) ? DEFAULT_LOCALE : 'en';
  });
