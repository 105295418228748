/* eslint-disable import/prefer-default-export */
export const DELEGATION_STATUS = {
  INVALIDATION: 'in validation',
  NEEDTOBEREVIEWED: 'needs to be reviewed',
  SENT: 'sent',
  READ: 'read',
  VALIDATED: 'validated',
  UPLOADED: 'uploaded',
  DENIED: 'denied',
  DELETED: 'deleted',
  INACTIVE: 'inactive',
};

export const COLORS = {
  BLACK: '#000',
  ORANGE: '#f39c12',
  GREY: '#95a5a6',
  GREY80_TINTED: '#efefef',
  BLUE: '#0054DF',
  BLUE_PALE: '#0054df19',
  RED: '#D32F2F',
  GREEN: '#63B545',
  YELLOW: '#fcd21c',
  PINK: '#FFB6C1',
  WHITE: '#FFF',
  MALTEM_RED: '#E84137',
};

export const LEAGALAGE = 18;

export const ALERTICC_LEVEL = {
  LEVEL1: 0,
  LEVEL2: 1,
  LEVEL3: 3,
};
