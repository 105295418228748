import { defineMessages } from 'react-intl';

export default defineMessages({
  statusInValidation: {
    id: 'status_in_validation',
  },
  statusNeedToBeReviewed: {
    id: 'status_need_to_be_reviewed',
  },
  statusRead: {
    id: 'status_read',
  },
  statusSent: {
    id: 'status_sent',
  },
  statusValidated: {
    id: 'status_validated',
  },
  statusDeleted: {
    id: 'status_deleted',
  },
  statusDenied: {
    id: 'status_denied',
  },
  statusInActive: {
    id: 'status_inactive',
  },
  statusUploaded: {
    id: 'status_uploaded',
  },
  roleSubscriber: {
    id: 'role_subscriber',
  },
  roleHeadOfUnderwriting: {
    id: 'role_head_of_underwriting',
  },
});
