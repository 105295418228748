// STEP CONSTANTS
export const SAVE_STEP = 'SAVE STEP [SAVE_STEP]';
export const CLEAR_STEP = 'CLEAR STEP [CLEAR_STEP]';
// POST CONSULTANT CONSTANTS
export const POST_CONSULTANT = 'POST CONSULTANT [POST_CONSULTANT]';
export const POST_CONSULTANT_SUCCES =
  'POST CONSULTANT SUCCES [POST_CONSULTANT_SUCCES]';
export const POST_CONSULTANT_FAILED =
  'POST CONSULTANT FAILED [POST_CONSULTANT_FAILED]';

// UPDATE CONSULTANT
export const UPDATE_CONSULTANT = 'UPDATE_CONSULTANT';
export const UPDATE_CONSULTANT_FAILED = 'UPDATE_CONSULTANT_FAILED';
export const UPDATE_CONSULTANT_SUCCES = 'UPDATE_CONSULTANT_SUCCES';

// get all jobs
export const GET_ALL_JOBS = 'GET ALL JOBS [GET_ALL_JOBS]';
export const GET_ALL_JOBS_FAILED = 'GET ALL JOBS SUCCES [GET_ALL_JOBS_FAILED]';
export const GET_ALL_JOBS_SUCCES = 'GET ALL JOBS SUCCES [GET_ALL_JOBS_SUCCES]';
export const UPDATE_ARCHIVE = 'UPDATE_ARCHIVE';
export const UPDATE_ARCHIVE_SUCCES = 'UPDATE_ARCHIVE_SUCCES';
export const UPDATE_ARCHIVE_FAILED = 'UPDATE_ARCHIVE_FAILED';

// follow consultant
export const UPDATE_FOLLOW = 'UPDATE_FOLLOW';
export const UPDATE_FOLLOW_SUCCESS = 'UPDATE_FOLLOW_SUCCESS';
export const UPDATE_FOLLOW_FAILED = 'UPDATE_FOLLOW_FAILED';

export const GET_CERTIFICATION_FILE = 'GET_CERTIFICATION_FILE';

export const initForm = () => {
  return {
    partyId: null,
    title: '',
    firstName: '',
    lastName: '',
    bio: '',
    birthday: '1995-01-01T00:00:00.000Z',
    maritalStatus: '',
    email: '',
    nationality: '',
    phone: '',
    adresse: '',
    city: '',
    codePostal: '',
    country: '',
    mobility: [],
    contractType: '',
    hiringDate: '',
    cjm: '',
    jobTitle: '',
    seniority: '',
    yearsOfExperience: '',
    profile: '',
    status: 'None',
    statusDate: new Date(),
    businessSkills: [],
    role: '',
    businessUnit: [],
    typeBusinessUnit: [],
    skills: [],
    lastReminderDate: null,
    salary: [
      {
        amount: 1000,
        pjt: 0,
        bonus: 0,
        dailyFees: 0,
        cjmUSD: 0,
        updated_at: '2023-02-14T01:31:09.292Z',
        updated_by: '-',
      },
    ],
    technicalSkills: [
      {
        value: '',
        groupFamily: '',
        color: '',
        comment: '',
      },
    ],
    education: [
      {
        degree: '',
        year: '',
        institute: '',
        type: '',
        level: '',
        fileUrl: '',
        fileName: '',
      },
    ],
    certification: [
      {
        name: '',
        year: '',
        institute: '',
        selectedCertif: '',
        fileUrl: '',
        fileName: '',
      },
    ],
    languages: [
      {
        language: '',
        writingReadingSkills: '',
        speakingSkills: '',
        certification: '',
        grade: '',
        score: '',
        year: '',
        moreCertificationLabel: '',
        moreCertificationGrade: '',
        moreCertificationScore: '',
        moreCertificationYear: '',
      },
    ],
    registered: '2019-11-16T21:05:53.350Z',
    updated: '2019-11-16T21:05:53.350Z',
    remuneration: [],
    missions: [
      {
        startDate: '2019-01-02',
        activityType: 'positionning',
        client: 'Google',
        business: 'basic healin',
        status: 'Pending',
      },
    ],
    sales: [],
    experiences: [
      {
        jobTitle: '',
        companyName: '',
        missionsFramework: '',
        startDate: '',
        endDate: '',
        achievement: [
          {
            title: '',
            description: '',
          },
        ],
        methodolienviron: [
          {
            title: '',
            description: '',
          },
        ],
        technicalenviro: [
          {
            title: '',
            description: '',
          },
        ],
        Functionalenviron: [
          {
            title: '',
            description: '',
          },
        ],
        tools: [
          {
            title: '',
          },
        ],
        isRelevant: false,
        isInProgress: false,
      },
    ],
    business: [],
    hrbp: {
      label: '',
      id: '',
    },
    consultantRef: {
      label: '',
      id: '',
    },
    trackingStatus: '',
    lastsynchroDate: null,
  };
};

export const language = [
  {
    code: 'ab',
    name: 'Abkhaz',
  },
  {
    code: 'aa',
    name: 'Afar',
  },
  {
    code: 'af',
    name: 'Afrikaans',
  },
  {
    code: 'ak',
    name: 'Akan',
  },
  {
    code: 'sq',
    name: 'Albanian',
  },
  {
    code: 'am',
    name: 'Amharic',
  },
  {
    code: 'ar',
    name: 'Arabic',
  },
  {
    code: 'an',
    name: 'Aragonese',
  },
  {
    code: 'hy',
    name: 'Armenian',
  },
  {
    code: 'as',
    name: 'Assamese',
  },
  {
    code: 'av',
    name: 'Avaric',
  },
  {
    code: 'ae',
    name: 'Avestan',
  },
  {
    code: 'ay',
    name: 'Aymara',
  },
  {
    code: 'az',
    name: 'Azerbaijani',
  },
  {
    code: 'bm',
    name: 'Bambara',
  },
  {
    code: 'ba',
    name: 'Bashkir',
  },
  {
    code: 'eu',
    name: 'Basque',
  },
  {
    code: 'be',
    name: 'Belarusian',
  },
  {
    code: 'bn',
    name: 'Bengali; Bangla',
  },
  {
    code: 'bh',
    name: 'Bihari',
  },
  {
    code: 'bi',
    name: 'Bislama',
  },
  {
    code: 'bs',
    name: 'Bosnian',
  },
  {
    code: 'br',
    name: 'Breton',
  },
  {
    code: 'bg',
    name: 'Bulgarian',
  },
  {
    code: 'my',
    name: 'Burmese',
  },
  {
    code: 'ca',
    name: 'Catalan; Valencian',
  },
  {
    code: 'ch',
    name: 'Chamorro',
  },
  {
    code: 'ce',
    name: 'Chechen',
  },
  {
    code: 'ny',
    name: 'Chichewa; Chewa; Nyanja',
  },
  {
    code: 'zh',
    name: 'Chinese',
  },
  {
    code: 'cv',
    name: 'Chuvash',
  },
  {
    code: 'kw',
    name: 'Cornish',
  },
  {
    code: 'co',
    name: 'Corsican',
  },
  {
    code: 'cr',
    name: 'Cree',
  },
  {
    code: 'hr',
    name: 'Croatian',
  },
  {
    code: 'cs',
    name: 'Czech',
  },
  {
    code: 'da',
    name: 'Danish',
  },
  {
    code: 'dv',
    name: 'Divehi; Dhivehi; Maldivian;',
  },
  {
    code: 'nl',
    name: 'Dutch',
  },
  {
    code: 'dz',
    name: 'Dzongkha',
  },
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'eo',
    name: 'Esperanto',
  },
  {
    code: 'et',
    name: 'Estonian',
  },
  {
    code: 'ee',
    name: 'Ewe',
  },
  {
    code: 'fo',
    name: 'Faroese',
  },
  {
    code: 'fj',
    name: 'Fijian',
  },
  {
    code: 'fi',
    name: 'Finnish',
  },
  {
    code: 'fr',
    name: 'French',
  },
  {
    code: 'ff',
    name: 'Fula; Fulah; Pulaar; Pular',
  },
  {
    code: 'gl',
    name: 'Galician',
  },
  {
    code: 'ka',
    name: 'Georgian',
  },
  {
    code: 'de',
    name: 'German',
  },
  {
    code: 'el',
    name: 'Greek, Modern',
  },
  {
    code: 'gn',
    name: 'GuaranÃ­',
  },
  {
    code: 'gu',
    name: 'Gujarati',
  },
  {
    code: 'ht',
    name: 'Haitian; Haitian Creole',
  },
  {
    code: 'ha',
    name: 'Hausa',
  },
  {
    code: 'he',
    name: 'Hebrew (modern)',
  },
  {
    code: 'hz',
    name: 'Herero',
  },
  {
    code: 'hi',
    name: 'Hindi',
  },
  {
    code: 'ho',
    name: 'Hiri Motu',
  },
  {
    code: 'hu',
    name: 'Hungarian',
  },
  {
    code: 'ia',
    name: 'Interlingua',
  },
  {
    code: 'id',
    name: 'Indonesian',
  },
  {
    code: 'ie',
    name: 'Interlingue',
  },
  {
    code: 'ga',
    name: 'Irish',
  },
  {
    code: 'ig',
    name: 'Igbo',
  },
  {
    code: 'ik',
    name: 'Inupiaq',
  },
  {
    code: 'io',
    name: 'Ido',
  },
  {
    code: 'is',
    name: 'Icelandic',
  },
  {
    code: 'it',
    name: 'Italian',
  },
  {
    code: 'iu',
    name: 'Inuktitut',
  },
  {
    code: 'ja',
    name: 'Japanese',
  },
  {
    code: 'jv',
    name: 'Javanese',
  },
  {
    code: 'kl',
    name: 'Kalaallisut, Greenlandic',
  },
  {
    code: 'kn',
    name: 'Kannada',
  },
  {
    code: 'kr',
    name: 'Kanuri',
  },
  {
    code: 'ks',
    name: 'Kashmiri',
  },
  {
    code: 'kk',
    name: 'Kazakh',
  },
  {
    code: 'km',
    name: 'Khmer',
  },
  {
    code: 'ki',
    name: 'Kikuyu, Gikuyu',
  },
  {
    code: 'rw',
    name: 'Kinyarwanda',
  },
  {
    code: 'ky',
    name: 'Kyrgyz',
  },
  {
    code: 'kv',
    name: 'Komi',
  },
  {
    code: 'kg',
    name: 'Kongo',
  },
  {
    code: 'ko',
    name: 'Korean',
  },
  {
    code: 'ku',
    name: 'Kurdish',
  },
  {
    code: 'kj',
    name: 'Kwanyama, Kuanyama',
  },
  {
    code: 'la',
    name: 'Latin',
  },
  {
    code: 'lb',
    name: 'Luxembourgish, Letzeburgesch',
  },
  {
    code: 'lg',
    name: 'Ganda',
  },
  {
    code: 'li',
    name: 'Limburgish, Limburgan, Limburger',
  },
  {
    code: 'ln',
    name: 'Lingala',
  },
  {
    code: 'lo',
    name: 'Lao',
  },
  {
    code: 'lt',
    name: 'Lithuanian',
  },
  {
    code: 'lu',
    name: 'Luba-Katanga',
  },
  {
    code: 'lv',
    name: 'Latvian',
  },
  {
    code: 'gv',
    name: 'Manx',
  },
  {
    code: 'mk',
    name: 'Macedonian',
  },
  {
    code: 'mg',
    name: 'Malagasy',
  },
  {
    code: 'ms',
    name: 'Malay',
  },
  {
    code: 'ml',
    name: 'Malayalam',
  },
  {
    code: 'mt',
    name: 'Maltese',
  },
  {
    code: 'mi',
    name: 'MÄori',
  },
  {
    code: 'mr',
    name: 'Marathi (MarÄá¹­hÄ«)',
  },
  {
    code: 'mh',
    name: 'Marshallese',
  },
  {
    code: 'mn',
    name: 'Mongolian',
  },
  {
    code: 'na',
    name: 'Nauru',
  },
  {
    code: 'nv',
    name: 'Navajo, Navaho',
  },
  {
    code: 'nb',
    name: 'Norwegian BokmÃ¥l',
  },
  {
    code: 'nd',
    name: 'North Ndebele',
  },
  {
    code: 'ne',
    name: 'Nepali',
  },
  {
    code: 'ng',
    name: 'Ndonga',
  },
  {
    code: 'nn',
    name: 'Norwegian Nynorsk',
  },
  {
    code: 'no',
    name: 'Norwegian',
  },
  {
    code: 'ii',
    name: 'Nuosu',
  },
  {
    code: 'nr',
    name: 'South Ndebele',
  },
  {
    code: 'oc',
    name: 'Occitan',
  },
  {
    code: 'oj',
    name: 'Ojibwe, Ojibwa',
  },
  {
    code: 'cu',
    name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
  },
  {
    code: 'om',
    name: 'Oromo',
  },
  {
    code: 'or',
    name: 'Oriya',
  },
  {
    code: 'os',
    name: 'Ossetian, Ossetic',
  },
  {
    code: 'pa',
    name: 'Panjabi, Punjabi',
  },
  {
    code: 'pi',
    name: 'PÄli',
  },
  {
    code: 'fa',
    name: 'Persian (Farsi)',
  },
  {
    code: 'pl',
    name: 'Polish',
  },
  {
    code: 'ps',
    name: 'Pashto, Pushto',
  },
  {
    code: 'pt',
    name: 'Portuguese',
  },
  {
    code: 'qu',
    name: 'Quechua',
  },
  {
    code: 'rm',
    name: 'Romansh',
  },
  {
    code: 'rn',
    name: 'Kirundi',
  },
  {
    code: 'ro',
    name: 'Romanian, [])',
  },
  {
    code: 'ru',
    name: 'Russian',
  },
  {
    code: 'sa',
    name: 'Sanskrit (Saá¹ská¹›ta)',
  },
  {
    code: 'sc',
    name: 'Sardinian',
  },
  {
    code: 'sd',
    name: 'Sindhi',
  },
  {
    code: 'se',
    name: 'Northern Sami',
  },
  {
    code: 'sm',
    name: 'Samoan',
  },
  {
    code: 'sg',
    name: 'Sango',
  },
  {
    code: 'sr',
    name: 'Serbian',
  },
  {
    code: 'gd',
    name: 'Scottish Gaelic; Gaelic',
  },
  {
    code: 'sn',
    name: 'Shona',
  },
  {
    code: 'si',
    name: 'Sinhala, Sinhalese',
  },
  {
    code: 'sk',
    name: 'Slovak',
  },
  {
    code: 'sl',
    name: 'Slovene',
  },
  {
    code: 'so',
    name: 'Somali',
  },
  {
    code: 'st',
    name: 'Southern Sotho',
  },
  {
    code: 'es',
    name: 'Spanish; Castilian',
  },
  {
    code: 'su',
    name: 'Sundanese',
  },
  {
    code: 'sw',
    name: 'Swahili',
  },
  {
    code: 'ss',
    name: 'Swati',
  },
  {
    code: 'sv',
    name: 'Swedish',
  },
  {
    code: 'ta',
    name: 'Tamil',
  },
  {
    code: 'te',
    name: 'Telugu',
  },
  {
    code: 'tg',
    name: 'Tajik',
  },
  {
    code: 'th',
    name: 'Thai',
  },
  {
    code: 'ti',
    name: 'Tigrinya',
  },
  {
    code: 'bo',
    name: 'Tibetan Standard, Tibetan, Central',
  },
  {
    code: 'tk',
    name: 'Turkmen',
  },
  {
    code: 'tl',
    name: 'Tagalog',
  },
  {
    code: 'tn',
    name: 'Tswana',
  },
  {
    code: 'to',
    name: 'Tonga (Tonga Islands)',
  },
  {
    code: 'tr',
    name: 'Turkish',
  },
  {
    code: 'ts',
    name: 'Tsonga',
  },
  {
    code: 'tt',
    name: 'Tatar',
  },
  {
    code: 'tw',
    name: 'Twi',
  },
  {
    code: 'ty',
    name: 'Tahitian',
  },
  {
    code: 'ug',
    name: 'Uyghur, Uighur',
  },
  {
    code: 'uk',
    name: 'Ukrainian',
  },
  {
    code: 'ur',
    name: 'Urdu',
  },
  {
    code: 'uz',
    name: 'Uzbek',
  },
  {
    code: 've',
    name: 'Venda',
  },
  {
    code: 'vi',
    name: 'Vietnamese',
  },
  {
    code: 'vo',
    name: 'VolapÃ¼k',
  },
  {
    code: 'wa',
    name: 'Walloon',
  },
  {
    code: 'cy',
    name: 'Welsh',
  },
  {
    code: 'wo',
    name: 'Wolof',
  },
  {
    code: 'fy',
    name: 'Western Frisian',
  },
  {
    code: 'xh',
    name: 'Xhosa',
  },
  {
    code: 'yi',
    name: 'Yiddish',
  },
  {
    code: 'yo',
    name: 'Yoruba',
  },
  {
    code: 'za',
    name: 'Zhuang, Chuang',
  },
  {
    code: 'zu',
    name: 'Zulu',
  },
];

export const degree = [
  { id: 1, name: 'BAC' },
  { id: 2, name: 'BAC +2' },
  { id: 3, name: 'BAC +3' },
  { id: 4, name: 'BAC +4' },
  { id: 5, name: 'BAC +5' },
  { id: 6, name: 'Other' },
];

export const educationLevel = [
  { id: 1, name: 'BAC' },
  { id: 2, name: 'BAC +2' },
  { id: 3, name: 'BAC +3' },
  { id: 4, name: 'BAC +4' },
  { id: 5, name: 'BAC +5' },
  { id: 6, name: 'Other' },
];

export const role = [
  { id: 1, name: 'Open-ended contract' },
  { id: 2, name: 'Fixed-Term contract' },
  { id: 3, name: 'Freelancer' },
];

export const bu = [
  { id: 1, name: 'Business Specialists' },
  { id: 2, name: 'Pilotage and Organisation' },
  { id: 3, name: 'Tech' },
  { id: 4, name: 'Cyber Security' },
  { id: 5, name: 'AGILE' },
  { id: 6, name: 'Product' },
  { id: 7, name: 'Data' },
  { id: 8, name: 'UX/UI Design' },
];

export const level = [
  { id: 1, name: 'Beginner' },
  { id: 2, name: 'Medium' },
  { id: 3, name: 'High' },
];

export const seniority = [
  { id: 1, name: 'Senior' },
  { id: 2, name: 'Intermediate' },
  { id: 3, name: 'Junior' },
];

export const WritingReading = [
  { id: 1, name: 'Beginner' },
  { id: 2, name: 'Medium' },
  { id: 3, name: 'High' },
];
export const speakingSkills = [
  { id: 1, name: 'Beginner' },
  { id: 2, name: 'Medium' },
  { id: 3, name: 'High' },
];

export const maritalStatus = [
  { id: 1, name: 'Single' },
  { id: 2, name: 'Married' },
  { id: 3, name: 'Divorced' },
  { id: 4, name: 'Widow' },
];

export const GENDER_MALE = 'Mr';
export const GENDER_FEMALE = 'Mrs';
export const GENDER_NON_BINARY = 'Nonbinary';

export const fonction = [
  {
    id: 1,
    name: GENDER_MALE,
  },
  {
    id: 2,
    name: GENDER_FEMALE,
  },
  {
    id: 3,
    name: GENDER_NON_BINARY,
  },
];

// to remove
export const status = [
  {
    id: 0,
    name: 'sent',
  },
  {
    id: 1,
    name: 'read',
  },
];

export const Filale = [
  {
    id: 1,
    name: 'Saham Assurance Bénin',
  },
  {
    id: 2,
    name: 'Saham Assurance Guinée',
  },
  {
    id: 3,
    name: 'Saham Assurance Life Cameroun',
  },
];

export const langue = [
  {
    id: 1,
    name: 'Francais',
  },
  {
    id: 12,
    name: 'Anglais',
  },
];

export const certificationsData = [{ id: 1, name: 'Other' }];
