import * as React from 'react';

const PenEditIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.55147 14.625H14.114M12.1838 6.90441L14.5 4.58824L11.4118 1.5L9.09559 3.81618M12.1838 6.90441L4.46324 14.625H1.375V11.5368L9.09559 3.81618M12.1838 6.90441L9.09559 3.81618"
        // stroke="#808794"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PenEditIcon;
