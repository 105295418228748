import React from 'react';
import { useField } from 'formik';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Input = ({ icon, width, onChange, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Container width={width} {...props}>
      {icon}
      <input autoComplete="off" 
        {...field} 
        {...props} 
        onChange= {(e) => {field.onChange(e); onChange(e)}}  
      />
      {meta.touched && meta.error ? (
        <span className="error">{meta.error}*</span>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width : 'none')};
  svg {
    position: absolute;
    top: 13px;
    right: 14px;
    fill: rgba(34, 35, 40, 0.38);
    cursor: pointer;
  }

  input {
    height: 48px;
    width: 100%;
    background: #f6f8fa;
    border-radius: 4px;
    ${({ outline }) =>
      outline
        ? css`
            border: solid 1px #e0e3e8 !important;
          `
        : css`
            border: none;
          `};
    outline: none;
    padding: 15px 0 14px 18px;
  }

  .error {
    color: red;
  }
`;

Input.defaultProps = {
  icon: [],
};

Input.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default Input;
