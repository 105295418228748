import { combineReducers } from 'redux-immutable';

import createConsultantReducer from 'containers/CreateProfile/reducer';
import DashboardReducer from 'containers/DashboardPage/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import createLoginReducer from 'containers/HomePage/reducer';
import createCompetencesReducer from 'containers/table/reducer';
import careerMonitoringReducer from 'containers/DetailsPage/career-monitoring/reducer';
import createKpiReducer from 'containers/Reporting/reducer';
import {
  createCountriesReducer,
  createActivitiesReducer,
  createNotificationsReducer,
  createResumesReducer,
} from 'store/reducers';

// inject app reducers
const appReducers = () =>
  combineReducers({
    intl: languageProviderReducer,
    consultants: createConsultantReducer,
    dashboard: DashboardReducer,
    login: createLoginReducer,
    competences: createCompetencesReducer,
    countries: createCountriesReducer,
    activities: createActivitiesReducer,
    notifications: createNotificationsReducer,
    resumes: createResumesReducer,
    careerMonitoring: careerMonitoringReducer,
    kpis: createKpiReducer,
  });

export default appReducers;
