import React, { useState } from 'react';

import { Title, BorderBottom, NoNotif, SwitchContainer } from './styles';
import { useIntl } from 'react-intl';
import NotificationCard from 'utils/focus-ui-kit/NotificationCard';
import { Box, Paper, Switch } from '@material-ui/core';

const Notifications = ({ notifications }) => {
  const intl = useIntl();

  const [showUnreadOnly, setShowUnreadOnly] = useState(false);

  return (
    <Paper variant="elevation" elevation={24}>
      <Title>{intl.formatMessage({ id: 'notifications' })}</Title>
      <SwitchContainer>
        <Switch
          color="primary"
          onChange={(event) => setShowUnreadOnly(event.target.checked)}
        />
        {intl.formatMessage({ id: 'show_unread_only' })}
      </SwitchContainer>
      <BorderBottom />
      <Box sx={{ maxHeight: '400px', width: '621px', overflow: 'auto' }}>
        {!showUnreadOnly ? (
          notifications.length > 0 ? (
            notifications.map((notification) => (
              <NotificationCard notification={notification} />
            ))
          ) : (
            <NoNotif>{intl.formatMessage({ id: 'no_notification' })}</NoNotif>
          )
        ) : notifications.filter((notification) => notification.read === false)
            .length > 0 ? (
          notifications.map((notification) => {
            if (!notification.read)
              return <NotificationCard notification={notification} />;
          })
        ) : (
          <NoNotif>
            {intl.formatMessage({ id: 'no_unread_notification' })}
          </NoNotif>
        )}
      </Box>
    </Paper>
  );
};

export default Notifications;
