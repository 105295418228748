import { translate } from 'containers/services/translate';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import messages from 'utils/focus-ui-kit/StatisticCard/messages';
import StaticItem from 'utils/focus-ui-kit/StatisticCard/StaticItem';

const Card = (props) => {
  const {
    icon,
    title,
    valide,
    needAction,
    pending,
    init,
    handlefilter,
    valideStatus,
    needActionStatus,
    pendingSatus,
  } = props;
  const {
    StaticCardneedAction,
    StaticCardPending,
    StaticCardValide,
  } = messages;

  return (
    <CardContainer {...props}>
      <TopSection>
        <IconContainer {...props}>{icon}</IconContainer>
        <h3>{title}</h3>
      </TopSection>
      <Content>
        <StaticItem
          handlefilter={
            /* istanbul ignore next */
            () => handlefilter(needActionStatus)
          }
          title={translate(StaticCardneedAction, props)}
          count={needAction}
          to="/delegation_pouvoirs"
          init={init}
        />
        <StaticItem
          handlefilter={
            /* istanbul ignore next */
            () => handlefilter(pendingSatus)
          }
          title={translate(StaticCardPending, props)}
          count={pending}
        />
        <StaticItem
          handlefilter={
            /* istanbul ignore next */
            () => handlefilter(valideStatus)
          }
          title={translate(StaticCardValide, props)}
          count={valide}
        />
      </Content>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  height: 184px;
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  background-color: #ffffff;
  color: ${(props) => (props.init ? '#4DA1FF' : '#63B545')};
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;

  h3 {
    margin-left: 14px;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 23px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 59px;
  background-color: ${(props) => (props.init ? '#F0F2F7' : '#DEF5D6')};
  border-radius: 16px;

  svg {
    font-size: 2rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;
`;

Card.defaultProps = {
  icon: [],
  title: '',
  init: false,
};

Card.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  title: PropTypes.string,
  valide: PropTypes.number.isRequired,
  needAction: PropTypes.number.isRequired,
  pending: PropTypes.number.isRequired,
  init: PropTypes.bool,
  handlefilter: PropTypes.func.isRequired,
  valideStatus: PropTypes.objectOf(PropTypes.string).isRequired,
  needActionStatus: PropTypes.objectOf(PropTypes.string).isRequired,
  pendingSatus: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default injectIntl(Card);
