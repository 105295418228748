import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#0176BA',
  },
  circle: {
    width: 22,
    height: 22,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#0176BA',
    zIndex: 1,
    width: 22,
    height: 22,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
};

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#0176BA',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#0176BA',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const StepperComponent = ({ activeStep, steps }) => (
  <Stepper
    alternativeLabel
    activeStep={activeStep}
    connector={<QontoConnector />}
  >
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
);

StepperComponent.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default StepperComponent;
