import React from 'react';

const WordIcon = ({ fill }) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.125 2.875H6.70833C6.17885 2.875 5.75 3.30385 5.75 3.83333V7.18894H21.0833V3.83333C21.0833 3.30385 20.6545 2.875 20.125 2.875Z"
      fill="url(#paint0_linear_3059_1764)"
    />
    <path
      d="M5.75 15.8384V19.1667C5.75 19.6961 6.17885 20.125 6.70833 20.125H20.125C20.6545 20.125 21.0833 19.6961 21.0833 19.1667V15.8384H5.75Z"
      fill="url(#paint1_linear_3059_1764)"
    />
    <path
      d="M5.75 7.18896H21.0833V11.5024H5.75V7.18896Z"
      fill="url(#paint2_linear_3059_1764)"
    />
    <path
      d="M5.75 11.5024H21.0833V15.8389H5.75V11.5024Z"
      fill="url(#paint3_linear_3059_1764)"
    />
    <path
      opacity="0.05"
      d="M10.6945 6.229H5.75V17.729H10.6945C11.6687 17.729 12.4583 16.9393 12.4583 15.9652V7.99282C12.4583 7.01867 11.6687 6.229 10.6945 6.229Z"
      fill="black"
    />
    <path
      opacity="0.07"
      d="M10.6437 17.2499H5.75V6.38867H10.6437C11.4698 6.38867 12.1392 7.05807 12.1392 7.88415V15.7545C12.1387 16.5805 11.4693 17.2499 10.6437 17.2499Z"
      fill="black"
    />
    <path
      opacity="0.09"
      d="M10.5925 16.7709H5.75V6.54883H10.5925C11.27 6.54883 11.8191 7.09795 11.8191 7.77549V15.5442C11.8196 16.2218 11.27 16.7709 10.5925 16.7709Z"
      fill="black"
    />
    <path
      d="M10.541 16.2918H2.87435C2.34487 16.2918 1.91602 15.863 1.91602 15.3335V7.66683C1.91602 7.13735 2.34487 6.7085 2.87435 6.7085H10.541C11.0705 6.7085 11.4993 7.13735 11.4993 7.66683V15.3335C11.4993 15.863 11.0705 16.2918 10.541 16.2918Z"
      fill="url(#paint4_linear_3059_1764)"
    />
    <path
      d="M8.8189 9.104L8.07811 12.5847L7.2573 9.104H6.20936L5.36267 12.6925L4.59936 9.104H3.66211L4.78528 13.8957H5.91228L6.73309 10.2114L7.55438 13.8957H8.63298L9.75615 9.104H8.8189Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3059_1764"
        x1="13.4167"
        y1="7.17121"
        x2="13.4167"
        y2="3.09062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#42A3F2" />
        <stop offset="1" stop-color="#42A4EB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3059_1764"
        x1="13.4167"
        y1="20.125"
        x2="13.4167"
        y2="15.8384"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#11408A" />
        <stop offset="1" stop-color="#103F8F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3059_1764"
        x1="13.4167"
        y1="-7.40789"
        x2="13.4167"
        y2="-7.43711"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3079D6" />
        <stop offset="1" stop-color="#297CD2" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3059_1764"
        x1="5.75"
        y1="13.6707"
        x2="21.0833"
        y2="13.6707"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1D59B3" />
        <stop offset="1" stop-color="#195BBC" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3059_1764"
        x1="2.27252"
        y1="7.065"
        x2="11.2569"
        y2="16.0489"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#256AC2" />
        <stop offset="1" stop-color="#1247AD" />
      </linearGradient>
    </defs>
  </svg>
);

export default WordIcon;
