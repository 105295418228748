import * as React from 'react';

const FrFLag = () => {
  return (
    <svg
      width="20px"
      height="14px"
      viewBox="0 0 20 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>9EEF0857-8BE2-4247-8E5D-E5F7A5812DB0</title>
      <g
        id="Validé"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="HP---DG---choix-de-langue"
          transform="translate(-1476.000000, -89.000000)"
        >
          <g id="Group-31" transform="translate(1278.000000, 66.000000)">
            <image
              id="Bitmap"
              x="198"
              y="23"
              width="20"
              height="14"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAcCAYAAAATFf3WAAAABGdBTUEAALGOfPtRkwAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKKADAAQAAAABAAAAHAAAAAAuR3jqAAAAe0lEQVRYCe3SwQ1AMACF4UfqwBoSY9jAANZxdRaTOJlLiDSpiwEqv+PruT/Nl1eoXZI+nKYOWqde49CpCmVeGaPubdcxzUrnlde8tzL/8Ombv172AymnBS1IBWjvDVqQCtDeG7QgFaC9N2hBKkB7b9CCVID23qAFqQDtH96/DYb1EaoBAAAAAElFTkSuQmCC"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FrFLag;
