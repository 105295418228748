import styled from 'styled-components';

export const Image = styled.img`
  width: ${({ width }) => (width ? width : '72px')};
  height: ${({ height }) => (height ? height : '36px')};
  margin-right: ${({ noText }) => (noText ? '0px' : '8px')};
  cursor: pointer;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;

  .regular {
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e3f42;
  }

  .bold {
    font-family: Rubik;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e3f42;
  }
`;
