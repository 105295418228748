import React from 'react';

const ImageIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.4364 12.7315C16.7464 12.7315 16.1864 12.1715 16.1864 11.4815C16.1864 10.7915 16.7464 10.2315 17.4364 10.2315C18.1264 10.2315 18.6864 10.7915 18.6864 11.4815C18.6864 12.1715 18.1264 12.7315 17.4364 12.7315ZM3.97237 4.95868H17.9806C18.5376 4.95868 18.9917 4.50439 18.9947 3.95639L18.9945 4.00229C18.9975 3.44874 18.5453 3 18 3H2.99996C2.4477 3 2 3.44749 2 3.9985V15.0015C2 15.553 2.44386 16 3 16C3.55228 16 3.99888 15.5535 3.9975 15.0007L3.97237 4.95868Z"
      fill="#CE2783"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.99996 7C6.4477 7 6 7.44749 6 7.9985V19.0015C6 19.553 6.45471 20 6.99996 20H22C22.5523 20 23 19.5525 23 19.0015V7.9985C23 7.44704 22.5453 7 22 7H6.99996ZM21 17.05C20.1 16.52 19.9301 14.9646 18.5501 15.0146C17.0801 15.0646 17.06 17.05 15.59 17.05C14.13 17.05 12.86 14.01 11.39 14.05C9.87 14.09 8.81 15.89 8 16.9V9H21V17.05Z"
      fill="#CE2783"
    />
    <path
      opacity="0.1"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 17.05C20.1 16.52 19.9301 14.9646 18.5501 15.0146C17.0801 15.0646 17.06 17.05 15.59 17.05C14.13 17.05 12.86 14.01 11.39 14.05C9.87 14.09 8.81 15.89 8 16.9V9H21V17.05Z"
      fill="#CE2783"
    />
  </svg>
);

export default ImageIcon;
