import React, { useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

let modalRoot = document.getElementById('modal');
if (!modalRoot) {
  modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'modal');
  document.body.appendChild(modalRoot);
}

const Content = ({ notify, checked, setChecked, custom, children }) => (
  <>
    <ModalOverlay />
    <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
      <ModalContainer>
        {custom && (
          <CustomHeader>
            <span>{custom}</span>
          </CustomHeader>
        )}
        <Container>{children}</Container>
        {notify && (
          <NotifContainer>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => setChecked(e.target.checked)}
                  checked={checked}
                  color="primary"
                />
              }
              label="Voulez-vous notifier l'ensemble des détenteurs de délégations concernés par l'onglet de la mise à jour"
              style={{ width: '100%' }}
            />
          </NotifContainer>
        )}
      </ModalContainer>
    </ModalWrapper>
  </>
);

function Modal({ isShowing, notify, checked, setChecked, custom, children }) {
  const el = useRef(document.createElement('div'));
  useLayoutEffect(() => {
    const currentEl = el.current;
    modalRoot.appendChild(currentEl);
    return () => modalRoot.removeChild(currentEl);
  }, []);
  return isShowing
    ? ReactDOM.createPortal(
        <Content
          isShowing={isShowing}
          notify={notify}
          checked={checked}
          setChecked={setChecked}
          custom={custom}
        >
          {children}
        </Content>,
        el.current,
      )
    : null;
}

const CustomHeader = styled.div`
  border-bottom: 1px solid rgba(151, 151, 151, 0.23);
  padding: 18px 24px;

  span {
    color: #485563;
    font-family: Rubik;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1140;
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.3;
`;

const ModalWrapper = styled.div`
  /* width: 100%; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1150;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
`;

const ModalContainer = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  border-radius: 3px;
`;

const Container = styled.div`
  padding: 23px 23px 23px 23px;
`;

const NotifContainer = styled.div`
  border-top: 1px solid rgba(151, 151, 151, 0.23);
  padding: 25px 0;

  label {
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .MuiTypography-root {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }
`;

Content.defaultProps = {
  notify: false,
  custom: false,
  checked: false,
  setChecked: () => {},
};

Content.propTypes = {
  notify: PropTypes.bool,
  custom: PropTypes.bool,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Modal;
