import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILED,
  GET_NOTIFICATIONS_SUCCES,
  SEND_NOTIFICATION,
  SEND_NOTIFICATION_FAILED,
  SEND_NOTIFICATION_SUCCES,
  UPDATE_LAST_REMINDER,
  UPDATE_LAST_REMINDER_FAILED,
  UPDATE_LAST_REMINDER_SUCCES,
  UPDATE_READ,
  UPDATE_READ_FAILED,
  UPDATE_READ_SUCCES,
} from 'shared';

import { all, call, put, takeLatest } from 'redux-saga/effects';
import Axios from 'utils/Axios';

const { REACT_APP_BASEURL } = process.env;

export const getNotificationsApi = (values) => {
  return Axios.get(
    `${REACT_APP_BASEURL}/notification/all/${values.email}?language=${values.locale}`,
  );
};

export function* getNotificationsSaga(values) {
  try {
    const res = yield call(getNotificationsApi, values);
    if (res.status === 200) {
      yield put({
        type: GET_NOTIFICATIONS_SUCCES,
        notifications: res.data,
      });
    } else {
      yield put({
        type: GET_NOTIFICATIONS_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_NOTIFICATIONS_FAILED,
    });
  }
}

export function* watchGetNotifications() {
  yield takeLatest(GET_NOTIFICATIONS, getNotificationsSaga);
}

export const updateReadApi = (values) => {
  return Axios.put(
    `${REACT_APP_BASEURL}/notification/update/${values._id}`,
    values,
  );
};

export function* updateReadSaga({ values }) {
  try {
    const res = yield call(updateReadApi, values);

    if (res.status === 200) {
      yield put({
        type: UPDATE_READ_SUCCES,
        notifications: res.data.notifications,
      });
    } else {
      yield put({
        type: UPDATE_READ_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_READ_FAILED,
    });
  }
}

export function* watchUpdateRead() {
  yield takeLatest(UPDATE_READ, updateReadSaga);
}

// send new notification
export const sendNotificationAPI = (values) => {
  return Axios.post(`${REACT_APP_BASEURL}/notification/add`, values);
};

export function* sendNotificationSaga({ values }) {
  try {
    const res = yield call(sendNotificationAPI, values);
    if (res.status === 200) {
      yield put({
        type: SEND_NOTIFICATION_SUCCES,
      });
    } else {
      yield put({
        type: SEND_NOTIFICATION_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: SEND_NOTIFICATION_FAILED,
    });
  }
}

export function* watchsendNotificationSaga() {
  yield takeLatest(SEND_NOTIFICATION, sendNotificationSaga);
}

// update consultant last reminder date
export const updateLastReminderAPI = (id) => {
  return Axios.put(`${REACT_APP_BASEURL}/consultant/updatelastreminder/${id}`);
};

export function* updateLastReminderSaga({ id }) {
  try {
    const res = yield call(updateLastReminderAPI, id);
    if (res.status === 200) {
      yield put({
        type: UPDATE_LAST_REMINDER_SUCCES,
      });
    } else {
      yield put({
        type: UPDATE_LAST_REMINDER_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_LAST_REMINDER_FAILED,
    });
  }
}

export function* watchUpdateLastReminderSaga() {
  yield takeLatest(UPDATE_LAST_REMINDER, updateLastReminderSaga);
}

export function* rootNotificationsSaga() {
  yield all([
    watchGetNotifications(),
    watchUpdateRead(),
    watchUpdateLastReminderSaga(),
    watchsendNotificationSaga(),
  ]);
}
