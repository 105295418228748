import {
  GET_ALL_COMPETENCES,
  GET_ALL_COMPETENCES_FAILED,
  GET_ALL_COMPETENCES_SUCCES,
} from 'containers/table/constants';

import { all, call, put, takeLatest } from 'redux-saga/effects';
import Axios from 'utils/Axios';

const { REACT_APP_BASEURL } = process.env;

export const getCompetencesApi = () => {
  return Axios.get(`${REACT_APP_BASEURL}/competence/allCompetences`);
};

export function* getCompetencesSaga() {
  try {
    const res = yield call(getCompetencesApi);

    if (res.status === 200) {
      const competenceNames = res.data.allCompetences.map(
        (competence) => competence.name,
      );

      yield put({
        type: GET_ALL_COMPETENCES_SUCCES,
        allCompetences: competenceNames,
      });
    } else {
      yield put({
        type: GET_ALL_COMPETENCES_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_ALL_COMPETENCES_FAILED,
    });
  }
}

export function* watchGetCompetences() {
  yield takeLatest(GET_ALL_COMPETENCES, getCompetencesSaga);
}

export default function* rootDelegationSaga() {
  yield all([watchGetCompetences()]);
}
