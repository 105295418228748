export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES';
export const GET_ALL_COUNTRIES_FAILED = 'GET_ALL_COUNTRIES_FAILED';
export const GET_ALL_COUNTRIES_SUCCES = 'GET_ALL_COUNTRIES_SUCCES';
export const GET_ALL_ACTIVITIES = 'GET_ALL_ACTIVITIES';
export const GET_ALL_ACTIVITIES_FAILED = 'GET_ALL_ACTIVITIES_FAILED';
export const GET_ALL_ACTIVITIES_SUCCES = 'GET_ALL_ACTIVITIES_SUCCES';
export const GET_ACTIVITY = 'GET_ACTIVITY';
export const GET_ACTIVITY_FAILED = 'GET_ACTIVITY_FAILED';
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED';
export const GET_NOTIFICATIONS_SUCCES = 'GET_NOTIFICATIONS_SUCCES';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const SEND_NOTIFICATION_FAILED = 'SEND_NOTIFICATION_FAILED';
export const SEND_NOTIFICATION_SUCCES = 'SEND_NOTIFICATION_SUCCES';
export const UPDATE_READ = 'UPDATE_READ';
export const UPDATE_READ_FAILED = 'UPDATE_READ_FAILED';
export const UPDATE_READ_SUCCES = 'UPDATE_READ_SUCCES';
export const UPDATE_LAST_REMINDER = 'UPDATE_LAST_REMINDER';
export const UPDATE_LAST_REMINDER_FAILED = 'UPDATE_LAST_REMINDER_FAILES';
export const UPDATE_LAST_REMINDER_SUCCES = 'UPDATE_LAST_REMINDER_SUCCES';
export const SAVE_RESUME = 'SAVE_RESUME';
export const SAVE_RESUME_SUCCES = 'SAVE_RESUME_SUCCES';
export const SAVE_RESUME_FAILED = 'SAVE_RESUME_FAILED';
export const DELETE_RESUME = 'DELETE_RESUME';
export const DELETE_RESUME_SUCCES = 'DELETE_RESUME_SUCCES';
export const DELETE_RESUME_FAILED = 'DELETE_RESUME_FAILED';
export const GET_RESUMES = 'GET_RESUMES';
export const GET_RESUMES_SUCCES = 'GET_RESUMES_SUCCES';
export const GET_RESUMES_FAILED = 'GET_RESUMES_FAILED';
export const COUNTRIES = 'countries';
export const ACTIVITIES = 'activities';
export const ACTIVITY = 'Activity';
export const MAX_LEN = 800;
export const NB_DECIMALS = 2;
export const NB_MOUNTHS = 12;
export const PROFILE_PATH = '/consultant-profile/';
export const CODIFICATION_GRADE = 'GRADE';
export const NEWEST = 'Newest';
export const OLDEST = 'Oldest';
export const COMMERCIAL = 'commercial';
export const INTERCONTRAT = 'INTERCONTRACT';
export const MISSION = 'MISSION';
export const PRESENTATION = 'PRESENTATION';
export const POSITIONNING = 'POSITIONNING';
export const PENDING = 'Pending';
export const ENROLLED = 'Enrolled';
export const COMPLETED = 'completed';
export const CANCELLED = 'Cancelled';
export const GO_MISSION = 'Go mission';
export const GO_PRESENTATION = 'Go presentation';
export const NO_GO = 'No go';
export const DEPARTURE = 'DEPARTURE';
export const DATE_PLACE_HOLDER = 'MM/DD/YYYY';
export const DATE_FORMAT = 'DD MMM YYYY';
export const intercontrat = 'intercontrat';
export const DEFAULT_OLD_DATE = new Date('2000-01-01T00:00:00');
export const BLUE_MUI = '#0054DF';
export const NOTIFICATION_TYPE_ALL = 'ALL';
export const NOTIFICATION_TYPE_MAIL = 'MAIL';
export const NOTIFICATION_TYPE_WEB = 'WEB';
export const UPDATE_PROFILE_REMINDER = 'UPDATE_PROFILE_REMINDER';
export const POSTPONED_MAIL = 'POSTPONED';
export const IMMEDIATE_MAIL = 'IMMEDIATE';
export const CERTIFICATION_MAX_SIZE = 20000000;
export const FREELANCER = 'Freelancer';
export const OPEN_ENDED_CONTRACT = 'Open-ended contract';
export const FIXED_TERM_CONTRACT = 'Fixed-Term contract';
export const CV_FONT_SIZE = 11;
