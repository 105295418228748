import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import createSagaMiddleware from 'redux-saga';
import appReducer from 'reducers';
import globalSagas from 'sagas';
import get from 'lodash/get';
import { createBrowserHistory } from 'history';

const ReduxDevTools = get(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__');
export const history = createBrowserHistory();

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = sagaMiddleware;

  const enhancers = applyMiddleware(middlewares);

  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    typeof ReduxDevTools === 'function'
      ? ReduxDevTools
      : compose;

  const store = createStore(
    appReducer(),
    fromJS(initialState),
    composeEnhancers(enhancers),
  );

  //   Saga injection
  if (globalSagas instanceof Array) {
    globalSagas.forEach((saga) => sagaMiddleware.run(saga));
  }

  return store;
}
