import React from "react";

const Delete = props => (
  <svg width={21} height={21} {...props}>
    <path
      fill="#DF0000"
      d="M10.5 9.793l2.646-2.647a.5.5 0 01.708.708L11.207 10.5l2.647 2.646a.5.5 0 01-.708.708L10.5 11.207l-2.646 2.647a.5.5 0 11-.708-.708L9.793 10.5 7.146 7.854a.5.5 0 01.708-.708L10.5 9.793zM10.5 21C4.701 21 0 16.299 0 10.5S4.701 0 10.5 0 21 4.701 21 10.5 16.299 21 10.5 21zm0-1a9.5 9.5 0 100-19 9.5 9.5 0 000 19z"
    />
  </svg>
);

export default Delete;
