import styled from 'styled-components'

export const StyledCheckbox = styled.div`
  & .MuiCheckbox-root {
    color: #0176ba ;
  }

  & .Mui-checked {
    color: #0176ba ;
  }

  & .MuiCheckbox-root:hover {
    background-color: rgba(1,118,186,0.1);
  }
`;
