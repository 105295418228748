import { defineMessages } from 'react-intl';

export default defineMessages({
  sideNavHome: {
    id: 'side_nav_home',
  },
  sideNavDetails: {
    id: 'side_nav_details',
  },
  sideNavElements: {
    id: 'side_nav_elements',
  },
  sideNavDelegation: {
    id: 'side_nav_delegation',
  },
  sideNavDashboard: {
    id: 'side_nav_dashboard',
  },
  sideNavMyDelegation: {
    id: 'side_nav_my_delegation',
  },
});
