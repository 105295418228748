import {
  DEFAULT_LOCALE,
  SET_LOCALE,
} from 'containers/LanguageProvider/constants';

const initialState = {
  locale: ['fr', 'en'].includes(DEFAULT_LOCALE) ? DEFAULT_LOCALE : 'en',
};

const languageProviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCALE:
      return { ...state, locale: action.locale };
    default:
      return state;
  }
};

export default languageProviderReducer;
