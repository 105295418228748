import * as React from 'react';

const DetailIcon = (props) => {
  return (
    <svg width={17} height={17} viewBox="0 0 17 17">
      <path
        d="M1.889 0A1.903 1.903 0 000 1.889V15.11C0 16.143.857 17 1.889 17H15.11A1.903 1.903 0 0017 15.111V1.89A1.903 1.903 0 0015.111 0H1.89zM2 2h13v2H2V2zm0 4h13v9H2V6zm2 2v2h2V8H4zm3 0v2h6V8H7zm-3 3v2h2v-2H4zm3 0v2h6v-2H7z"
        {...props}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default DetailIcon;
