import * as React from 'react';

const ExcelIcon = (props) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8965 2.875H7.54465C7.08321 2.875 6.70898 3.24923 6.70898 3.71067V7.18894H13.8965V2.875Z"
        fill="#169154"
      />
      <path
        d="M6.70898 15.8384V19.2893C6.70898 19.7508 7.08321 20.125 7.54417 20.125H13.8965V15.8384H6.70898Z"
        fill="#18482A"
      />
      <path
        d="M6.70898 7.18896H13.8965V11.5024H6.70898V7.18896Z"
        fill="#0C8045"
      />
      <path
        d="M6.70898 11.5024H13.8965V15.8389H6.70898V11.5024Z"
        fill="#17472A"
      />
      <path
        d="M20.2483 2.875H13.8965V7.18894H21.084V3.71067C21.084 3.24923 20.7098 2.875 20.2483 2.875Z"
        fill="#29C27F"
      />
      <path
        d="M13.8965 15.8384V20.125H20.2488C20.7098 20.125 21.084 19.7508 21.084 19.2898V15.8389L13.8965 15.8384Z"
        fill="#27663F"
      />
      <path
        d="M13.8965 7.18896H21.084V11.5024H13.8965V7.18896Z"
        fill="#19AC65"
      />
      <path
        d="M13.8965 11.5024H21.084V15.8389H13.8965V11.5024Z"
        fill="#129652"
      />
      <path
        d="M10.6939 16.2918H2.72149C2.27683 16.2918 1.91602 15.931 1.91602 15.4864V7.51398C1.91602 7.06931 2.27683 6.7085 2.72149 6.7085H10.6939C11.1385 6.7085 11.4993 7.06931 11.4993 7.51398V15.4864C11.4993 15.931 11.1385 16.2918 10.6939 16.2918Z"
        fill="#0C7238"
      />
      <path
        d="M4.6991 9.104H5.84239L6.77006 10.9028L7.75043 9.104H8.81849L7.34697 11.4998L8.85204 13.8957H7.72551L6.71447 12.0125L5.70774 13.8957H4.56445L6.09443 11.4912L4.6991 9.104Z"
        fill="white"
      />
    </svg>
  );
};

export default ExcelIcon;
