import {
  POST_CONSULTANT,
  POST_CONSULTANT_FAILED,
  POST_CONSULTANT_SUCCES,
  UPDATE_CONSULTANT,
  UPDATE_CONSULTANT_FAILED,
  UPDATE_CONSULTANT_SUCCES,
  //all jobs
  GET_ALL_JOBS,
  GET_ALL_JOBS_FAILED,
  GET_ALL_JOBS_SUCCES,
  GET_CERTIFICATION_FILE,
  UPDATE_ARCHIVE,
  UPDATE_ARCHIVE_SUCCES,
  UPDATE_ARCHIVE_FAILED,
  UPDATE_FOLLOW,
  UPDATE_FOLLOW_SUCCESS,
  UPDATE_FOLLOW_FAILED,
} from 'containers/CreateProfile/constants';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import Axios from 'utils/Axios';
import { toast } from 'react-toastify';

const { REACT_APP_BASEURL } = process.env;

export const postConsultantAPI = (values) => {
  return Axios.post(`${REACT_APP_BASEURL}/consultant/add`, values);
};

export function* postConsultantSaga({ enableModal }) {
  try {
    const values = yield select((state) => state.get('consultants').form);
    const res = yield call(postConsultantAPI, values);

    if (res.status === 201) {
      yield put({
        type: POST_CONSULTANT_SUCCES,
        status: 'succes',
        created_consultant_id: res.data.id,
        enableModal: enableModal,
      });
    } else {
      yield put({
        type: POST_CONSULTANT_FAILED,
        status: 'failed',
      });
    }
  } catch (err) {
    yield put({
      type: POST_CONSULTANT_FAILED,
      status: 'failed',
    });
  }
}

export function* watchPostConsultant() {
  yield takeLatest(POST_CONSULTANT, postConsultantSaga);
}

// update consultant
export const updateConsultantAPI = ({ formData, id }) => {
  return Axios.put(
    `${REACT_APP_BASEURL}/consultant/updateById/${id}`,
    formData,
  );
};

export function* updateConsultantSaga({ id, enableModal }) {
  try {
    const values = yield select((state) => state.get('consultants').form);
    const certificationsFiles = yield select(
      (state) => state.get('consultants').certificationsFiles,
    );

    const diplomaFiles = yield select(
      (state) => state.get('consultants').diplomaFiles,
    );

    const formData = new FormData();

    formData.append('values', JSON.stringify(values));
    certificationsFiles.map((file) => formData.append('file', file));
    diplomaFiles.map((file) => formData.append('diploma', file));

    const res = yield call(updateConsultantAPI, { formData, id });
    if (res.status === 200) {
      yield put({
        type: UPDATE_CONSULTANT_SUCCES,
        status: 'succes',
        updated_consultant_id: res.data._id,
        values: res.data,
        enableModal: enableModal,
      });
      toast.success('Saved successfully');
    } else {
      yield put({
        type: UPDATE_CONSULTANT_FAILED,
        status: 'failed',
      });
      toast.error('Error savings');
    }
  } catch (err) {
    yield put({
      type: UPDATE_CONSULTANT_FAILED,
      status: 'failed',
    });
  }
}

export function* watchUpdateConsultantSaga() {
  yield takeLatest(UPDATE_CONSULTANT, updateConsultantSaga);
}

export const getCertificationFileAPI = (key) => {
  const encodedKey = encodeURIComponent(key);
  return Axios.get(
    `${REACT_APP_BASEURL}/consultant/downloadCertification/${encodedKey}`,
    {
      responseType: 'arraybuffer',
    },
  );
};

export function* getCertificationFileSaga({ key }) {
  try {
    const res = yield call(getCertificationFileAPI, key);

    if (res.status === 200) {
      const fileExtension = key.split('/').pop().split('.').pop();
      const mimeTypes = {
        png: 'image/png',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        pdf: 'application/pdf',
      };
      const mimeType =
        mimeTypes[fileExtension.toLowerCase()] || 'application/octet-stream';

      const blob = new Blob([res.data], { type: mimeType });
      const imageUrl = URL.createObjectURL(blob);
      window.open(imageUrl, '_blank');
    }
  } catch (err) {}
}

export function* watchGetCertificationFileSaga() {
  yield takeLatest(GET_CERTIFICATION_FILE, getCertificationFileSaga);
}

export const getAllJobsAPI = () => {
  return Axios.get(`${REACT_APP_BASEURL}/jobs/all`);
};

export function* getAllJobsSaga() {
  try {
    const res = yield call(getAllJobsAPI);

    if (res.status === 200) {
      yield put({
        type: GET_ALL_JOBS_SUCCES,
        allJobs: res.data.allJobs,
      });
    } else {
      yield put({
        type: GET_ALL_JOBS_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_ALL_JOBS_FAILED,
    });
  }
}

//archiver profile
export const updateArchiveApi = (values) => {
  return Axios.put(`${REACT_APP_BASEURL}/consultant/archive/${values.id}`, {
    archive: values.archive,
  });
};

export function* updateArchiveSaga({ values }) {
  try {
    const res = yield call(updateArchiveApi, values);
    if (res.status === 200) {
      yield put({
        type: UPDATE_ARCHIVE_SUCCES,
        archive: res.data.archive,
      });
    } else {
      yield put({
        type: UPDATE_ARCHIVE_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_ARCHIVE_FAILED,
    });
  }
}

export function* watchUpdateArchive() {
  yield takeLatest(UPDATE_ARCHIVE, updateArchiveSaga);
}

/// Suivre un consultant
export const updateFollowApi = (values) => {
  return Axios.put(
    `${REACT_APP_BASEURL}/consultant/follow/${values.consultantId}`,
    {
      businessId: values.businessId,
      isFollowed: values.follow,
    },
  );
};

export function* updateFollowSaga({ values }) {
  try {
    const res = yield call(updateFollowApi, values);
    if (res.status === 200) {
      yield put({
        type: UPDATE_FOLLOW_SUCCESS,
        consultant: res.data,
      });
    } else {
      yield put({
        type: UPDATE_FOLLOW_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_FOLLOW_FAILED,
    });
  }
}

export function* watchUpdateFollow() {
  yield takeLatest(UPDATE_FOLLOW, updateFollowSaga);
}

export function* watchGetAllJobsSaga() {
  yield takeLatest(GET_ALL_JOBS, getAllJobsSaga);
}

export default function* rootConsultantSaga() {
  yield all([
    watchPostConsultant(),
    watchUpdateConsultantSaga(),
    watchGetAllJobsSaga(),
    watchGetCertificationFileSaga(),
    watchUpdateArchive(),
    watchUpdateFollow(),
  ]);
}
