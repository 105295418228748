/* eslint-disable no-nested-ternary */
import { InputBase, NativeSelect } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { handleRoleLabel, handleStatusLabel } from 'utils/utils';

const BootstrapInput = withStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    color: 'black',
    width: '100%',
    height: 48,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    padding: '0px 26px 0px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(InputBase);

const Select = ({ options, placeholder, statusLabel, roleLabel, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Container>
      <NativeSelect {...field} {...props} input={<BootstrapInput />}>
        <option value="" disabled selected>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.id} value={option.name}>
            {statusLabel
              ? handleStatusLabel(option.name, props)
              : roleLabel
              ? handleRoleLabel(option.name, props)
              : option.name}
          </option>
        ))}
      </NativeSelect>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

Select.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  statusLabel: PropTypes.bool.isRequired,
  roleLabel: PropTypes.bool.isRequired,
};

export default injectIntl(Select);
