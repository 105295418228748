import {
  AccuielIcon,
  DashboardIcon,
  DelegIcon,
  DetailIcon,
  ElQuaIcon,
  ListIcon,
} from 'assets/svg';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

/* istanbul ignore next */
const handelIcon = (to, isActive) => {
  switch (to) {
    case '/delegation_pouvoirs':
      return <DetailIcon fill={isActive ? '#ffc912' : '#c1cab8'} />;
    case '/liste_utilisateurs':
      return <ListIcon fill={isActive ? '#ffc912' : '#c1cab8'} />;
    case '/details_pouvoirs':
      return <DelegIcon fill={isActive ? '#ffc912' : '#c1cab8'} />;
    case '/elements_qualitatifs':
      return <ElQuaIcon fill={isActive ? '#ffc912' : '#c1cab8'} />;
    case '/my_delegation':
      return <ElQuaIcon fill={isActive ? '#ffc912' : '#c1cab8'} />;
    case '/Dashboard':
      return <DashboardIcon fill={isActive ? '#ffc912' : '#c1cab8'} />;

    default:
      return <AccuielIcon fill={isActive ? '#ffc912' : '#c1cab8'} />;
  }
};

const MenuItem = ({ title, to, isCollapsed }) => {
  const history = useHistory();
  const location = useLocation();
  const isActive = location && location.pathname === to;

  /* istanbul ignore next */
  const goTo = () => {
    history.push(to);
  };

  return (
    <Item onClick={goTo}>
      <Span isActive={isActive} />
      <Content isActive={isActive}>
        {handelIcon(to, isActive)}
        <Title isCollapsed={isCollapsed} isActive={isActive}>
          {title}
        </Title>
      </Content>
    </Item>
  );
};

const Item = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 8px;
  position: relative;
`;

const Span = styled.div`
  background-color: ${(props) => (props.isActive ? '#ffc912' : '#003865')};
  width: 6px;
  margin-right: 8px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
`;

const Title = styled.div`
  transition: 0.5s;
  margin-left: ${(props) => (props.isCollapsed ? '25px' : '12px')};
  color: ${(props) => (props.isActive ? '#fff' : '#c1cab8')};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  flex: 1;
  background-color: ${(props) =>
    props.isActive ? 'rgba(32, 128, 188, 0.17)' : '#003865'};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
};

export default MenuItem;
