import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  width: 90%;
  height: ${({ isExpand }) => (isExpand ? 'auto' : '100px')};
  flex-direction: column;
  padding: 2px 16px 16px 16px;
  margin: 15px;
  border-radius: 8px;
  border: ${({ read }) => (read ? 'solid 2px #eef1f5' : `solid 2px`)};
  background-color: ${({ read }) => (read ? '#fff' : '#eef1f5')};
`;

export const MarkWrapper = styled.div`
  width: 0px;
  height: 0px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MarkText = styled.div`
  font-family: Rubik;
  text-decoration: underline;
  cursor: pointer;
`;

export const Title = styled.div`
  max-width: 75%;
  margin-bottom: 4px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
`;

export const Description = styled.div`
  margin-bottom: 10px;
  font-family: Rubik;
  overflow: hidden;
  white-space: ${({ isExpand }) => (isExpand ? 'normal' : 'nowrap')};
  text-overflow: ellipsis;
`;

export const Link = styled.div`
  font-family: Rubik;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
`;
