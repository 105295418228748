import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { NumberFormatCustom } from 'utils/focus-ui-kit';

const MaterialInput = (props) => {
  const [field, meta] = useField(props);
  const { masked, adornment, onChange } = props;

  return (
    <Container {...props}>
      <StyledTextField
        onChange={onChange}
        variant="outlined"
        {...field}
        {...props}
        InputProps={{
          ...(masked && { inputComponent: NumberFormatCustom, }),
          ...(adornment && { endAdornment: adornment, }),
        }}
      />
      {
        /* istanbul ignore next */
        meta.touched && meta.error ? (
          <div className="error" style={{ color: 'red' }}>
            {meta.error}
          </div>
        ) : null
      }
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) =>
    props.mgBottom ? `${props.mgBottom}px` : '56px'};
`;

const StyledTextField = styled(TextField)`
  width: 100% !important;
`;



MaterialInput.defaultProps = {
  masked: false,
};

MaterialInput.propTypes = {
  masked: PropTypes.bool,
};

export default MaterialInput;
