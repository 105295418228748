import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const MaterialCheckBox = ({ label, disabled, ...props }) => {
  const [field] = useField(props);

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          disabled={disabled}
          checked={field.value}
          color="primary"
        />
      }
      label={label}
      style={{ marginBottom: 68, width: '100%' }}
    />
  );
};

MaterialCheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default MaterialCheckBox;
