import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button, Modal } from 'utils/focus-ui-kit';

const LogOutModal = ({ isShowing, hide, handleLogOut, handleClose }) => {
  const intl = useIntl();
  if (isShowing) handleClose();
  return (
    <Modal isShowing={isShowing}>
      <Content>
        <Text>{intl.formatMessage({ id: 'logout_question' })}</Text>
      </Content>
      <ButtonSection>
        <Button
          textcolor="#0075D0"
          bgcolor="#fff"
          outlined
          width={257}
          onClick={() => hide()}
        >
          {intl.formatMessage({ id: 'cancel' })}
        </Button>
        <Button bgcolor="#0075C9" width={321} onClick={() => handleLogOut()}>
          {intl.formatMessage({ id: 'logout' })}
        </Button>
      </ButtonSection>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    fill: #0075d0;
    height: 88px;
    width: 88px;
  }
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.p`
  color: #222328;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 26px;
  margin-bottom: 96px;
`;

LogOutModal.propTypes = {
  hide: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
};

export default injectIntl(LogOutModal);
