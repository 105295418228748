import * as React from 'react';

const AccueilIcon = (props) => {
  return (
    <svg width={17} height={17} viewBox="0 0 17 17">
      <defs>
        <path
          d="M15.111 1.889v1.889h-3.778v-1.89h3.778zm-9.444 0v5.667H1.889V1.889h3.778zm9.444 7.555v5.667h-3.778V9.444h3.778zm-9.444 3.778v1.89H1.889v-1.89h3.778zM17 0H9.444v5.667H17V0zM7.556 0H0v9.444h7.556V0zM17 7.556H9.444V17H17V7.556zm-9.444 3.777H0V17h7.556v-5.667z"
          id="prefix__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g mask="url(#prefix__b)" {...props}>
          <path d="M-3-2h22v22H-3z" />
        </g>
      </g>
    </svg>
  );
};

export default AccueilIcon;
