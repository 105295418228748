import {
  GET_USER,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
} from 'containers/HomePage/constants';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Axios from 'utils/Axios';

const { REACT_APP_BASEURL } = process.env;

export const getUserAPI = (values) => {
  return axios.post(`${REACT_APP_BASEURL}/access/login`, {
    email: values.email,
    password: values.password,
  });
};

export function* getUserSaga(values) {
  try {
    const res = yield call(getUserAPI, values);

    if (res.status === 200) {
      yield put({
        type: GET_USER_SUCCESS,
        data: res,
      });
      localStorage.setItem('userId', res.data.id);
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('email', res.data.email);
      localStorage.setItem('firstName', res.data.firstName);
      localStorage.setItem('lastName', res.data.lastName);
      localStorage.setItem('country', res.data.country);
      localStorage.setItem('role', res.data.role);
      localStorage.setItem('gender', res.data.gender);
      localStorage.setItem('consultantId', res.data.consultantId);
      localStorage.setItem('subRole', res.data.subRole);
      localStorage.setItem('businessUnit', res.data.businessUnit);
      localStorage.setItem('scopeGeo', res.data.scopeGeo);
      if (
        values.history &&
        values.history.location &&
        values.history.location.pathname !== '/'
      ) {
        values.history.push('/');
      }
    } else {
      yield put({
        type: GET_USER_FAILED,
        message: res.data.message,
      });

      if (
        values.history &&
        values.history.location &&
        values.history.location.pathname !== '/login'
      ) {
        values.history.push('/login');
      }
    }
  } catch (err) {
    yield put({
      type: GET_USER_FAILED,
      message: err.response.data.message,
    });
    /* istanbul ignore next */
    // toast.warn(err.response.data.message);
  }
}

export function* watchGetUserSaga() {
  yield takeLatest(GET_USER, getUserSaga);
}

export const postForgotPasswordAPI = ({ values }) => {
  return Axios.post(`${REACT_APP_BASEURL}/user/reset`, { email: values.email });
};

export function* postForgotPasswordSaga(values) {
  try {
    const res = yield call(postForgotPasswordAPI, values);

    if (res.status === 201) {
      yield put({
        type: FORGOT_PASSWORD_SUCCESS,
        forgot_success: true,
        forgot_error: null,
        message: null,
      });
    } else {
      yield put({
        type: FORGOT_PASSWORD_FAILED,
        forgot_error: true,
        message: null,
      });
    }
  } catch (err) {
    yield put({
      type: FORGOT_PASSWORD_FAILED,
      forgot_error: true,
      message: err.response.data.message,
    });
  }
}

export function* watchPostForgotPasswordSaga() {
  yield takeLatest(FORGOT_PASSWORD, postForgotPasswordSaga);
}

export const resetPasswordAPI = (payload) => {
  return Axios.put(
    `${REACT_APP_BASEURL}/user/resetpass/${payload.id}`,
    payload.values,
  );
};

export function* resetPasswordSaga(values) {
  try {
    const res = yield call(resetPasswordAPI, values);

    if (res.status === 201) {
      yield put({
        type: RESET_PASSWORD_SUCCESS,
        updated: res.data.updated,
      });
    } else {
      yield put({
        type: RESET_PASSWORD_FAILED,
        updated: res.data.updated,
      });
    }
  } catch (err) {
    yield put({
      type: RESET_PASSWORD_FAILED,
    });
  }
}

export function* watchresetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
}

export default function* rootHomeSaga() {
  yield all([
    watchGetUserSaga(),
    watchPostForgotPasswordSaga(),
    watchresetPasswordSaga(),
  ]);
}
