import React from 'react';
import { Button as Btn } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = ({ startIcon, endIcon, children, ...rest }) => {
  return (
    <StyledBtn
      {...rest}
      disableElevation
      variant="contained"
      color="primary"
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {children}
    </StyledBtn>
  );
};

const StyledBtn = styled(Btn)`
  ${(props) => props.bgcolor && `background: ${props.bgcolor} !important;`}
  ${(props) => props.disabled && `background: #cccccc !important;`}
  ${(props) => (props.width ? `width: ${props.width}px;` : 'width: 100%;')}
  ${(props) => props.height && `height: ${props.height}px;`}
  ${(props) =>
    props.marginleft && `margin-left: ${props.marginleft}px !important;`}
  ${(props) =>
    props.marginright && `margin-right: ${props.marginright}px !important;`}
  ${(props) => props.textcolor && `color: ${props.textcolor} !important;`}
  ${(props) =>
    props.outlined && `border: 1px solid ${props.textcolor} !important;`}
  text-transform: ${(props) =>
    props.uppercase ? 'uppercase' : 'capitalize'} !important;

  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: ${({ alignSlef }) => alignSlef || 'center'};
  color: ${({ outline, underline, without, color }) =>
    outline ? '#0054df' : underline || without ? '#3e3f42' : 'white'};
  padding: ${({ small, without }) =>
    small ? '10px 16px' : without ? '0' : '12px 40px'};
  border: ${({ outline }) => (outline ? 'solid 2px #0054df' : 'none')};
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  font-family: ${({ Rubik }) => (Rubik ? 'Rubik' : 'Rubik')};
  font-size: 16px;
  font-weight: ${({ underline }) => (underline ? '400' : '700')};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  ${({ underline }) => underline && 'text-decoration: underline'};
  margin-right: ${({ marginRight }) => marginRight || 0};
  margin-top: ${({ marginTop }) => marginTop || 0};
`;

Button.defaultProps = {
  startIcon: [],
  endIcon: [],
};

Button.propTypes = {
  startIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  endIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Button;
