export const GET_INTERCONTRACT_QUANTITY_COUNTRIES =
  'GET INTERCONTRACT QUANTITY [GET_INTERCONTRACT_QUANTITY_COUNTRIES]';
export const GET_INTERCONTRACT_QUANTITY_COUNTRIES_SUCCES =
  'GET INTERCONTRACT QUANTITY SUCCES [GET_INTERCONTRACT_QUANTITY_COUNTRIES_SUCCES]';
export const GET_INTERCONTRACT_QUANTITY_COUNTRIES_FAILED =
  'GET INTERCONTRACT QUANTITY FAILED [GET_INTERCONTRACT_QUANTITY_COUNTRIES_FAILED]';

export const GET_INTERCONTRACT_QUANTITY_COMMUNITIES =
  'GET INTERCONTRACT QUANTITY [GET_INTERCONTRACT_QUANTITY_COMMUNITIES]';
export const GET_INTERCONTRACT_QUANTITY_COMMUNITIES_SUCCES =
  'GET INTERCONTRACT QUANTITY SUCCES [GET_INTERCONTRACT_QUANTITY_COMMUNITIES_SUCCES]';
export const GET_INTERCONTRACT_QUANTITY_COMMUNITIES_FAILED =
  'GET INTERCONTRACT QUANTITY FAILED [GET_INTERCONTRACT_QUANTITY_COMMUNITIES_FAILED]';

export const GET_INTERCONTRACT_COST_COUNTRIES =
  'GET INTERCONTRACT COST [GET_INTERCONTRACT_COST_COUNTRIES]';
export const GET_INTERCONTRACT_COST_COUNTRIES_SUCCES =
  'GET INTERCONTRACT COST SUCCES [GET_INTERCONTRACT_COST_COUNTRIES_SUCCES]';
export const GET_INTERCONTRACT_COST_COUNTRIES_FAILED =
  'GET INTERCONTRACT COST FAILED [GET_INTERCONTRACT_COST_COUNTRIES_FAILED]';

export const GET_INTERCONTRACT_COST_COMMUNITIES =
  'GET INTERCONTRACT COST [GET_INTERCONTRACT_COST_COMMUNITIES]';
export const GET_INTERCONTRACT_COST_COMMUNITIES_SUCCES =
  'GET INTERCONTRACT COST SUCCES [GET_INTERCONTRACT_COST_COMMUNITIES_SUCCES]';
export const GET_INTERCONTRACT_COST_COMMUNITIES_FAILED =
  'GET INTERCONTRACT COST FAILED [GET_INTERCONTRACT_COST_COMMUNITIES_FAILED]';

export const GET_INTERCONTRACT_UNIQUE_COUNTRIES =
  'GET INTERCONTRACT UNIQUE [GET_INTERCONTRACT_UNIQUE_COUNTRIES]';
export const GET_INTERCONTRACT_UNIQUE_COUNTRIES_SUCCES =
  'GET INTERCONTRACT UNIQUE SUCCES [GET_INTERCONTRACT_UNIQUE_COUNTRIES_SUCCES]';
export const GET_INTERCONTRACT_UNIQUE_COUNTRIES_FAILED =
  'GET INTERCONTRACT UNIQUE FAILED [GET_INTERCONTRACT_UNIQUE_COUNTRIES_FAILED]';

export const GET_INTERCONTRACT_UNIQUE_COMMUNITIES =
  'GET INTERCONTRACT UNIQUE [GET_INTERCONTRACT_UNIQUE_COMMUNITIES]';
export const GET_INTERCONTRACT_UNIQUE_COMMUNITIES_SUCCES =
  'GET INTERCONTRACT UNIQUE SUCCES [GET_INTERCONTRACT_UNIQUE_COMMUNITIES_SUCCES]';
export const GET_INTERCONTRACT_UNIQUE_COMMUNITIES_FAILED =
  'GET INTERCONTRACT UNIQUE FAILED [GET_INTERCONTRACT_UNIQUE_COMMUNITIES_FAILED]';

export const GET_YTD = 'GET YTD [GET_YTD]';
export const GET_YTD_SUCCES = 'GET YTD SUCCES [GET_YTD_SUCCES]';
export const GET_YTD_FAILED = 'GET YTD FAILED [GET_YTD_FAILED]';

export const GET_HR_KPI = 'GET HR KPI [GET_HR_KPI]';
export const GET_HR_KPI_SUCCES = 'GET HR KPI SUCCES [GET_HR_KPI_SUCCES]';
export const GET_HR_KPI_FAILED = 'GET HR KPI FAILED [GET_HR_KPI_FAILED]';
