import {
  GET_ALL_COUNTRIES,
  GET_ALL_COUNTRIES_FAILED,
  GET_ALL_COUNTRIES_SUCCES,
} from 'shared';

import { all, call, put, takeLatest } from 'redux-saga/effects';
import Axios from 'utils/Axios';

const { REACT_APP_BASEURL } = process.env;

export const getCountriesApi = () => {
  return Axios.get(`${REACT_APP_BASEURL}/countries/allcountries`);
};

export function* getCountriesSaga() {
  try {
    const res = yield call(getCountriesApi);

    if (res.status === 200) {
      yield put({
        type: GET_ALL_COUNTRIES_SUCCES,
        countries: res.data.countries,
      });
    } else {
      yield put({
        type: GET_ALL_COUNTRIES_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: GET_ALL_COUNTRIES_FAILED,
    });
  }
}

export function* watchGetCountries() {
  yield takeLatest(GET_ALL_COUNTRIES, getCountriesSaga);
}

export function* rootCountriesSaga() {
  yield all([watchGetCountries()]);
}
