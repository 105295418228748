import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { StyledCheckbox } from './styles';

const CheckboxLabel = ({value, sendCheckboxValue, index, values, label}) => {
  const [checked, setChecked] = useState(value);

  const changeHandler = (event) => {
    const newValue = event.target.checked;
    setChecked(newValue);
    sendCheckboxValue(newValue, index, values);
  }

  return (
    <FormGroup>
      <FormControlLabel 
        control={
          <StyledCheckbox>
            <Checkbox 
              checked={checked} 
              onChange={changeHandler}
            />
          </StyledCheckbox>
        } 
        label={label} 
      />
    </FormGroup>
  )
}

export default CheckboxLabel;