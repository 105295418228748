import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by error boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            background: '#0075C9',
            color: 'white',
            padding: '20px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
            height: '100vh',
          }}
        >
          <h2
            style={{
              padding: '20px',
              background: 'white',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
            }}
          >
            Oops! Something went wrong...
          </h2>
          <p
            style={{
              padding: '20px',
            }}
          >
            We're sorry for the inconvenience. Please try again later.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
