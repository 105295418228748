import * as React from 'react';

const DashboardIcon = (props) => {
  return (
    <svg width={17} height={16} viewBox="0 0 16 16">
      <path
        d="M14.7407895,0 L2.10921053,0 C1.18289474,0 0.425,0.757894737 0.425,1.68421053 L0.425,13.4736842 C0.425,14.4 1.18289474,15.1578947 2.10921053,15.1578947 L14.7407895,15.1578947 C15.6671053,15.1578947 16.425,14.4 16.425,13.4736842 L16.425,1.68421053 C16.425,0.757894737 15.6671053,0 14.7407895,0 Z M14.7407895,1.68421053 L14.7407895,4.21052632 L2.10921053,4.21052632 L2.10921053,1.68421053 L14.7407895,1.68421053 Z M10.5302632,13.4736842 L6.31973684,13.4736842 L6.31973684,5.89473684 L10.5302632,5.89473684 L10.5302632,13.4736842 Z M2.10921053,5.89473684 L4.63552632,5.89473684 L4.63552632,13.4736842 L2.10921053,13.4736842 L2.10921053,5.89473684 Z M12.2144737,13.4736842 L12.2144737,5.89473684 L14.7407895,5.89473684 L14.7407895,13.4736842 L12.2144737,13.4736842 Z"
        id="path-1"
        {...props}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default DashboardIcon;
